import React, { useContext, useEffect } from 'react';
import { PageLoadingContext } from '@vfit/business-data-access';
import { usePostCreateShoppingCartDSMutation } from '@vfit/business-data-access';
import { IActionType } from '@vfit/shared/models';
import { WidgetContainer } from '../Widgets/widgets.style';
import { IBusinessWidgetProductHeroBannerDS } from './widgetProductHeroBannerDS.models';
import { useWidgetProductHeroBannerDS } from './widgetProductHeroBannerDS.hook';
import TopProduct from '../TopProduct/topProduct';
import SkeletonPdp from '../SkeletonPdp';

const WidgetProductHeroBannerDS = ({
  widget,
  isLastWidget,
  indexWidget,
}: IBusinessWidgetProductHeroBannerDS) => {
  const { isLoading } = useContext(PageLoadingContext);

  const info = useWidgetProductHeroBannerDS(widget);

  const { createShoppingCart, data } = usePostCreateShoppingCartDSMutation(info.product.offerId || '');

  useEffect(() => {
    if (data) {
      const checkoutAction = info.product?.actions?.find(
        (a) => a?.type == IActionType.CHECKOUT_MODAL_MOBILE
      );
      window.open(
        `${checkoutAction?.url}${data?.id}`,
        checkoutAction?.isBlank === 'true' ? '_blank' : '_self'
      );
    }
  }, [data]);

  if (isLoading) return <SkeletonPdp />;
  return (
    <WidgetContainer isLastWidget={isLastWidget}>
      <TopProduct {...{ ...info, createShoppingCart, indexWidget }} />
    </WidgetContainer>
  );
};

export default WidgetProductHeroBannerDS;

