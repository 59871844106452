import { getActionUrlByActionType } from '@vfit/shared/data-access';
import { useWidgetsAction } from '../Widgets/widgets.hook';
import { IBusinessSplittedTableText } from './widgetSplittedTableText.models';
import { getTableRowsList } from './widgetSplittedTableText.utils';

export const useWidgetTableText = (widget: IBusinessSplittedTableText) => {
  const tableRows = getTableRowsList(widget.elements);
  const { handleClick } = useWidgetsAction();

  return {
    title: widget.title,
    description: widget.description,
    tableRows,
    action: {
      ...widget.customAction,
      style: Number(widget.customAction?.style),
    },
    handleButtonClick: handleClick,
    ...(widget.customAction && {
      actionNavigationUrl: getActionUrlByActionType(widget.customAction),
    }),
  };
};

