import { useContext } from 'react';
import { IActionType, IActionSlide } from '@vfit/shared/models';
import { CTC_POSITION, useVolaUtils } from '@vfit/business-data-access';
import { TrackingPageContext } from '@vfit/business-data-access';
import { IProductCard } from '../WidgetSliderProductsHub/components/ProductCard/productCard.models';

export const useWhatsappModalAction = () => {
  const { descriptionVolaUrl } = useContext(TrackingPageContext);
  const { formatUrlVola, openPopupVola, createUrlDesc, formatDynamicVolaUrl } = useVolaUtils();

  const handleClick = (param?: IActionSlide, product?: IProductCard) => {
    const desc = createUrlDesc({
      position: CTC_POSITION.WHATSAPP_MODAL,
      productName: product?.taggingProductName || product?.title || '',
      productPrice: product?.taggingProductPrice,
    });
    const type = param?.type ? +param.type : 0;
    switch (type) {
      case IActionType.CUSTOM_URL:
        if (param?.url) {
          window.open(param?.url, param?.isBlank === 'true' ? '_blank' : '_self');
        }
        break;
      case IActionType.CALL_ME_NOW:
        if (param?.url) {
          if (descriptionVolaUrl && desc)
            openPopupVola(formatDynamicVolaUrl(param?.url, desc), 'vola-ctc');
          else openPopupVola(formatUrlVola(param?.url, product!), 'vola-ctc');
        }
        break;
      default:
        break;
    }
  };

  return {
    handleClick,
  };
};

