import { useEffect } from 'react';
import { Portal } from '@vfit/shared/components';
import { useQueryClient } from 'react-query';
import { OverlayGlobalStyle } from './coverageToolModal.style';
import { ICoverageToolModalProps } from './coverageToolModal.models';
import { CoverageToolModalProvider } from './coverageToolModal.context';
import CoverageToolModalContent from './coverageToolModalContent';

const CoverageToolModal = ({
  isOpen,
  handleClose,
  backgroundImage,
  product,
}: ICoverageToolModalProps) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    const closeOnEscapeKey = (e: KeyboardEvent) =>
      e.key === 'Escape' && handleClose ? handleClose() : null;
    document.body.addEventListener('keydown', closeOnEscapeKey);
    return () => {
      document.body.removeEventListener('keydown', closeOnEscapeKey);
    };
  }, [handleClose]);

  useEffect(() => {
    if (isOpen) {
      queryClient.setQueryData('coverageToolProduct', product);
    }
  }, [isOpen]);

  return isOpen ? (
    <Portal wrapperId="coverage-tool">
      <>
        <OverlayGlobalStyle />
        <CoverageToolModalProvider>
          <CoverageToolModalContent
            backgroundImage={backgroundImage}
            product={product}
            handleClose={handleClose}
          />
        </CoverageToolModalProvider>
      </>
    </Portal>
  ) : null;
};

export default CoverageToolModal;

