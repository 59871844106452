import { Children, useState } from 'react';
import { ITab } from './tabs.models';
import { TabsContainer, Tab } from './tabs.style';

const Tabs = ({
  tabs,
  handleClickTab,
  align,
}: {
  tabs: ITab[];
  handleClickTab?: (id: number) => void;
  align?: string;
}) => {
  const [selIndex, setSelIndex] = useState<number>(0);

  const onClickTab = (index: number) => {
    setSelIndex(index);
    handleClickTab?.(index);
  };

  return (
    <>
      <TabsContainer align={align}>
        {Children.toArray(
          tabs.map((tab, index) => (
            <Tab isActive={index === selIndex} onClick={() => onClickTab(index)}>
              {tab.label}
            </Tab>
          ))
        )}
      </TabsContainer>
      {tabs[selIndex]?.content}
    </>
  );
};

export default Tabs;

