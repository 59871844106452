import React from 'react';
import { SlideCarousel } from '@vfit/shared/components';
import { ISlideCarouelProps } from '@vfit/shared/models';
import { useWidgetsAction } from '../../../Widgets/widgets.hook';

const SliderWithAction = (slideCarouselProps: ISlideCarouelProps) => {
  const { slide } = slideCarouselProps;
  const { handleClick } = useWidgetsAction();

  return <SlideCarousel {...slideCarouselProps} handleClickButton={() => handleClick(slide.action)} />;
};

export default SliderWithAction;
