import { useState } from 'react';
import { ButtonSlide, Modal } from '@vfit/shared/atoms';
import { Container, Title } from './modalInfo.style';
import { IModalInfo } from './modalInfo.model';

const ModalInfo: React.FC<IModalInfo> = ({ content, customAction }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { title, ctaName, card } = content;

  return (
    <Container>
      <div>
       {title && <Title>{title}</Title>}
       {ctaName && <ButtonSlide
          labelColor="#262626;"
          borderColor="#262626"
          buttonColor="#fff"
          label={ctaName}
          onClick={() => {
            if(customAction) customAction()
            else setIsOpen((prevState) => !prevState)
          }}
        />}
      </div>
      {
        card && (
          <Modal
            show={isOpen}
            onCloseModal={() => setIsOpen((prevState) => !prevState)}
            withBorderRadius
          >
            {card}
          </Modal>
        )
      }
    </Container>
  );
};
export default ModalInfo;
