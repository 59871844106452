import styled from 'styled-components';
import { breakpoints, fonts, pxToCssFontSize } from '@vfit/shared/themes';

export const CoverageToolContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CoverageToolContent = styled.div`
  margin-top: 32px;
  margin-left: 20px;
  margin-right: 20px;

  @media (min-width: ${breakpoints.tablet}) {
    margin-top: 64px;
    margin-left: 40px;
    margin-right: 40px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-top: 60px;
    margin-left: 54px;
    margin-right: 54px;
  }
`;

export const CoverageToolContentButton = styled.div`
  margin-bottom: 32px;
  margin-left: 20px;
  margin-right: 20px;

  @media (min-width: ${breakpoints.tablet}) {
    margin-bottom: 64px;
    margin-left: 40px;
    margin-right: 40px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-bottom: 60px;
    margin-left: 54px;
    margin-right: 54px;
  }
`;

export const CoverageToolContentButtonAuto = styled.div`
  position: absolute;
  bottom: 32px;
  right: 20px;
  left: 20px;

  @media (min-width: ${breakpoints.tablet}) {
    bottom: 64px;
    left: 40px;
    right: 40px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    bottom: 60px;
    left: 54px;
    right: 54px;
  }
`;

export const WrapperBoxRadioBox = styled.div`
height: 300px;
overflow-y: auto;
margin: 20px 20px 100px 20px;
display: flex;
flex-direction: column;
gap: 12px;
padding: 12px;

 div {
  input[type="radio"] {
    display: none;
    padding: 16px;
  }
  label {
    padding: 20px 16px;
    text-align: left;
    font-family: ${fonts.exbold};
    ${pxToCssFontSize(20)}
  }
 }
`;

