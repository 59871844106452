import {
  IEcommerceCatalogResponse,
  useGetEcommerceCatalogDevices,
} from '@vfit/business-data-access';
import { IBusinessHubDevice } from './widgetHubDevice.model';
import { formatData } from './widgetHubDevice.utils';

const useWidgetHubDevice = (widget: IBusinessHubDevice) => {
 const product = useGetEcommerceCatalogDevices({
    limit: widget.numberCards,
    category: widget.productType,
  }).data as IEcommerceCatalogResponse ;

  return {
    products: product && formatData(widget, product.deviceInfo)
  };
};

export default useWidgetHubDevice;

