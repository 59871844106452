import { IStepCard } from '@vfit/shared/models';
import CoverageToolAutoCard from './components/CoverageToolAutoCard/coverageToolAutoCard';
import CoverageToolManualCard from './components/CoverageToolManualCard/coverageToolManualCard';
import CoverageToolMapCard from './components/CoverageToolMapCard/coverageToolMapCard';
import CoverageToolListAddressCard from './components/CoverageToolListAddressCard/coverageToolListAddressCard';

export interface ICoverageToolFlow {
  DEFAULT: IStepCard[];
}

export const ID_FLOWS = {
  AUTO: 'auto',
  MANUAL: 'Manual',
  MAP: 'map',
  CHECK_USER: 'checkuser',
  OTP: 'otp',
  LIST_ADDRESS: 'listaddress',
};

export type ID_FLOWS_TYPE = 'auto' | 'Manual' | 'map' | 'checkuser' | 'otp' | 'listaddress';

export interface IID_FLOWS {
  AUTO: 'auto';
  MANUAL: 'Manual';
  MAP: 'map';
  CHECK_USER: 'checkuser';
  OTP: 'otp';
  LIST_ADDRESS: 'listaddress';
}

export const AUTO_FLOW = {
  AUTO_CARD: [
    {
      title: ID_FLOWS.AUTO,
      component: CoverageToolAutoCard,
    },
  ],
};

export const LIST_ADDRESS_FLOW = {
  LIST_CARD: [
    {
      title: ID_FLOWS.LIST_ADDRESS,
      component: CoverageToolListAddressCard,
    },
  ],
};

export const MANUAL_FLOW = {
  MANUAL_CARD: [
    {
      title: ID_FLOWS.MANUAL,
      component: CoverageToolManualCard,
    },
  ],
};

export const MAP_FLOW = {
  MAP_CARD: [
    {
      title: ID_FLOWS.MAP,
      component: CoverageToolMapCard,
    },
  ],
};

export const EMPTY_FLOWS = {
  EMPTY: [
    {
      title: '',
      component: undefined,
    },
    {
      title: '',
      component: undefined,
    },
  ],
};

const COVERAGE_TOOL_FLOW: ICoverageToolFlow = {
  DEFAULT: [...AUTO_FLOW.AUTO_CARD, ...MAP_FLOW.MAP_CARD],
};

export { COVERAGE_TOOL_FLOW };

