import { SplittedTableText } from '@vfit/shared/components';
import { WidgetContainer } from '../Widgets/widgets.style';
import { IBusinessWidgetSplittedTableText } from './widgetSplittedTableText.models';
import { useWidgetTableText } from './widgetSplittedTableText.hook';

const WidgetSplittedTableText = ({ widget, isLastWidget }: IBusinessWidgetSplittedTableText) => (
  <WidgetContainer isLastWidget={isLastWidget}>
    <SplittedTableText {...useWidgetTableText(widget)} />
  </WidgetContainer>
);

export default WidgetSplittedTableText;

