import { purify } from '@vfit/shared/themes';
import { IAlertProps } from './alert.models';

import { StyledAlert, StyledIconContainer, StyledText } from './alert.style';

/** ALERT DOCS
 *
 * @description A React Functional Component for rendering a customizable alert.
 *
 * @param props:
 *
 * -
 * - text: string -> for rendering the message inside the alert;
 * - icon: JSX.Element -> for rendering an icon inside the alert;
 * - type: "success" | "warning" | "error" | "info" -> for specifing the type of alert needed, with the associated color.
 *
 * @returns A JSX Element.
 *
 * @author Chris
 */

const Alert: React.FC<IAlertProps> = (props) => {
  const { text, type, icon } = props;
  const [pText] = purify([text]);

  return (
    <StyledAlert type={type}>
      <StyledIconContainer>{icon}</StyledIconContainer>
      <StyledText dangerouslySetInnerHTML={{ __html: pText || ''}} />
    </StyledAlert>
  );
};

export default Alert;
