import { breakpoints, pxToCssFont } from '@vfit/shared/themes';
import styled from 'styled-components';

export const ModalTitle = styled.div`
  div {
    margin: 0;
    ${pxToCssFont(18, 16)}
  }

  @media (min-width: ${breakpoints.desktop}) {
    div {
      ${pxToCssFont(36, 45)}
    }
  }
`;

export const ModalContent = styled.div`
  div {
    margin: 0;
  }
`;
