import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont, pxToRem } from '@vfit/shared/themes';

export const SlideProductContainer = styled.div`
  position: relative;
  min-height: 442px;

  @media (min-width: ${breakpoints.tablet}) {
    height: 800px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    height: inherit;
    min-height: 680px;
  }
`;

export const DivImgContainer = styled.div<{ absolute: boolean }>`
  height: 100%;
  width: 100%;
  min-height: 100%;
  min-width: 100%;
  overflow: hidden;
  z-index: -1;
  object-fit: cover;
  object-position: center;
  border-radius: 0 0 20px 20px;
  position: ${(props) => (props.absolute ? 'absolute' : 'relative')};
  top: ${(props) => (props.absolute ? 0 : '')};
  left: ${(props) => (props.absolute ? 0 : '')};

  @media (min-width: ${breakpoints.tablet}) {
    border-radius: 0 0 30px 30px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    border-radius: 0 0 30px 30px;
  }
`;

export const SlideProductNavigation = styled.div`
  margin-top: 24px;
  padding-left: 30px;
  margin-bottom: 28px;
  display: flex;
  align-items: center;

  @media (min-width: ${breakpoints.tablet}) {
    margin-top: 67px;
    padding-left: 65px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-top: 56px;
    padding-left: 80px;
    margin-bottom: inherit;
  }
`;

export const SlideProductContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const SlideProductContentTop = styled.div`
  width: 100%;
  flex: 0.8;

  @media (min-width: ${breakpoints.tablet}) {
    flex: 0.9;
  }

  @media (min-width: ${breakpoints.desktop}) {
    flex: 0.8;
  }
`;

export const SlideProductContentBottom = styled.div`
  width: 100%;
  flex: 0.2;
  display: flex;
  justify-content: center;
  padding-bottom: 60px;

  @media (min-width: ${breakpoints.tablet}) {
    padding-bottom: 0;
    flex: 0.1;
  }

  @media (min-width: ${breakpoints.desktop}) {
    padding-bottom: 60px;
    flex: 0.2;
    position: absolute;
    bottom: 0;
  }
`;

export const SlideProductButtonSlideContainer = styled.div`
  width: 324px;
  max-width: 400px;
  padding-bottom: 25px;

  div {
    width: 324px;
    max-width: 400px;
    padding-left: 30px;
  }
`;

export const SlideProductText = styled.div`
  h2 {
    font-family: ${fonts.regular};
    font-weight: 400;
    ${pxToCssFont(16, 24)}
    letter-spacing: ${pxToRem(0.5)};
    text-transform: uppercase;
    color: ${colors.$ffffff};
    margin: 0 30px;
  }

  h3 {
    font-family: ${fonts.exbold};
    font-weight: 400;
    ${pxToCssFont(42, 40)}
    color: ${colors.$ffffff};
    margin: 0;
    max-width: none;
  }

  h4 {
    font-family: ${fonts.exbold};
    ${pxToCssFont(24, 28)}
    font-weight: 400;
    color: ${colors.$f2f2f2};
    margin: 30px 30px 0;
    max-width: none;
  }

  h5 {
    font-family: ${fonts.regular};
    font-weight: 700;
    ${pxToCssFont(16, 22)}
    color: ${colors.$bebebe};
    margin: 0 30px;
  }

  h6 {
    font-family: ${fonts.regular};
    ${pxToCssFont(16, 18)}
    font-weight: 400;
    color: ${colors.$ffffff};
    margin: 10px 30px 0;
    max-width: none;
  }

  .text {
    color: ${colors.$ffffff};
    font-family: ${fonts.regular};
    margin-top: 24px;
    margin-bottom: 75px;

    span {
      ${pxToCssFont(16, 22)}
      font-weight: 700;

      @media (min-width: ${breakpoints.tablet}) {
        ${pxToCssFont(18, 24)}
      }
    }

    .containerPill {
      margin-left: 30px;
      span {
        ${pxToCssFont(12, 16)}
      }
    }

    .price {
      margin: 0;
      margin-left: 30px;

      div {
        margin: 0;
        ${pxToCssFont(24, 28)}

        b:first-of-type {
          ${pxToCssFont(30, 38)}
        }

        @media (min-width: ${breakpoints.tablet}) {
          white-space: nowrap;
          ${pxToCssFont(24, 40)}

          b:first-of-type {
            ${pxToCssFont(42, 52)}
          }
        }
      }
    }

    .productDescription div {
      margin: 0 30px;
      color: ${colors.$f2f2f2};
      font-family: ${fonts.regular};
      ${pxToCssFont(18, 24)};
      font-weight: 400;
    }
  }

  @media (min-width: ${breakpoints.tablet}) {
    padding-left: 32px;
    padding-top: 61px;

    h2 {
      ${pxToCssFont(18, 24)}
    }

    h3 {
      ${pxToCssFont(64, 80)}/* max-width: 620px; */
    }

    h4 {
      ${pxToCssFont(30, 38)}
    }

    h5 {
      ${pxToCssFont(18, 24)}
      max-width: 70%;
    }

    h6 {
      margin-top: 24px;
      ${pxToCssFont(18, 24)}
    }

    .text {
      margin-top: 40px;
      margin-bottom: 40px;

      div {
        ${pxToCssFont(14, 18)}
        max-width: 70%;
      }
      .containerPill {
        div {
          max-width: 100%;
        }
      }
    }
  }

  @media (min-width: ${breakpoints.desktop}) {
    padding-left: 50px;
    padding-top: 56px;

    h2 {
      ${pxToCssFont(18, 24)}
    }

    h3 {
      ${pxToCssFont(70, 65)}
    }

    h4 {
      ${pxToCssFont(42, 52)}
    }

    .text {
      div {
        ${pxToCssFont(18, 24)}
        max-width: 70%;
      }
      .containerPill {
        div {
          max-width: 100%;
        }
      }
    }
  }
`;

export const SlideButtons = styled.div`
  display: flex;
  gap: 30px;
  padding-bottom: 30px;
`;

export const TitleContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 30px;
  position: relative;
`;

export const TitleText = styled.div<{ iconUrl: boolean }>`
  h3 {
    margin-right: ${(props) => (props.iconUrl ? '100px' : '0')};
  }
  h6 {
    margin-right: ${(props) => (props.iconUrl ? '100px' : '30px')};
  }

  @media (min-width: ${breakpoints.tablet}) {
    h3 {
      margin-right: 0;
    }
    h6 {
      margin-right: 30px;
    }
  }
`;

export const IconContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background: ${colors.$ffffff};
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;
  padding-right: 32px;

  > div {
    width: 56px;
    height: 56px;
  }

  @media (min-width: ${breakpoints.tablet}) {
    position: relative;
    margin-right: 24px;
    border-top-right-radius: 9px;
    border-bottom-right-radius: 9px;
    transform: none;
    padding-right: 0;

    > div {
      width: 80px;
      height: 80px;
    }
  }
`;

