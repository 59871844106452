import styled from 'styled-components';
import { breakpoints } from '@vfit/shared/themes';

export const MapContainer = styled.div`
  height: 310px;

  @media (min-width: ${breakpoints.tablet}) {
    height: 360px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    height: 320px;
  }
`;

export const StyledMap = styled.span`
  div {
    border-radius: 20px 20px 0 0;
    transform: translateZ(0);
  }

  a:any-link {
    display: none !important;
  }

  .gm-bundled-control {
    display: none;
  }

  .gm-style .gm-style-cc a,
  .gm-style .gm-style-cc button,
  .gm-style .gm-style-cc span {
    display: none !important;
  }

  .gm-style-cc {
    display: none !important;
  }
`;

