import { Fade } from 'react-awesome-reveal';
import {
  IConfigCMSParams,
  IPlaceholderRequest,
  cmsDataID,
  useCmsConfig,
  usePostCreateShoppingCartDSMutation,
  useSwitchCmsData,
} from '@vfit/business-data-access';
import { useContext, useEffect, useState } from 'react';
import { PageLoadingContext } from '@vfit/business-data-access';
import { IActionType } from '@vfit/shared/models';
import { WidgetContainer } from '../Widgets/widgets.style';
import { useWidgetSliderProductDSMultiple } from './widgetSliderProductDSMultiple.hook';
import { IBusinessWidgetSliderProductDSMultiple } from './widgetSliderProductDSMultiple.models';
import SkeletonHub from '../SkeletonHub';
import ProductsList from './components/ProductsList/productsList';

const WidgetSliderProductDSMultiple = ({
  widget,
  isLastWidget,
}: IBusinessWidgetSliderProductDSMultiple) => {
  const { isLoading } = useContext(PageLoadingContext);
  const [selectedId, setSelectedId] = useState<string | undefined>();

  const { cmsConfig, api } = useSwitchCmsData(cmsDataID.PLACEHOLDER) as IConfigCMSParams;
  const { placeholders } = useCmsConfig(cmsConfig, api) as IPlaceholderRequest;

  const { title, products } = useWidgetSliderProductDSMultiple(widget, placeholders?.placeholders);

  const { createShoppingCart, data } = usePostCreateShoppingCartDSMutation(
    widget.dxlId || '',
    selectedId
  );

  useEffect(() => {
    if (data) {
      const selectedProduct = products.find((item) => item.offerId === selectedId);
      const checkoutAction = selectedProduct?.actions?.find(
        (a) => a?.type == IActionType.CHECKOUT_MODAL_MOBILE
      );
      window.open(
        `${checkoutAction?.url}${data?.id}`,
        checkoutAction?.isBlank === 'true' ? '_blank' : '_self'
      );
    }
  }, [data]);

  useEffect(() => {
    if (selectedId) {
      createShoppingCart();
    }
  }, [selectedId]);

  if (isLoading) return <SkeletonHub />;

  return (
    <WidgetContainer isLastWidget={isLastWidget}>
      <Fade direction="up" triggerOnce>
        <ProductsList title={title} products={products} createShoppingCart={setSelectedId} />
      </Fade>
    </WidgetContainer>
  );
};

export default WidgetSliderProductDSMultiple;

