import { Shadow } from '@vfit/shared/atoms'
import { useDeviceType } from '@vfit/shared/hooks';


const Card = ({ noMargin = false }) => (
  <div
    style={{
      height: '430px',
      width: '400px',
      border: '1px solid #BEBEBE',
      borderRadius: '20px',
      overflow: 'hidden',
      marginRight: noMargin ? '0' : '32px',
      marginBottom: '24px',
    }}
  >
    <div style={{ height: '145px', marginTop: '32px', marginLeft: '24px' }}>
      <div style={{ width: '80px' }}>
        <Shadow height={22} />
      </div>
      <div style={{ width: '270px', marginTop: '50px' }}>
        <Shadow height={30} />
      </div>
      <div style={{ width: '180px', marginTop: '25px' }}>
        <Shadow height={20} />
      </div>
    </div>
    <div style={{ width: '300px', margin: '100px auto' }}>
        <Shadow height={44} />
      </div>
  </div>
);

const SkeletonAddon = () => (
    <div>
    <div style={{ width: '500px', margin: '80px auto 20px' }}>
      <Shadow height={45} />
    </div>
    <div style={{ width: '300px', margin: '20px auto 80px' }}>
      <Shadow height={45} />
    </div>
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Card />
    </div>
  </div>
  );

export default SkeletonAddon