import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont } from '@vfit/shared/themes';
import { IStyledProps } from './alert.models';

export const StyledAlert = styled.div.attrs((props: IStyledProps) => ({
  type: props.type,
}))`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 0 2px 0 0;
  border-radius: 6px;
  border-color: ${(props) => {
    let color;
    switch (props.type) {
      case 'success':
        color = colors.$008a00;
        break;
      case 'error':
        color = colors.$bd0000;
        break;
      case 'warning':
        color = '#eb9700';
        break;
      default:
        color = '#005ea5';
        break;
    }
    return color;
  }};
  background: ${(props) => {
    let color;
    switch (props.type) {
      case 'success':
        color = colors.$008a00;
        break;
      case 'error':
        color = colors.$bd0000;
        break;
      case 'warning':
        color = '#eb9700';
        break;
      default:
        color = '#005ea5';
        break;
    }
    return color;
  }};
`;

export const StyledIconContainer = styled.div`
  padding: 1rem;
  border: none;

  & svg {
    vertical-align: middle;
  }
`;

export const StyledText = styled.div`
  margin: 0;
  text-align: start;
  font-family: ${fonts.regular};
  ${pxToCssFont(16, 22)}
  padding: 1rem;
  background: ${colors.$ffffff};
  width: 100%;
  border-top: 2px solid;
  border-bottom: 2px solid;
  border-color: inherit;
  border-radius: 0 6px 6px 0;

  p {
    margin: 0;
  }

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(18, 24)}
  }
`;

