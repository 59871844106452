import styled from 'styled-components';

export const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  justify-content: center;
  padding-bottom: 40px;

  button,
  a {
    min-width: 320px;
    width: fit-content;
  }
`;

