import { ButtonSlideCustom, CustomText } from '@vfit/shared/atoms';
import { API, PAGES } from '@vfit/shared/data-access';
import { CMS_CONFIG, ICoverageToolCongifg, useCmsConfig } from '@vfit/business-data-access';
import { IActionStyle } from '@vfit/shared/models';
import { useContext, useEffect } from 'react';
import { TrackingPageContext } from '@vfit/business-data-access';
import { ICoverageErrorProps } from './coverageToolError.models';
import {
  ButtonSlideContainer,
  Container,
  Title,
  Description,
  Subtitle,
} from './coverageToolError.style';

const CoverageToolError = ({ onRetry, offerId }: ICoverageErrorProps) => {
  const coverageToolConfig = useCmsConfig(
    CMS_CONFIG[PAGES.BUSINESS_FIXED_LINE],
    API.CMS_BFL_GET_COVERAGE_TOOLS
  ) as ICoverageToolCongifg;

  const { view, link, setCurrentDataLayer, currentDataLayer } = useContext(TrackingPageContext);

  useEffect(() => {
    const datalayer = view(
      {
        event_name: ['tool_fail'],
        event_label: 'tool_error',
        page_section: coverageToolConfig?.tagging?.pageSection,
        page_subsection: coverageToolConfig?.tagging?.pageSubsection,
        page_type: coverageToolConfig?.tagging?.pageType,
        journey_name: 'coverage tool',
        journey_type: 'journey',
        event_category: [coverageToolConfig?.tagging?.eventCategory || ''],
      },
      offerId
    );
    setCurrentDataLayer({ ...currentDataLayer, coverageToolDataLayer: datalayer });
  }, []);

  const getCoverageToolMessage = () => ({
    title: coverageToolConfig?.coveragetool?.koTechnicalModal?.title,
    subTitle: coverageToolConfig?.coveragetool?.koTechnicalModal?.subtitle,
    description: coverageToolConfig?.coveragetool?.koTechnicalModal?.description || '',
    button: coverageToolConfig?.coveragetool?.koTechnicalModal?.button || '',
    buttonStyle: (coverageToolConfig?.coveragetool?.koTechnicalModal?.buttonStyle ||
      '') as IActionStyle,
  });

  const koCoverageTooldata = getCoverageToolMessage();

  return (
    <Container>
      <div>
        <Title>{koCoverageTooldata.title}</Title>
        <Subtitle>{koCoverageTooldata.subTitle}</Subtitle>
        <Description>
          <CustomText text={koCoverageTooldata.description} />
        </Description>
      </div>
      <ButtonSlideContainer>
        <ButtonSlideCustom
          label={koCoverageTooldata.button}
          onClick={() => {
            link(
              {
                ...currentDataLayer?.coverageToolDataLayer,
                link_name: `${currentDataLayer?.coverageToolDataLayer?.page_name}:Riprova`,
              },
              offerId
            );
            onRetry();
          }}
          style={koCoverageTooldata.buttonStyle}
          name="action_ko_second"
        />
      </ButtonSlideContainer>
    </Container>
  );
};
export default CoverageToolError;

