import { breakpoints, colors } from '@vfit/shared/themes';
import styled from 'styled-components';

export const FilterRow = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 32px 0 32px 0;
  border-top: 1px solid ${colors.$bebebe};

  span{
    cursor: pointer;
  }
  .numberBrand{
    color: ${colors.$bebebe};
  }
`;

export const Title = styled.div`
  margin-bottom: 32px;
   div{
    margin: 0;
    span{
      margin: 0;
    }
  }
  @media (min-width: ${breakpoints.desktop}) {
    margin-bottom: 48px;
  }
`;