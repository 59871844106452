import { Map, ButtonSlideCustom } from '@vfit/shared/atoms';
import {
  CMS_CONFIG,
  useCmsConfig,
  ICoverageToolCongifg,
  CoverageErrorType,
} from '@vfit/business-data-access';
import { API, PAGES } from '@vfit/shared/data-access';
import { Player } from '@lottiefiles/react-lottie-player';
import {
  Address,
  AddressDesc,
  MapDescription,
  CoverageToolMapContainer,
  CoverageToolMapContent,
  CoverageToolMapButton,
  TextLoader,
} from './coverageToolMap.style';
import { ICoverageToolMap } from './coverageToolMap.models';
import CoverageToolError from '../CoverageToolError/coverageToolError';

const CoverageToolMap = ({
  address,
  onEditAddress,
  onConfirm,
  isLoading,
  lottieFile,
  onReTry,
  offerId,
  errorType,
}: ICoverageToolMap) => {
  const coverageToolConfig = useCmsConfig(
    CMS_CONFIG[PAGES.BUSINESS_FIXED_LINE],
    API.CMS_BFL_GET_COVERAGE_TOOLS
  ) as ICoverageToolCongifg;

  const { city, latitude, longitude, postalCode, stateOrProvince, street, streetNumber } = address;

  const onEdit = () => {
    onEditAddress(address);
  };

  const handleRetry = () => {
    if (onReTry) onReTry();
  };

  if (errorType === CoverageErrorType.KO_TECHNICAL) {
    return <CoverageToolError onRetry={handleRetry} offerId={offerId} />;
  }

  return (
    <CoverageToolMapContainer>
      <div>
        <Map
          latitude={latitude ? latitude.toString() : ''}
          longitude={longitude ? longitude.toString() : ''}
        />
        <CoverageToolMapContent>
          <MapDescription>
            {isLoading && lottieFile && (
              <Player src={lottieFile} className="player" loop autoplay />
            )}
            {isLoading && !lottieFile && (
              <>
                <TextLoader />
                <TextLoader />
              </>
            )}
            {!isLoading && (
              <>
                <Address>{`${street}, ${streetNumber}`}</Address>
                <AddressDesc>{`${postalCode} ${city} ${stateOrProvince}`}</AddressDesc>
              </>
            )}
          </MapDescription>
        </CoverageToolMapContent>
      </div>
      <CoverageToolMapButton>
        <ButtonSlideCustom
          label={coverageToolConfig?.coveragetool?.confirmationMap?.buttonEdit || ' '}
          disabled={isLoading}
          onClick={onEdit}
          style={coverageToolConfig?.coveragetool?.confirmationMap?.buttonEditStyle}
        />
        <br />
        <ButtonSlideCustom
          label={coverageToolConfig?.coveragetool?.confirmationMap?.buttonSubmit || ' '}
          onClick={onConfirm}
          disabled={isLoading}
          style={coverageToolConfig?.coveragetool?.confirmationMap?.buttonSubmitStyle}
        />
      </CoverageToolMapButton>
    </CoverageToolMapContainer>
  );
};

export default CoverageToolMap;

