import { ProductType } from '@vfit/business-data-access';
import { IRetarget } from '@vfit/business-data-access';
import { IActionCustom, IDivider } from '@vfit/shared/models';
import { ILandingPrive } from '../WidgetProductHeroBanner/widgetProductHeroBanner.models';
import { ITimeDiscountLabels } from '../WidgetSliderProductsHub/components/ProductCard/productCard.models';

export interface ITopProduct {
  product: IHeroBanner;
  disclaimer?: string;
  disableNavigation?: boolean;
  description?: string;
  divider?: IDivider;
  offerDetails?: IActionCustom;
  createShoppingCart?: () => void;
  indexWidget?: number;
}

export enum IType {
  TEXT,
  PRODUCT,
}

export interface IBackLabel {
  text?: string;
  url?: string;
}

export interface IHeroBanner {
  offerId?: string;
  isEditorialCard?: string;
  taggingProductPrice?: string;
  title?: string;
  priceDescription?: string;
  unaTantumPriceDescription?: string;
  shortDescription?: string;
  productType?: ProductType;
  price?: string;
  priceResponsive?: string;
  frontLabel?: string;
  image?: string;
  backLabel?: IBackLabel;
  offerLabel?: string;
  actions?: IActionCustom[];
  retarget?: IRetarget;
  version?: string;
  landingprive?: ILandingPrive;
  subtitle?: string;
  iconUrl?: string;
  externalUrl?: boolean;
  hideDetails?: boolean;
  taggingProductName?: string;
  timeDiscount?: string;
  timeDiscountLabels?: ITimeDiscountLabels;
}

