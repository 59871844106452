import { ConnectionCards } from '@vfit/shared/components';
import { WidgetContainer } from '../Widgets/widgets.style';
import { IBusinessWidgetConnectionCards } from './widgetConnectionCards.models';
import { useWidgetConnectionCards } from './widgetConnectionCards.hook';

const WidgetConnectionCards = ({ widget, isLastWidget = true }: IBusinessWidgetConnectionCards) => {
  const cards = useWidgetConnectionCards(widget);
  return (
    <WidgetContainer isLastWidget={isLastWidget}>
      <ConnectionCards {...cards} />
    </WidgetContainer>
  );
};

export default WidgetConnectionCards;

