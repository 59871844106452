import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont } from '@vfit/shared/themes';

export const ParagraphContainer = styled.p`
  margin: 0;
`;

export const Address = styled.h5`
  text-align: left;

  div {
    margin: 32px 0;

    h5 {
      font-family: ${fonts.light};
      font-weight: 400;
      ${pxToCssFont(20, 30)}
      margin: 0;

      b {
        font-family: ${fonts.regular};
        font-weight: 400;
      }
    }
  }
`;


export const SectionTitle = styled(ParagraphContainer)`
  margin-top: 32px;
  font-family: ${fonts.exbold};
  font-weight: 400;
  ${pxToCssFont(30, 38)}
  color: ${colors.$262626};

  @media (min-width: ${breakpoints.desktop}) {
    margin-top: 48px;
    ${pxToCssFont(36, 45)}
  }
`;

export const Title = styled(ParagraphContainer)`
  margin-top: 32px;
  font-family: ${fonts.exbold};
  font-weight: 400;
  ${pxToCssFont(24, 30)}
  color: ${colors.$262626};

  @media (min-width: ${breakpoints.desktop}) {
    margin-top: 48px;
  }
`;

export const OffersList = styled.ul`
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 0;

  li {
    list-style: none;
    font-family: ${fonts.regular};
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-bottom: 56px;
  }
`;

export const Paragraph = styled.div`
  p,
  span {
    font-style: normal;
    font-weight: 400;
    ${pxToCssFont(18, 24)}
    color: ${colors.$0d0d0d};
  }

  div {
    margin: 0;
    margin-top: 16px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    div {
      margin: 0;
      margin-top: 8px;
    }
  }
`;

export const ParagraphBold = styled(Paragraph)`
  font-weight: 700;
  ${pxToCssFont(20, 26)}
  color: #333;

  @media (min-width: ${breakpoints.desktop}) {
    margin-top: 24px;
  }
`;

export const Price = styled(Title)`
    
    margin: 0;

    div {
      margin: 0;
       margin-top: 12px;
    }

    .rec {
      ${pxToCssFont(24, 28)}
    }

    @media (min-width: ${breakpoints.desktop}) {
      div {
        margin-top: 8px;
        ${pxToCssFont(24, 40)}

        b:first-of-type {
          ${pxToCssFont(42, 52)}
        }
      }

      .rec {
        ${pxToCssFont(24, 30)}
      }
    }
`;

export const Description = styled.div`
  div {
    margin: 0;
    margin-top: 18px;
    ${pxToCssFont(16, 22)}
    color: ${colors.$262626};
  }

  @media (min-width: ${breakpoints.desktop}) {
    div {
      margin: 0;
      margin-top: 18px;
    }
  }
`;

export const Spacer = styled.div`
  @media (min-width: ${breakpoints.desktop}) {
    padding-top: 16px;
  }
`;


