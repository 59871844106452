import {
  CMS_CONFIG,
  useCmsConfig,
  ICoverageToolCongifg,
  IgeographicAddressManagementResponse,
  IValidAddress,
  IgeographicAddressManagementRequest,
  INormValidAddress,
} from '@vfit/business-data-access';
import { ButtonSlideCustom, CustomText, RadioBox } from '@vfit/shared/atoms';
import { API, PAGES } from '@vfit/shared/data-access';
import { fonts, stringToAsciiCode } from '@vfit/shared/themes';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { Title } from './coverageTool.style';
import {
  CoverageToolContainer,
  CoverageToolContent,
  CoverageToolContentButtonAuto,
  WrapperBoxRadioBox,
} from './coverageToolContainers.style';
import { useAddressAndVillage } from '../coverageTool.hooks';
import { ICoverageToolProps, ICoverageToolType, IInputs } from './coverageTool.models';
import { checkAddressFromValue, retrieveAddress } from './coverageTool.utils';
import CoverageToolError from '../CoverageToolError/coverageToolError';

const CoverageToolListAddress = ({ onSubmit, onBack, offerId }: ICoverageToolProps) => {
  const queryClient = useQueryClient();
  const { validAddress = {} } = queryClient.getQueryData(
    'geographicAddressManagement'
  ) as IgeographicAddressManagementResponse;
  const [candidates] = useState<INormValidAddress | undefined>(validAddress);
  const [selected, setSelected] = useState<IValidAddress | undefined>();
  const [onConfirmed, setOnConfirmed] = useState(false);

  const coverageToolConfig = useCmsConfig(
    CMS_CONFIG[PAGES.BUSINESS_FIXED_LINE],
    API.CMS_BFL_GET_COVERAGE_TOOLS
  ) as ICoverageToolCongifg;

  const handlerRadioBox = (e: React.FormEvent<HTMLInputElement>) => {
    if (isLoading) return;
    setSelected(validAddress.candidates?.[e.currentTarget.value]);
    setOnConfirmed(true);
  };

  if (onConfirmed) queryClient.invalidateQueries('geographicAddressManagement');

  const getAddress = () =>
    ({
      city: selected?.city || '',
      postcode: selected?.postcode || '',
      streetName: selected?.streetName || '',
      streetNr: selected?.streetNr || '',
      stateOrProvince: selected?.stateOrProvince || '',
      provideAlternatives: false,
    } as IgeographicAddressManagementRequest);

  const {
    isSuccess,
    isLoading,
    isError,
    data: addressValidated,
  } = useAddressAndVillage({
    address: getAddress(),
    onConfirmAddress: onConfirmed,
    setOnConfirmed,
  });

  useEffect(() => {
    if (!isSuccess) return;

    if (!addressValidated?.validAddress?.candidates) {
      confirmMutation(ICoverageToolType.MAPS);
    }
  }, [isSuccess]);

  const confirmMutation = async (coverageType: ICoverageToolType) => {
    const address = retrieveAddress({
      city: addressValidated?.validAddress?.city,
      postalCode: addressValidated?.validAddress?.postcode,
      stateOrProvince: addressValidated?.validAddress?.stateOrProvince,
      street: addressValidated?.validAddress?.streetName,
      streetNumber: addressValidated?.validAddress?.streetNr,
    });
    const addressGeocoder = await checkAddressFromValue(address);

    const objdAddress: IInputs = {
      city: addressValidated?.validAddress?.city || '',
      postalCode: addressValidated?.validAddress?.postcode || '',
      stateOrProvince: addressValidated?.validAddress?.stateOrProvince || '',
      street: addressValidated?.validAddress?.streetName || '',
      streetNumber: addressValidated?.validAddress?.streetNr || '',
      placeId: addressValidated?.validAddress?.addressCode || '',
      latitude: addressGeocoder?.latitude ? parseFloat(addressGeocoder.latitude) : 0,
      longitude: addressGeocoder?.longitude ? parseFloat(addressGeocoder.longitude) : 0,
    };

    if (onSubmit) {
      onSubmit(objdAddress, coverageType);
    }
  };

  const onPrev = () => {
    if (onBack) {
      onBack();
    }
  };

  const handleRetry = () => {
    queryClient.invalidateQueries('geographicAddressManagement');
    if (onBack) onBack();
  };

  if (isError) {
    return <CoverageToolError onRetry={handleRetry} offerId={offerId} />;
  }

  return (
    <CoverageToolContainer>
      <CoverageToolContent>
        <Title>{coverageToolConfig?.coveragetool?.multipleCandidates?.title || ''}</Title>
        <CustomText
          text={coverageToolConfig?.coveragetool?.multipleCandidates?.description || ''}
          textAlign="center"
          size={20}
          lineHeight={26}
          fontFamily={fonts.regular}
          modal
        />
        <WrapperBoxRadioBox>
          {candidates?.candidates?.map((candidate, id) => {
            const { city, postcode, stateOrProvince, streetName, streetNr } = candidate;
            const desc = `${streetName}, ${streetNr}, ${postcode} ${city} ${stateOrProvince}`;
            return (
              <RadioBox
                value={id}
                title=""
                key={stringToAsciiCode(id.toString())}
                description={desc}
                onChange={handlerRadioBox}
                disabled={isLoading}
                checked={selected?.streetName === candidate.streetName}
              />
            );
          })}
        </WrapperBoxRadioBox>
      </CoverageToolContent>
      <CoverageToolContentButtonAuto>
        <ButtonSlideCustom
          label={coverageToolConfig?.coveragetool?.multipleCandidates?.buttonEditAddress || ''}
          disabled={isLoading}
          onClick={() => onPrev()}
          style={coverageToolConfig?.coveragetool?.multipleCandidates?.buttonEditAddressStyle}
        />
      </CoverageToolContentButtonAuto>
    </CoverageToolContainer>
  );
};

export default CoverageToolListAddress;

