import { CustomText } from '@vfit/shared/atoms';
import { colors, fonts } from '@vfit/shared/themes';
import { Pills } from '@vfit/shared/components';
import {
  DeviceBrand,
  DeviceDetailOffer,
  DeviceFrontLabel,
  DeviceInfo,
  DeviceOfferWrapper,
  DevicePrice,
  DeviceShortDescription,
  DeviceSubTitle,
  DeviceTitle,
} from '../topDevice.style';
import { IDevice, IResourceData } from '../../WidgetDeviceHeroBanner/widgetDeviceHeroBanner.models';

const DeviceOffer = ({
  deviceData,
  resourceData,
  isSticky,
}: {
  deviceData: IDevice;
  resourceData: IResourceData;
  isSticky?: boolean;
}) => {
  const { frontLabel, offerLabel, tagDevice, brand, title, additionalOfferDetails } = deviceData;

  const { price, subTitle } = resourceData;

  return (
    <DeviceOfferWrapper>
      <DeviceInfo>
        <DeviceBrand>{brand}</DeviceBrand>
        <DeviceTitle>{title}</DeviceTitle>
        <DeviceSubTitle>{subTitle}</DeviceSubTitle>
      </DeviceInfo>
      <DeviceInfo>
        <DeviceFrontLabel>{frontLabel}</DeviceFrontLabel>
        <DevicePrice>
          <CustomText
            text={price || ''}
            fontFamily={fonts.exbold}
            textAlign="left"
            size={28}
            lineHeight={36}
          />
          <div className="discount">
            {tagDevice && <Pills pills={[tagDevice]} variant={colors.$00697c} opacity={0.8} />}
          </div>
        </DevicePrice>
        <DeviceShortDescription>{offerLabel}</DeviceShortDescription>
        {isSticky && (
          <DeviceDetailOffer>
            <CustomText
              text={additionalOfferDetails?.moreInfoContentHtml || ''}
              textAlign="left"
              size={18}
              lineHeight={24}
            />
          </DeviceDetailOffer>
        )}
      </DeviceInfo>
    </DeviceOfferWrapper>
  );
};

export default DeviceOffer;

