import {
  getOfferName,
  getPrice,
  getPriceDescription,
  getUnaTantumPriceDescription,
  IProductInfo,
  IPlaceholder,
  getCharacteristicValues,
} from '@vfit/business-data-access';
import { getFormattedPriceString } from '@vfit/business-data-access';
import { IProductCard } from './components/ProductCard/productCard.models';
import { IProductHub } from './widgetSliderProductsHub.models';

const formatProducts = (
  elements: IProductHub[],
  placeholder: IPlaceholder[],
  productInfo: IProductInfo[] = []
): IProductCard[] => {
  const products = elements
    .map((el) => {
      const productCatalog = productInfo.find(
        (item) => item.productSpecificationId === el?.dxlId?.toString()
      );

      if (el?.isEditorialCard === 'false' && !productCatalog) return undefined;

      return {
        tagLine: el?.tagLine,
        offerName:
          el?.overrideOfferName || (productCatalog && getOfferName(productCatalog, placeholder)),
        frontLabel: el?.frontLabel,
        bottomLabel: el?.offerLabel,
        overrideCategory: el?.overrideCategory,
        additionalOfferDetails: el?.additionalOfferDetails,
        offerSpecs: el?.shortCharacteristics,
        offerId: el?.dxlId,
        isEditorialCard: el?.isEditorialCard,
        taggingProductPrice:
          el?.taggingProductPrice || getFormattedPriceString(productCatalog) || '',
        priceDescription:
          el?.overrideCostActivationHtml ||
          (productCatalog && getPriceDescription(productCatalog, placeholder)),
        unaTantumPriceDescription:
          el?.overrideUnaTantumCostActivationHtml ||
          (productCatalog && getUnaTantumPriceDescription(productCatalog, placeholder)),
        shortDescription: el?.shortDescription,
        image: el?.imageBackgroundUrl,
        price:
          el?.overrideCostRenewalHtml || (productCatalog && getPrice(productCatalog, placeholder)),
        actions: el?.actions,
        pills: el?.tags,
        retarget: el?.retarget,
        version: el?.version,
        taggingProductName:
          el?.taggingProductName || (productCatalog && getOfferName(productCatalog, placeholder)),
        timeDiscount: productCatalog?.timeDiscount,
        timeDiscountLabels: {
          timeDiscountTag: getCharacteristicValues(productCatalog?.characteristic || [], 'timeDiscountTag'),
          timeDiscountDetails: getCharacteristicValues(productCatalog?.characteristic || [], 'timeDiscountDetails'),
          timeDiscountTagForDevicePages: getCharacteristicValues(productCatalog?.characteristic || [], 'timeDiscountTagForDevicePages'),
        }
      };
    })
    .filter(Boolean) as IProductCard[];

  return products;
};

export { formatProducts };

