import {
  ICoverageToolType,
  IInputsCoverageTool,
  IInputsCoverageToolError,
  IStepCard,
} from '@vfit/shared/models';
import React, { useContext, useEffect, useState } from 'react';
import { ID_FLOWS, MANUAL_FLOW, LIST_ADDRESS_FLOW, ID_FLOWS_TYPE } from './coverageToolModal.flow';
import { getCoverageToolFlow, organizeFlowsAppend } from './coverageToolModal.utils';

interface ICoverageToolModalProvider {
  children: React.ReactNode;
}

export type ICoverageToolModalContext = {
  steps: IStepCard[];
  setSteps: (step: IStepCard[]) => void;
  address: IInputsCoverageTool;
  offerId: string;
  errorCoverage: IInputsCoverageToolError;
  setAddress: (address: IInputsCoverageTool) => void;
  setOfferId: (offerId: string) => void;
  slidesGoBack: number;
  isManual: boolean;
  isAlternativeAddress: boolean;
  setErrorCoverage: (address: IInputsCoverageToolError) => void;
  setSlidesGoBack: (slides: number) => void;
  setIsManual: (isManual: boolean) => void;
  setIsAlternativeAddress: (isCandidatesAddress: boolean) => void;
  setIdFlow: (idFlow: ID_FLOWS_TYPE) => void;
  resetSteps: () => void;
  offerType: Record<string, string | boolean>;
  setOfferType: (offerInfo: Record<string, string | boolean>) => void;
};

export const DEFAULT_ADDRESS: IInputsCoverageTool = {
  placeId: '',
  city: '',
  postalCode: '',
  stateOrProvince: '',
  streetNumber: '',
  street: '',
  typeCoverage: ICoverageToolType.AUTO,
};

export const DEFAULT_OFFER_ID = '';

export const DEFAULT_ERROR_COVERAGE: IInputsCoverageToolError = {
  placeId: '',
  city: '',
  postalCode: '',
  stateOrProvince: '',
  streetNumber: '',
  street: '',
  typeCoverage: ICoverageToolType.AUTO,
  showInModal: false,
};

export const DEFAULT_SLIDES_GO_BACK = -1;

export const DEFAULT_IS_MANUAL = false;
export const ALTERNATIVE_ADDRESS = false;

export const CoverageToolModalContext = React.createContext<ICoverageToolModalContext>({
  steps: [],
  address: DEFAULT_ADDRESS,
  offerId: DEFAULT_OFFER_ID,
  errorCoverage: DEFAULT_ERROR_COVERAGE,
  slidesGoBack: DEFAULT_SLIDES_GO_BACK,
  isManual: DEFAULT_IS_MANUAL,
  isAlternativeAddress: ALTERNATIVE_ADDRESS,
  setOfferId: () => {},
  setAddress: () => {},
  setErrorCoverage: () => {},
  setSlidesGoBack: () => {},
  setIsManual: () => {},
  setIsAlternativeAddress: () => {},
  setSteps: () => {},
  setIdFlow: () => {},
  resetSteps: () => {},
  offerType: {},
  setOfferType: () => {},
});

export const CoverageToolModalProvider = ({ children }: ICoverageToolModalProvider) => {
  const [steps, setSteps] = useState(getCoverageToolFlow());
  const [offerId, setOfferIdData] = useState<string>(DEFAULT_OFFER_ID);
  const [address, setInfoAddress] = useState<IInputsCoverageTool>({
    ...DEFAULT_ADDRESS,
  });
  const [errorCoverage, setErrorCoverageAddress] = useState<IInputsCoverageTool>({
    ...DEFAULT_ERROR_COVERAGE,
  });
  const [slidesGoBack, setSlidesGoBackData] = useState<number>(DEFAULT_SLIDES_GO_BACK);
  const [isManual, setIsManualData] = useState<boolean>(DEFAULT_IS_MANUAL);
  const [isAlternativeAddress, setIsAlternativeAddress] = useState<boolean>(ALTERNATIVE_ADDRESS);
  const [idFlow, setIdFlow] = useState<ID_FLOWS_TYPE>('auto');
  const [offerType, setInfoOfferType] = useState<Record<string, string | boolean>>({});

  const setOfferId = (offerIdData: string) => {
    setOfferIdData(offerIdData);
  };
  const resetSteps = () => {
    setSteps(getCoverageToolFlow());
  };
  const setAddress = (addressInfo: IInputsCoverageTool) => {
    setInfoAddress({ ...addressInfo });
  };

  const setErrorCoverage = (addressInfo: IInputsCoverageToolError) => {
    setErrorCoverageAddress({ ...addressInfo });
  };

  const setSlidesGoBack = (slidesBack: number) => {
    setSlidesGoBackData(slidesBack);
  };

  const setIsManual = (isManualData: boolean) => {
    setIsManualData(isManualData);
  };

  const setOfferType = (offerInfo: Record<string, string | boolean>) => {
    setInfoOfferType(offerInfo);
  };

  useEffect(() => {
    const organizedStepManual = organizeFlowsAppend(
      steps,
      isManual,
      ID_FLOWS.AUTO,
      MANUAL_FLOW.MANUAL_CARD
    );
    setSteps(organizedStepManual);
  }, [isManual]);

  useEffect(() => {
    const organizedListAddress = organizeFlowsAppend(
      steps,
      isAlternativeAddress,
      idFlow,
      LIST_ADDRESS_FLOW.LIST_CARD
    );
    setSteps(organizedListAddress);
  }, [isAlternativeAddress]);

  return (
    <CoverageToolModalContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        steps,
        address,
        setAddress,
        offerId,
        setOfferId,
        errorCoverage,
        setErrorCoverage,
        slidesGoBack,
        setSlidesGoBack,
        isManual,
        isAlternativeAddress,
        setIsManual,
        setSteps,
        setIsAlternativeAddress,
        setIdFlow,
        resetSteps,
        setOfferType,
        offerType,
      }}
    >
      {children}
    </CoverageToolModalContext.Provider>
  );
};

export const useCoverageToolModalProvider = () => useContext(CoverageToolModalContext);

