import {
  IEcommerceCatalogResponse,
  useGetEcommerceCatalogDigitalSolutions,
} from '@vfit/business-data-access';
import { IBusinessHubDigitalSolutions } from './widgetHubDigitalSolutions.model';
import { formatDigitalSolutions } from './widgetHubDigitalSolutions.utils';

export const useWidgetHubDigitalSolutions = (widget: IBusinessHubDigitalSolutions) => {
  const product = useGetEcommerceCatalogDigitalSolutions({
    limit: widget.numberCards,
    category: widget.productType,
  }).data as IEcommerceCatalogResponse;

  const digitalSolutions = product && formatDigitalSolutions(widget, product.businessSolutionInfo);

  const tabsToShow = widget.tags.tagsLabel
    .filter((tag) => tag.isHidden !== 'true')
    .filter((tag) => digitalSolutions?.find((ds) => ds.tag === tag.label))
    .map((tag) => tag.label);

  return {
    title: widget.title,
    tabs: [widget.tags.allTagsLabel, ...tabsToShow],
    digitalSolutions,
    productNotAvailable: widget.productNotAvailable,
  };
};

