import { IActionSlide, IActionType } from '@vfit/shared/models';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { getFormatprops } from './widgetAddons.utils';
import { IBusinessAddons, IAddonsProps } from './widgetAddons.models';

export const useWidgetAddons = (widget: IBusinessAddons): IAddonsProps => {
  const formatPropscards = widget.elements ? getFormatprops(widget.elements) : [];
  const { push } = useRouter();
  const [modal, setModal] = useState({
    show: false,
    text: '',
    title: '',
  });

  const onActionClick = (action?: IActionSlide, key?: string) => {
    const type = action?.type ? +action.type : 0;
    const addon = widget.elements?.find((el) => el.addonId === key);
    switch (type) {
      case IActionType.CUSTOM_URL:
        if (action?.url) window.open(action.url, action?.isBlank === 'true' ? '_blank' : '_self');
        break;

      case IActionType.LOCAL_URL:
        if (action?.url) push(action?.url);
        break;

      case IActionType.POPUP_DETAIL:
        if (addon?.popupDetail) {
          setModal({
            show: true,
            text: addon?.popupDetail.descriptionHtml,
            title: addon?.popupDetail.title,
          });
        }
        break;
      default:
        break;
    }
  };
  return {
    topText: widget.titleHtml,
    description: widget.descriptionHtml,
    divider: widget.divider,
    disclaimer: widget.disclaimerHtml,
    newCards: formatPropscards,
    onClickCardAction: onActionClick,
    modal,
    setModal,
  };
};

