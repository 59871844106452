import { cssprops, pxToCssFont, pxToRem } from '@vfit/shared/themes';
import styled from 'styled-components';
import { checkbox } from '../../inputs.style';
import { ICheckboxForStyled } from './checkbox.models';

export const Label = styled.label<ICheckboxForStyled>`
  ${pxToCssFont(14, 18)}
  cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'not-allowed')};
  display: flex;
  ${cssprops.styled}
`;

export const Input = styled.input<ICheckboxForStyled>`
  ${checkbox}
  cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'not-allowed')};
  margin-right: ${pxToRem(8)};
`;

