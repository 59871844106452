import { forwardRef, useId } from 'react';
import { colors, purify } from '@vfit/shared/themes';
import { IRadioBox } from './radioBox.models';
import * as S from './radioBox.style';
import { Svg } from '../Svg';

export const RadioBox = forwardRef<HTMLInputElement, IRadioBox>(
  (
    {
      children,
      detail,
      description,
      icon,
      iconColor = colors.$008a00,
      iconText,
      id: _id,
      title,
      ...rest
    },
    ref
  ) => {
    const id = useId();
    const [pDescription] = purify([description!]);

    return (
      <S.Container>
        <S.Input {...rest} type="radio" ref={ref} id={_id || id} hasDetail={!!detail} />

        <S.Label htmlFor={_id || id}>
          {detail && <S.Detail>{detail}</S.Detail>}
          <S.Title hasDetail={!!detail}>{title}</S.Title>
          {pDescription && (
            <S.Description
              hasIcon={!!icon}
              dangerouslySetInnerHTML={{
                __html: pDescription,
              }}
            />
          )}
          {icon && (
            <S.Icon color={iconColor}>
              <Svg name={icon} color={iconColor} width={16} />
              {iconText}
            </S.Icon>
          )}
          {children}
        </S.Label>
      </S.Container>
    );
  }
);
