/* stylelint-disable no-descending-specificity */
import styled from 'styled-components';
import {
  breakpoints,
  colors,
  fonts,
  pxToCssFont,
  pxToCssFontSize,
  pxToFontSize,
  pxToLineHeight,
} from '@vfit/shared/themes';

interface IProductCardTop {
  large?: boolean;
  haveImage?: boolean;
}

interface IProductCardContainer {
  noMargin?: boolean;
  haveOfferLabel?: boolean;
}

export const ProductCardContainer = styled.div<IProductCardContainer>`
  display: flex;
  flex: 1;
  border-radius: 20px 20px 0 0;
  flex-direction: column;
  margin-left: ${({ noMargin }) => (noMargin ? '0' : '20px')};
  position: ${({ haveOfferLabel }) => (haveOfferLabel ? 'relative' : 'inherit')};
  bottom: ${({ haveOfferLabel }) => (haveOfferLabel ? '40px' : 'inherit')};

  @media (min-width: ${breakpoints.tablet}) {
    margin-left: 0;
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-left: 0;
  }
`;

export const ProductCardTop = styled.div<IProductCardTop>`
  display: flex;
  z-index: 1;
  flex-direction: column;
  justify-content: ${({ haveImage }) => (haveImage ? 'space-between' : 'flex-start')};
  ${({ haveImage }) => (haveImage ? 'min-height: 150px' : '')};
  ${({ haveImage }) => (haveImage ? 'max-height: 240px' : '')};
  background-color: ${colors.$ffffff};
  position: relative;
  border: ${({ haveImage }) => (haveImage ? 'none' : '1px solid #ccc')};
  border-bottom: none;
  border-radius: 20px 20px 0 0;
  padding-left: 24px;
  padding-right: 24px;
  transition: transform 0.9s ease-in-out;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;

  @media (min-width: ${breakpoints.tablet}) {
    ${({ haveImage, large }) =>
      haveImage
        ? `height: ${large ? '300px' : '170px'};
            max-height: ${large ? '300px' : '240px'};`
        : ''}
    padding-left: 24px;
    padding-right: 24px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    ${({ haveImage, large }) =>
      haveImage
        ? `height: ${large ? '300px' : '240px'};
            max-height: ${large ? '300px' : '240px'};`
        : ''}
    padding-left: 36px;
    padding-right: 36px;
  }
`;

export const ProductCardBottom = styled.div`
  z-index: 2;
  background: ${colors.$ffffff};
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 20px 20px;
`;

export const ProductCardBottomContainer = styled.div`
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 32px;

  @media (min-width: ${breakpoints.tablet}) {
    margin-left: 24px;
    margin-right: 24px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-left: 36px;
    margin-right: 36px;
  }
`;

export const H3Header = styled.div<{ haveImage: boolean }>`
  font-family: ${fonts.exbold};
  font-style: normal;
  font-weight: 400;
  ${pxToCssFontSize(30)}
  color: ${({ haveImage }) => (haveImage ? colors.$ffffff : colors.$333333)};
  margin: 0;
  margin-bottom: ${({ haveImage }) => (haveImage ? '16px' : '0')};

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFontSize(30)}
  }

  @media (min-width: ${breakpoints.desktop}) {
    ${pxToCssFontSize(42)}
  }
`;

export const ProductCardTopContent = styled.div<{ haveImage: boolean }>`
  padding-top: ${({ haveImage }) => (!haveImage ? '24px' : '')};
  align-items: flex-end;

  h2 {
    text-transform: uppercase;
    font-family: ${fonts.regular};
    font-style: normal;
    font-weight: 700;
    ${pxToCssFontSize(14)}
    color: ${colors.$ffffff};
    margin: 0;
    margin-bottom: 4px;
    margin-top: 24px;
  }

  h3 {
    font-family: ${fonts.exbold};
    font-style: normal;
    font-weight: 400;
    ${pxToCssFontSize(30)}
    color: ${colors.$ffffff};
    margin: 0;
    margin-bottom: 16px;
  }

  @media (min-width: ${breakpoints.tablet}) {
    h3 {
      ${pxToCssFontSize(30)}
    }

    h2 {
      ${pxToCssFontSize(14)}
      margin-bottom: 4px;
      margin-top: 24px;
    }
  }

  @media (min-width: ${breakpoints.desktop}) {
    h3 {
      ${pxToCssFontSize(42)}
    }

    h2 {
      ${pxToCssFontSize(18)}
      margin-bottom: 7px;
      margin-top: 33px;
    }
  }
`;

export const DivImgContainer = styled.div<{ absolute: boolean }>`
  height: 100%;
  width: 100%;
  min-height: 100%;
  min-width: 100%;
  overflow: hidden;
  z-index: -1;
  object-fit: cover;
  object-position: center;
  border-radius: 15px 15px 0 0;
  position: ${(props) => (props.absolute ? 'absolute' : 'relative')};
  top: ${(props) => (props.absolute ? 0 : '')};
  left: ${(props) => (props.absolute ? 0 : '')};

  img {
    transition: transform 0.9s ease-in-out;
  }

  &:hover {
    img {
      transform: inherit;
    }
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: inline-block;
    background: linear-gradient(1.14deg, rgba(0, 0, 0, 40%) 11.28%, rgba(0, 0, 0, 0%) 75.72%);
  }
`;

export const ProductCardSpecItem = styled.div`
  display: flex;
  align-items: baseline;
  margin: 0;
  margin-bottom: 8px;

  svg {
    margin-right: 16px;
    width: 16px;
  }

  @media (min-width: ${breakpoints.tablet}) {
    margin-bottom: 16px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-bottom: 16px;
  }
`;

export const ProductCardBottomContainerText = styled.div`
  margin-top: 32px;
  margin-bottom: 32px;

  h6 {
    font-family: ${fonts.regular};
    font-weight: 700;
    ${pxToCssFont(16, 22)}
    color: ${colors.$262626};
    margin: 0;
  }

  .productTitle div {
    margin: 0;
    color: ${colors.$262626};
    font-weight: 400;
    display: flex;
    align-items: baseline;

    .price {
      ${pxToCssFont(30, 38)}

      @media (min-width: ${breakpoints.desktop}) {
        white-space: nowrap;
        ${pxToCssFont(24, 40)}

        b:first-of-type {
          ${pxToCssFont(42, 52)}
        }
      }
    }

    .rec {
      ${pxToCssFont(24, 28)}

      @media (min-width: ${breakpoints.desktop}) {
        ${pxToCssFont(36, 45)}
      }
    }
  }

  h4 {
    margin: 0;
    margin-bottom: 10px;
    font-family: ${fonts.regular} !important;
    color: ${colors.$7e7e7e} !important;
    font-weight: 700 !important;
    font-size: ${pxToFontSize(18)} !important;
    line-height: ${pxToLineHeight(18, 24)} !important;
  }

  .productDescription div {
    margin: 0;
    color: ${colors.$0d0d0d};
  }

  .additionalOfferDetails {
    margin-top: 8px;
    min-height: 32px;
    p {
      font-weight: 400;
      ${pxToCssFontSize(14)}
      line-height: ${pxToLineHeight(14, 18)}
    }
  }

  @media (min-width: ${breakpoints.tablet}) {
    .productTitle div {
      ${pxToCssFontSize(24)}
    }

    h4 {
      ${pxToCssFontSize(16)}
    }

    h6 {
      ${pxToCssFontSize(18)}
    }

    .productDescription div {
      ${pxToCssFontSize(14)}
    }
  }

  @media (min-width: ${breakpoints.desktop}) {
    .productTitle div {
      ${pxToCssFontSize(36)}
    }

    h4 {
      ${pxToCssFontSize(20)}
      font-family: ${fonts.light};
    }

    .productDescription div {
      ${pxToCssFontSize(18)}
    }
  }
`;

export const ProductCardSpecContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: flex-start;
  justify-content: space-between;
`;

export const ProductCardSpecTitle = styled.p`
  font-family: ${fonts.regular};
  font-weight: 700;
  ${pxToCssFontSize(20)}
  color: ${colors.$262626};
  margin: 0;
`;

export const ProductCardSpecItemAction = styled.button`
  display: flex;
  margin-left: 16px;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  background: ${colors.$e60000};
  border-radius: 100px;
  color: white;
  width: 26px;
  height: 26px;
  text-align: center;
  align-items: center;
  justify-content: center;
  ${pxToCssFontSize(26)}
  font-family: ${fonts.light};

  &:hover {
    background: ${colors.$bd0000};
  }

  &:active {
    background: ${colors.$a10000};
  }
`;

export const ProductCardOfferLabel = styled.div<{ haveLabel: boolean }>`
  background-color: ${({ haveLabel }) => (haveLabel ? '#00697C' : 'transparent')};
  border-radius: 20px;
  height: 73px;

  span {
    font-family: ${fonts.regular};
    font-style: normal;
    font-weight: 700;
    ${pxToCssFont(12, 16)}
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: ${colors.$ffffff};
    padding-top: 10px;
    margin-left: 36px;
    display: flex;
  }
`;

export const ProductCardSpecText = styled.div`
  div {
    font-family: ${fonts.regular};
    font-weight: 400;
    ${pxToCssFontSize(20)}
    color: ${colors.$262626};
    margin: 0;
  }
`;

export const ModalTitle = styled.div`
  div {
    margin: 0;
    ${pxToCssFont(18, 16)}
  }

  @media (min-width: ${breakpoints.desktop}) {
    div {
      ${pxToCssFont(36, 45)}
    }
  }
`;

export const ModalContent = styled.div`
  div {
    margin: 0;
  }
`;

export const ProductCardCategory = styled.div`
  font-family: ${fonts.regular};
  font-style: normal;
  font-weight: 700;
  ${pxToCssFontSize(14)}
  color: ${colors.$333333};
  text-transform: uppercase;

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFontSize(14)}
  }

  @media (min-width: ${breakpoints.desktop}) {
    ${pxToCssFontSize(18)}
  }
`;

