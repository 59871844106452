import { colors } from "@vfit/shared/themes";
import styled from "styled-components";
import { IClickBoxStyleProps } from "./clickBox.model";

export const ClickBoxContainer = styled.div<IClickBoxStyleProps>`
    display: flex;
    width: 100%;
    border-radius: 10px;
    margin: ${({ selected }) => (selected ? `1px` :  `2px`)}; 
    border: ${({ selected, color }) => (selected? `2px solid ${color}` :  `1px solid ${colors.$bebebe}`)};
    cursor: pointer;
    position: relative;

    &:hover {
        margin: 1px;
        border: ${({ color }) => (`2px solid ${color}`)}
    }
`;

export const FullCoverContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${colors.$ffffff};
    border-radius: 10px;
    opacity: 0.6;
    z-index: 1;
`