/**
 *
 * Interfaces
 *
 */
export enum ICoverageToolType {
  MANUAL,
  AUTO,
  MAPS,
  ERROR,
  LIST_ADDRESS,
}

export interface ILoaderInterface {
  apiKey: string;
  version: string;
  libraries: any;
  language: string;
  region: string;
}
export interface IAddressObject {
  city: string | undefined;
  postalCode: string | undefined;
  stateOrProvince: string | undefined;
  street: string | undefined;
  streetNumber: string | undefined;
}

export interface IInputs extends IAddressObject {
  placeId: string | undefined;
  latitude?: number;
  longitude?: number;
  region?: string;
  completeAddress?: string;
  isHiddenStreetNumber?: boolean;
}

export interface IInputsCoverageTool extends IInputs {
  typeCoverage?: ICoverageToolType;
  id?: string;
}

export interface IInputsCoverageToolError extends IInputsCoverageTool {
  showInModal?: boolean;
  errorType?: number;
}

export interface ICoverageToolProps {
  onSubmit?: (data: IInputs, coverageToolType: ICoverageToolType) => void;
  onBack?: () => void;
  state?: IInputs;
  offerId: string;
  setState?: (state: IInputs) => void;
  onReset?: () => void;
  onCloseError?: () => void;
}

export interface ICSSProps {
  isManual?: boolean;
}

export interface IGeoAddress {
  long_name: string;
  short_name: string;
  types: string[];
}

export interface IGeoAddressElab {
  placeId?: string;
  street: string;
  streetNumber?: string;
  city: string;
  province: string;
  postalCode: string;
  latitude?: string;
  longitude?: string;
}

export interface IPikedForMutation {
  region: string | undefined;
  latitude: string;
  longitude: string;
  placeId: string;
}

