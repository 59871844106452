import { Shadow } from '@vfit/shared/atoms';
import { useDeviceType } from '@vfit/shared/hooks';
import SkeletonDefault from './SkeletonDefault';

const SkeletonDesktop = () => (
  <div
    style={{
      width: '100%',
      display: 'flex',
      gap: '150px',
      justifyContent: 'flex-start',
      margin: '140px 80px',
    }}
  >
    {/* gallery image tumbnail */}
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '24px',
      }}
    >
      <div
        style={{
          width: '77px',
          height: '500px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          gap: '16px',
        }}
      >
        <div style={{ width: '77px' }}>
          <Shadow height={77} />
        </div>
        <div style={{ width: '77px' }}>
          <Shadow height={77} />
        </div>
        <div style={{ width: '77px' }}>
          <Shadow height={77} />
        </div>
        <div style={{ width: '77px' }}>
          <Shadow height={77} />
        </div>
        <div style={{ width: '77px' }}>
          <Shadow height={77} />
        </div>
      </div>
      <div style={{ minWidth: '300px', maxWidth: '500px' }}>
        <Shadow height={300} />
      </div>
    </div>

    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
      }}
    >
      {/* tagline */}
      <div style={{ width: '300px', display: 'flex', gap: '5px', marginBottom: '16px' }}>
        <div style={{ width: '100px' }}>
          <Shadow height={20} />
        </div>
        <div style={{ width: '100px' }}>
          <Shadow height={20} />
        </div>
        <div style={{ width: '100px' }}>
          <Shadow height={20} />
        </div>
      </div>

      <div style={{ width: '50px', marginBottom: '5px' }}>
        <Shadow height={30} />
      </div>

      <div style={{ minWidth: '300px', maxWidth: '400px', marginBottom: '16px' }}>
        <Shadow height={50} />
      </div>

      <div style={{ width: '50px', marginBottom: '5px' }}>
        <Shadow height={20} />
      </div>
      {/* price */}
      <div
        style={{
          width: '300px',
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
          marginBottom: '5px',
        }}
      >
        <div style={{ width: '150px' }}>
          <Shadow height={40} />
        </div>
        <div style={{ width: '150px' }}>
          <Shadow height={20} />
        </div>
      </div>
      <div style={{ width: '80px', marginBottom: '16px' }}>
        <Shadow height={20} />
      </div>
      {/* details */}
      <div
        style={{
          width: '200px',
          marginBottom: '16px',
          display: 'flex',
          gap: '5px',
          flexDirection: 'column',
        }}
      >
        <div style={{ width: '200px' }}>
          <Shadow height={20} />
        </div>
        <div style={{ width: '200px' }}>
          <Shadow height={20} />
        </div>
        <div style={{ width: '200px' }}>
          <Shadow height={20} />
        </div>
      </div>
      <div style={{ width: '200px', marginBottom: '32px' }}>
        <Shadow height={20} />
      </div>
      <div
        style={{
          minWidth: '300px',
          maxWidth: '500px',
          display: 'flex',
          gap: '16px',
          marginBottom: '40px',
        }}
      >
        <div style={{ minWidth: '100px' }}>
          <Shadow height={50} />
        </div>
        <div style={{ minWidth: '100px' }}>
          <Shadow height={50} />
        </div>
        <div style={{ minWidth: '100px' }}>
          <Shadow height={50} />
        </div>
      </div>
      <div
        style={{
          minWidth: '300px',
          maxWidth: '500px',
          display: 'flex',
          gap: '16px',
          marginBottom: '40px',
        }}
      >
        <div style={{ minWidth: '100px' }}>
          <Shadow height={50} />
        </div>
        <div style={{ minWidth: '100px' }}>
          <Shadow height={50} />
        </div>
        <div style={{ minWidth: '100px' }}>
          <Shadow height={50} />
        </div>
      </div>
    </div>
  </div>
);

const SkeletonMobile = () => (
  <div
    style={{
      width: '100%',
      display: 'flex',
      gap: '30px',
      justifyContent: 'flex-start',
      alignItems: 'center',
      padding: '20px',
    }}
  >
    <div
      style={{
        width: '100%',
        display: 'flex',
        gap: 30,
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
      }}
    >
      <div style={{ width: '200px' }}>
        <Shadow height={20} />
      </div>
      <div style={{ width: '100px' }}>
        <Shadow height={30} />
      </div>

      <div style={{ width: '200px' }}>
        <Shadow height={20} />
      </div>
      <div style={{ width: '200px' }}>
        <Shadow height={30} />
      </div>
      <div style={{ width: '50px' }}>
        <Shadow height={10} />
      </div>
      <div style={{ width: '300px' }}>
        <Shadow height={30} />
      </div>
      <div style={{ width: '100px' }}>
        <Shadow height={20} />
      </div>
      <div style={{ width: '100px' }}>
        <Shadow height={20} />
      </div>
      <div style={{ width: '100px' }}>
        <Shadow height={20} />
      </div>
      <div style={{ width: '100px' }}>
        <Shadow height={20} />
      </div>
    </div>
  </div>
);

const SkeletonPdpDevice = () => {
  const { isMobile, isDesktop } = useDeviceType();

  if(isDesktop) return <SkeletonDesktop />
  if(isMobile) return <SkeletonMobile />
  return <SkeletonDefault />
};

export default SkeletonPdpDevice;

