import { IStepCard } from '@vfit/shared/models';
import { COVERAGE_TOOL_FLOW } from './coverageToolModal.flow';

const getCoverageToolFlow = () => COVERAGE_TOOL_FLOW.DEFAULT;

const organizeFlowsAppend = (
  flowCards: IStepCard[],
  enableAppend: boolean,
  idFromStep: string,
  appendSteps: IStepCard[]
) => {
  const indexAppend = flowCards.findIndex((el) => el.title === idFromStep);
  let organizedFlows = flowCards.slice(0, indexAppend + 1);
  const lastFlows = flowCards.slice(indexAppend + 1);
  if (enableAppend) {
    const alreadyPresent = appendSteps[0].title === lastFlows[0].title;
    if (alreadyPresent) return flowCards;
    organizedFlows = organizedFlows.concat(appendSteps);
    organizedFlows = organizedFlows.concat(lastFlows);
  } else if (lastFlows[0].title === appendSteps[0].title) {
    const removedAppendInfo = lastFlows.slice(appendSteps.length);
    organizedFlows = organizedFlows.concat(removedAppendInfo);
  } else if (appendSteps?.findIndex((aS) => aS.title === lastFlows[0].title) > -1) {
    const removedAppendInfo = lastFlows.slice(1);
    organizedFlows = organizedFlows.concat(removedAppendInfo);
  } else {
    organizedFlows = organizedFlows.concat(lastFlows);
  }
  return organizedFlows;
};

export { getCoverageToolFlow, organizeFlowsAppend };

