import { getActionUrlByActionType } from '@vfit/shared/data-access';
import { ISplitted } from './widgetSplittedList.models';

/**
 * Add in documentation only for splitted images text widget
 * @param elements
 */
const getCardsList = (elements: ISplitted[]) =>
  elements.map((el, indexCard) => {
    const isLastCard = indexCard === elements.length - 1;

    return {
      title: el?.title || '',
      description: el?.description || '',
      urlImg: el?.image || '',
      urlImgMobile: el?.imageMobile || '',
      action: {
        ...el?.action,
        style: Number(el?.action?.style),
      },
      isLastCard,
      ...(el?.action && { actionNavigationUrl: getActionUrlByActionType(el?.action) }),
    };
  });

export { getCardsList };

