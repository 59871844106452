import { getActionUrlByActionType } from '@vfit/shared/data-access';
import {
  IBusinessProductConnectionCards,
  IConnectionCardsProps,
} from './widgetConnectionCards.models';

export const useWidgetConnectionCards = (
  widget: IBusinessProductConnectionCards
): IConnectionCardsProps => ({
  topText: widget?.title,
  disclaimer: widget?.disclaimerHtml,
  divider: widget?.divider,
  slides: widget.elements.map((el) => ({
    ...el,
    ...(el?.action && { actionNavigationUrl: getActionUrlByActionType(el?.action) }),
  })),
});

