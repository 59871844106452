import { useEffect, useState } from 'react';
import { resetData } from '@vfit/shared/data-access';
import { useQueryClient } from 'react-query';
import { ICommonData } from '@vfit/shared/models';
import { DEFAULT_ADDRESS, useCoverageToolModalProvider } from '../../coverageToolModal.context';
import { ICoverageToolType, IInputs } from '../../CoverageTool/coverageTool.models';
import CoverageToolAuto from '../../CoverageTool/coverageToolAuto';
import { DISABLE_RESET } from '../../../OfferMapCard/offerMapCard.model';
import { IProductCard } from '../../coverageToolModal.models';

const CoverageToolAutoCard = ({ handleGoNextSlide }: ICommonData) => {
  const [enableNext, setEnableNext] = useState(false);
  const { setAddress, setIsManual, setIsAlternativeAddress, setIdFlow, resetSteps } =
    useCoverageToolModalProvider();
  const queryClient = useQueryClient();
  const isDisableReset = localStorage.getItem(DISABLE_RESET);
  const product: IProductCard | undefined = queryClient.getQueryData('coverageToolProduct');

  const resetCoverageToolData = async () => {
    if (!isDisableReset) {
      await resetData(queryClient, ['coverageToolInputAddress', 'validAddress']);
    }
  };

  useEffect(() => {
    resetCoverageToolData();
    resetSteps();
    setIsAlternativeAddress(false);
    setIsManual(false);
  }, []);

  const onSubmit = (data: IInputs, typeCoverage: ICoverageToolType) => {
    setAddress({
      ...data,
      typeCoverage,
    });
    if (typeCoverage === ICoverageToolType.LIST_ADDRESS) {
      setIdFlow('auto');
      setIsAlternativeAddress(true);
    }
    if (typeCoverage === ICoverageToolType.MANUAL) {
      setIsManual(true);
    }

    if (handleGoNextSlide) {
      setTimeout(() => {
        setEnableNext(true);
      }, 500);
    }
  };

  useEffect(() => {
    if (enableNext && handleGoNextSlide) {
      handleGoNextSlide();
    }
  }, [enableNext]);

  const onReset = () => {
    setAddress(DEFAULT_ADDRESS);
  };

  return (
    <CoverageToolAuto
      offerId={product?.offerId || ''}
      onSubmit={onSubmit}
      state={DEFAULT_ADDRESS}
      onReset={onReset}
    />
  );
};

export default CoverageToolAutoCard;

