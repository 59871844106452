import { Fade } from 'react-awesome-reveal';
import {
  IConfigCMSParams,
  IPlaceholderRequest,
  cmsDataID,
  useCmsConfig,
  useSwitchCmsData,
} from '@vfit/business-data-access';
import { useContext } from 'react';
import { PageLoadingContext } from '@vfit/business-data-access';
import { WidgetContainer } from '../Widgets/widgets.style';
import { useWidgetSliderProductHub } from './widgetSliderProductsHub.hook';
import { IBusinessWidgetSliderProductsHub } from './widgetSliderProductsHub.models';
import ProductsList from './components/ProductsList/productsList';
import SkeletonHub from '../SkeletonHub';
import ProductsWrapper from '../ProductsWrapper/ProductsWrapper';

const WidgetSliderProductsHub = ({ widget, isLastWidget }: IBusinessWidgetSliderProductsHub) => {
  const { isLoading } = useContext(PageLoadingContext);

  const { cmsConfig, api } = useSwitchCmsData(cmsDataID.PLACEHOLDER) as IConfigCMSParams;
  const { placeholders } = (useCmsConfig(cmsConfig, api) as IPlaceholderRequest) || {};

  const { title, products } = useWidgetSliderProductHub(widget, placeholders?.placeholders);

  if (isLoading) return <SkeletonHub />;

  return (
    <WidgetContainer isLastWidget={isLastWidget}>
      <Fade direction="up" triggerOnce>
        <ProductsList title={title} products={products} />
      </Fade>
    </WidgetContainer>
  );
};

export default ProductsWrapper(WidgetSliderProductsHub);
