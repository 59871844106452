import { IActionCustom, IActionType } from '@vfit/shared/models';
import { useContext, useState } from 'react';
import { useRouter } from 'next/router';
import { useVolaUtils } from '@vfit/business-data-access';
import { TrackingPageContext } from '@vfit/business-data-access';
import { IProductCard } from './productCard.models';

const useCardFunctionality = () => {
  const { descriptionVolaUrl } = useContext(TrackingPageContext);
  const { formatUrlVola, openPopupVola, formatDynamicVolaUrl } = useVolaUtils();

  const [isShowCoverageTool, setIsShowCoverageTool] = useState(false);
  const [isShowWhatsapp, setIsShowWhatsapp] = useState(false);
  const [showCheckout, setShowCheckout] = useState<string>('');
  const [modalDetails, setModalDetails] = useState({
    show: false,
  });

  const { push } = useRouter();

  const handleClick = (param: IActionCustom, product?: IProductCard, desc?: string) => {
    const type = param?.type ? +param.type : 0;
    switch (type) {
      case IActionType.CUSTOM_URL:
        if (param?.url) {
          window.open(param?.url, param?.isBlank === 'true' ? '_blank' : '_self');
        }
        break;
      case IActionType.LOCAL_URL:
        if (param?.url) push(param?.url);
        break;
      case IActionType.COVERAGE_TOOL:
        setIsShowCoverageTool(true);
        break;
      case IActionType.WHATSAPP_URL:
        setIsShowWhatsapp(true);
        break;
      case IActionType.CALL_ME_NOW:
        if (param.url) {
          if (descriptionVolaUrl && desc)
            openPopupVola(formatDynamicVolaUrl(param?.url, desc), 'vola-ctc');
          else openPopupVola(formatUrlVola(param?.url, product!), 'vola-ctc');
        }
        break;
      case IActionType.CHECKOUT_MODAL_MOBILE:
        setShowCheckout(product?.offerId || '');
        break;
      case IActionType.POPUP_DETAIL:
        setModalDetails({
          show: true,
        });
        break;
      default:
        break;
    }
  };

  return {
    handleClick,
    isShowCoverageTool,
    setIsShowCoverageTool,
    isShowWhatsapp,
    setIsShowWhatsapp,
    showCheckout,
    modalDetails,
    setModalDetails,
  };
};

export { useCardFunctionality };

