import { breakpoints, pxToCssFont } from '@vfit/shared/themes';
import styled from 'styled-components';

export const VersionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;



export const ModalTitle = styled.div`
  ${pxToCssFont(18, 16)}
  div {
    margin: 0;
    line-height: 42px;
    margin-bottom: 24px;
  }
`;

export const ModalDescription = styled.div`
  div {
    margin: 0;
    p {
      margin: 0;
    }
  }
`;

export const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: center;

  div {
    width: 219px;
  }

  @media (max-width: ${breakpoints.tablet}) {
    div {
      width: 100%;
    }
  }
`;

export const CheckBoxContainer = styled.div`
display: flex;
  span{
    ${pxToCssFont(14, 18)}
  }
`;

