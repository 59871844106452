import { useEffect, useState } from 'react';
import { ICommonData } from '@vfit/shared/models';
import { useQueryClient } from 'react-query';
import CoverageToolManual from '../../CoverageTool/coverageToolManual';
import { ICoverageToolType, IInputs } from '../../CoverageTool/coverageTool.models';
import { useCoverageToolModalProvider } from '../../coverageToolModal.context';
import { IProductCard } from '../../coverageToolModal.models';

const CoverageToolManualCard = ({ handleGoNextSlide, handleGoPrevSlide }: ICommonData) => {
  const { address, setAddress, setIsAlternativeAddress, setIdFlow } =
    useCoverageToolModalProvider();
  const [enableNext, setEnableNext] = useState(false);
  const queryClient = useQueryClient();
  const product: IProductCard | undefined = queryClient.getQueryData('coverageToolProduct');

  useEffect(() => {
    setIsAlternativeAddress(false);
  }, []);

  const goNextSlide = (data: IInputs, typeCoverage: ICoverageToolType) => {
    setAddress({
      ...data,
      typeCoverage,
    });
    if (typeCoverage === ICoverageToolType.LIST_ADDRESS) {
      setIdFlow('Manual');
      setIsAlternativeAddress(true);
    }
    if (handleGoNextSlide) {
      setTimeout(() => {
        setEnableNext(true);
      }, 500);
    }
  };

  useEffect(() => {
    if (enableNext && handleGoNextSlide) {
      handleGoNextSlide();
    }
  }, [enableNext]);

  const goPrevSlide = () => {
    if (handleGoPrevSlide) {
      handleGoPrevSlide();
    }
  };

  return (
    <CoverageToolManual
      offerId={product?.offerId || ''}
      onSubmit={goNextSlide}
      onBack={goPrevSlide}
      state={address}
    />
  );
};

export default CoverageToolManualCard;

