import { forwardRef, useContext, useEffect, useState } from 'react';
import { ImageAtoms, CustomText, ButtonSlideCustom, Button } from '@vfit/shared/atoms';
import { Fade } from 'react-awesome-reveal';
import { useDeviceType } from '@vfit/shared/hooks';
import { IActionStyle, IGradientImage } from '@vfit/shared/models';
import { Pills } from '@vfit/shared/components';
import { ITimerStatus, RetargetContext } from '@vfit/business-data-access';
import { colors, stringToAsciiCode } from '@vfit/shared/themes';
import { CTC_POSITION, useVolaUtils } from '@vfit/business-data-access';
import { getActionUrlByActionType, getVariantByActionStyle } from '@vfit/shared/data-access';
import {
  DivImgContainer,
  SlideProductContainer,
  SlideProductContent,
  SlideProductContentBottom,
  SlideProductContentTop,
  SlideProductNavigation,
  SlideProductText,
  SlideButtons,
  SlideProductButtonSlideContainer,
  IconContainer,
  TitleContent,
  TitleText,
} from './slideProduct.style';
import { ISlideProductProps } from './slideProduct.models';
import { NavBackLink } from '../../../NavBackLink/navBackLink';

const SlideProduct = forwardRef(
  (
    { product, disableNavigation = false, onActiveProduct, onButtonScroll }: ISlideProductProps,
    refButton: React.Ref<HTMLDivElement>
  ) => {
    const {
      offerId,
      title,
      price,
      image,
      frontLabel,
      backLabel,
      priceDescription,
      unaTantumPriceDescription,
      shortDescription,
      offerLabel,
      retarget,
      timeDiscount,
      timeDiscountLabels,
      version,
      subtitle,
      iconUrl,
      externalUrl = false,
      taggingProductPrice,
      taggingProductName,
    } = product;
    const { isMobile } = useDeviceType();
    const { checkRetargetActive } = useContext(RetargetContext);
    const { createUrlDesc } = useVolaUtils();
    const [timeDiscountLabel, setTimeDiscountLabel] = useState<string | undefined>(undefined);
    const [isRetarget, setIsRetarget] = useState<boolean>(false);

    useEffect(() => {
      setTimeDiscountLabel(timeDiscount && timeDiscountLabels?.timeDiscountTag);
      setIsRetarget(checkRetargetActive({ productId: offerId!, version }) === ITimerStatus.ACTIVE);
    }, [product]);

    const renderPill = () => {
      if (timeDiscountLabel || isRetarget) {
        return (
          <div className="containerPill">
            <Pills
              pills={timeDiscountLabel ? [timeDiscountLabel || ''] : [retarget?.label || '']}
              variant={colors.$00697c}
              opacity={1}
            />
          </div>
        );
      }
      return <span style={{ marginLeft: 30 }}>{frontLabel || '\u00A0'}</span>;
    };

    // WADG-4841 (SEO improvement) linked to WADG-5583 (shared button style change) - temporary solution to avoid changes on AEM side
    const formatActionStyle = (style?: IActionStyle) => {
      switch (style?.toString()) {
        case '1':
          return 1;
        case '2':
          return 3;
        case '3':
          return 3;
        default:
          return 1;
      }
    };

    return (
      <div>
        <SlideProductContainer key={offerId}>
          <SlideProductContent>
            <SlideProductContentTop>
              <SlideProductNavigation>
                {!disableNavigation && backLabel && (
                  <NavBackLink
                    navBackLinkUrl={backLabel?.url}
                    navBackLinkText={backLabel?.text}
                    navBackLinkColor="#ffffff"
                  />
                )}
              </SlideProductNavigation>
              <Fade triggerOnce>
                <SlideProductText>
                  <TitleContent>
                    {iconUrl && (
                      <IconContainer>
                        <div>
                          <ImageAtoms
                            nameCard="iconUrlSlideProduct"
                            image={iconUrl}
                            isMobile={isMobile}
                            external={externalUrl}
                            alt={`icona ${title || ''}`}
                          />
                        </div>
                      </IconContainer>
                    )}
                    {title && (
                      <TitleText iconUrl={!!iconUrl}>
                        <h3>{title}</h3>
                      </TitleText>
                    )}
                  </TitleContent>
                  {subtitle && (
                    <TitleText iconUrl={!!iconUrl}>
                      <h6>{subtitle}</h6>
                    </TitleText>
                  )}
                  <div className="text">
                    {renderPill()}
                    {price && (
                      <div className="price">
                        <CustomText text={price} textAlign="left" />
                      </div>
                    )}
                    {offerLabel && <h5>{offerLabel}</h5>}
                    {priceDescription && (
                      <div className="productDescription">
                        <CustomText text={priceDescription} textAlign="left" />
                      </div>
                    )}
                    {unaTantumPriceDescription && (
                      <div className="productDescription">
                        <CustomText text={unaTantumPriceDescription} textAlign="left" />
                      </div>
                    )}
                    {shortDescription && (
                      <div className="productDescription">
                        <CustomText text={shortDescription} textAlign="left" />
                      </div>
                    )}
                  </div>
                  <SlideButtons>
                    {product.actions?.map((cta, i) => {
                      const show = cta?.style != IActionStyle.PRIMARY || !isMobile;
                      return (
                        show && (
                          <SlideProductButtonSlideContainer
                            ref={refButton}
                            key={stringToAsciiCode(i.toString())}
                          >
                            <div>
                              <Button
                                onClick={() => {
                                  const desc = createUrlDesc({
                                    position: CTC_POSITION.TOP_PRODUCT,
                                    productName: taggingProductName || title,
                                    productPrice: taggingProductPrice,
                                  });
                                  !getActionUrlByActionType(cta) && onActiveProduct(cta, desc);
                                }}
                                href={getActionUrlByActionType(cta)}
                                target={
                                  getActionUrlByActionType(cta) && cta?.isBlank === 'true'
                                    ? '_blank'
                                    : undefined
                                }
                                variant={getVariantByActionStyle(formatActionStyle(cta?.style))}
                                name={`action_top_product_${cta.type}`}
                              >
                                {cta?.title}
                              </Button>
                            </div>
                          </SlideProductButtonSlideContainer>
                        )
                      );
                    })}
                  </SlideButtons>
                </SlideProductText>
              </Fade>
            </SlideProductContentTop>
            {!isMobile && (
              <SlideProductContentBottom>
                <ButtonSlideCustom
                  onClick={onButtonScroll}
                  animated
                  small
                  smallSize={50}
                  rotation={90}
                  buttonColor="#fff"
                  hoverColor="rgba(255, 255, 255, 0.8)"
                  clickColor="rgba(255, 255, 255, 0.6)"
                />
              </SlideProductContentBottom>
            )}
          </SlideProductContent>
          <DivImgContainer absolute>
            <ImageAtoms
              gradient={IGradientImage.GRADIENT_TOP_LEFT}
              nameCard="slideProduct"
              image={image}
              imageMobile={image}
              isMobile={isMobile}
              alt={`cover ${title || ''}`}
            />
          </DivImgContainer>
        </SlideProductContainer>
      </div>
    );
  }
);

export default SlideProduct;
