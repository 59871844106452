import { breakpoints, colors, fonts } from '@vfit/shared/themes';
import styled from 'styled-components';

export const ButtonDesc = styled.div`
  display: flex;
  margin: 0 23px 50px;
`;

export const DisclaimerContainer = styled.div`
  font-family: ${fonts.regular};
  font-weight: 700;
  color: ${colors.$7e7e7e};

  img {
    width: 18px;
  }

  a {
    color: ${colors.$262626} !important;

    :visited {
      color: ${colors.$262626} !important;
    }
  }
`;

export const ContainerDescription = styled.div`
  div p {
    margin-top: 32px;
    margin-bottom: 52px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    div p {
      margin-top: 80px;
    }
  }
`;

export const ActionContainer = styled.p`
  width: 324px;
  margin: 30px auto;
  div {
    margin-bottom: 16px;

    a {
      color: ${colors.$ffffff} !important;

      :visited {
        color: ${colors.$ffffff} !important;
      }
    }
  }
`;

export const StickyOfferContainerTopProduct = styled.div`
  #sticky-offer-product {
    a {
      color: ${colors.$262626};
      font-weight: 700;
    }

    a:hover {
      color: ${colors.$e60000};
    }

    a:visited {
      color: ${colors.$262626};
    }

    a:active {
      color: ${colors.$262626};
    }
  }
`;

