import styled from 'styled-components';

export const CoverageToolModalContainer = styled.div`
  position: fixed;
  overflow-y: scroll;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }

  top: 0;
  z-index: 4;
  width: 100%;
`;

