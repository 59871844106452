import {
  DXL,
  IConfigCMSParams,
  IDeviceInfo,
  IPlaceholderRequest,
  IPlaceholder,
  IResource,
  PlaceholdersID,
  cmsDataID,
  getDeviceTotalRenewalCost,
  getDeviceTotalCost,
  getLabel,
  getPrice,
  getPriceDevice,
  getPriceDeviceSpecificOffer,
  getPriceOfferAndDevice,
  getPriceOfferDevice,
  useCmsConfig,
  useSwitchCmsData,
  DeviceDetailsPlaceholders,
  getCharacteristicValues,
} from '@vfit/business-data-access';
import {
  IDevice,
  ICapacityandColor,
  IBusinessDeviceHeroBanner,
  IFilter,
  IFilterType,
  IOfferDevice,
  IResourceData,
  IDeviceMoreInfo,
} from './widgetDeviceHeroBanner.models';
import { getValues } from '../WidgetHubDevice/widgetHubDevice.utils';

const getFormatData = (
  device: IDeviceInfo,
  capacityAndColor: ICapacityandColor,
  widget: IBusinessDeviceHeroBanner,
  selectedRes?: IResource
): IDevice => {
  const allCapacity = capacityAndColor?.capacity?.filter((e) =>
    device?.capacity?.find((c) => c?.id === e?.capacityId)
  );
  const allColors = capacityAndColor?.colors?.filter((e) =>
    device?.colour?.find((c) => c?.id === e?.gradientId)
  );

  return (
    {
      // widget info
      deviceId: widget?.product.dxlId,
      deviceType: widget?.product?.productType,
      backLabel: widget?.product.backLabel,
      frontLabel: widget?.product.frontLabel,
      offerLabel: widget?.product.offerLabel,
      topPriceLabel: widget?.product.topPriceLabel,
      colorLabel: widget?.product.colorLabel,
      capacityLabel: widget?.product.capacityLabel,
      offerMatchLabel: widget?.product.offerMatchLabel,
      tags: widget?.product.tags,
      tagDevice: widget?.product.tagDevice,
      additionalOfferDetails: getAdditionalOfferDetails(
        widget?.product.additionalOfferDetails || {},
        selectedRes
      ),
      actions: widget?.product.actions,
      customAction: widget?.customAction,
      tagline:
        widget?.product.overrideTagLine || getValues(device?.characteristic || [], 'deviceTag'),

      // common device info
      brand: device?.deviceBrand,
      title: device?.deviceName,
      allCombinations: {
        colors: allColors || [],
        capacity: allCapacity || [],
      },
    } || {}
  );
};

const getResourceData = (
  device: IDeviceInfo,
  capacityAndColor: ICapacityandColor,
  widget: IBusinessDeviceHeroBanner,
  selectedRes?: IResource
): IResourceData => {
  const { cmsConfig, api } = useSwitchCmsData(cmsDataID.PLACEHOLDER) as IConfigCMSParams;
  const { placeholders } = useCmsConfig(cmsConfig, api) as IPlaceholderRequest;

  // capacity color labels
  const capacity = capacityAndColor?.capacity?.find(
    (el) => el?.capacityId === selectedRes?.resourceCapacity?.id
  );

  return selectedRes
    ? {
        resource: selectedRes,
        associatedOfferId: selectedRes?.product?.[0]?.productSpecificationId || '',
        subTitle: getLabel(
          { productName: selectedRes?.product?.[0]?.productName, capacity },
          placeholders.placeholders,
          PlaceholdersID.PRODUCT_TITLE_DEVICE
        ),
        deviceImages: (
          device?.characteristic?.find((el) => el?.name === 'colors')
            ?.value as DXL.ICharacteristicValue[]
        )?.filter((img) => img?.id === selectedRes?.resourceColour?.id),
        detailsOfferDevice: [
          getPriceOfferDevice(selectedRes, placeholders.placeholders) || '',
          getPrice(selectedRes?.product?.[0]!, placeholders.placeholders) || '',
          getPriceDevice(selectedRes, placeholders.placeholders) || '',
          widget?.product.shortDescription || '',
        ],
        price: getPriceOfferAndDevice(
          selectedRes,
          placeholders.placeholders,
          PlaceholdersID.TOTAL_COST_WITH_FEE
        ),
      }
    : ({} as IResourceData);
};

// START Resource funcions
const findFirstResource = (
  offerCombination: Record<string, IResource[]>,
  capacityAndColor: ICapacityandColor
) => {
  // select first offer
  const offerId = Object.keys(offerCombination)[0];
  const offerResource = offerCombination[offerId];

  const color =
    capacityAndColor?.colors?.find((c) =>
      offerResource?.find((r) => r?.resourceColour?.id === c?.gradientId)
    )?.gradientId || '';
  const capacity =
    capacityAndColor?.capacity?.find((c) =>
      offerResource?.find(
        (r) => r?.resourceCapacity?.id === c?.capacityId && r?.resourceColour?.id === color
      )
    )?.capacityId || '';

  return {
    resource: offerResource?.find(
      (r) => r?.resourceColour?.id === color && r?.resourceCapacity?.id === capacity
    ),
    offerId,
  };
};

const findResourceByOfferId = (offerResource: IResource[], filter: IFilter, type: IFilterType) => {
  const capacityResource = offerResource?.find((r) => r?.resourceCapacity?.id === filter?.capacity);
  const colourResource = offerResource?.find((r) => r?.resourceColour?.id === filter?.color);

  return type === 'capacity' ? capacityResource : colourResource;
};

const findNewResource = (
  offerCombination: Record<string, IResource[]>,
  filter: IFilter,
  type: IFilterType
) => {
  switch (type) {
    case 'capacity': {
      let capacityResOffer;
      const capacityOffer = Object.entries(offerCombination)?.find((el) => {
        capacityResOffer = el?.[1]?.find((e) => e?.resourceCapacity?.id === filter?.capacity);
        return capacityResOffer ? el : undefined;
      });

      return {
        resource: capacityResOffer,
        offerId: capacityOffer?.[0] || '',
      };
    }

    case 'color': {
      let colorResOffer;
      const colorOffer = Object.entries(offerCombination)?.find((el) => {
        colorResOffer = el?.[1]?.find((e) => e?.resourceColour?.id === filter?.color);
        return colorResOffer ? el : undefined;
      });
      return {
        resource: colorResOffer,
        offerId: colorOffer?.[0] || '',
      };
    }

    default:
      return {
        resource: offerCombination[filter?.offerId][0],
        offerId: filter?.offerId,
      };
  }
};
// END Resource funcions

const getFormatOffers = (
  offerCombination: Record<string, IResource[]>,
  selectedResource?: IResource
): IOfferDevice[] => {
  const { cmsConfig, api } = useSwitchCmsData(cmsDataID.PLACEHOLDER) as IConfigCMSParams;
  const { placeholders } = useCmsConfig(cmsConfig, api) as IPlaceholderRequest;

  return Object.entries(offerCombination)?.map((el) => {
    const id = el[0];
    const resource =
      selectedResource?.product?.[0]?.productSpecificationId === id ? selectedResource : el[1][0];
    const offer = resource?.product?.[0];

    return {
      id,
      title:
        getLabel(offer, placeholders.placeholders, PlaceholdersID.DEVICE_OFFER_NAME_LABEL) || '',
      description: getValues(offer?.characteristic || [], 'productShortDescription'),
      price: getPriceOfferAndDevice(
        resource,
        placeholders.placeholders,
        PlaceholdersID.TOTAL_COST_WITH_FEE_CUSTOMIZATION
      ),
      unaTantum: getPriceDeviceSpecificOffer(resource, placeholders.placeholders),
      deviceTimeDiscount: offer?.timeDiscount && getCharacteristicValues(offer?.characteristic || [], 'timeDiscountTagForDevicePages'),
    };
  });
};

const getDetailsPlaceholders = (
  placeholder: string,
  selectedRes: IResource,
  placeholders: IPlaceholder[]
) => {
  switch (placeholder) {
    case DeviceDetailsPlaceholders.MOBILEPHONE_PLAN: {
      return getPrice(selectedRes?.product?.[0]!, placeholders) || '';
    }
    case DeviceDetailsPlaceholders.RENEWAL_COST_DEVICE: {
      return getPriceOfferDevice(selectedRes, placeholders) || '';
    }
    case DeviceDetailsPlaceholders.TOTAL_RENEWAL_COST_DEVICE: {
      return getDeviceTotalRenewalCost(selectedRes, placeholders) || '';
    }
    case DeviceDetailsPlaceholders.DEVICE_COST_UNA_TANTUM: {
      return getPriceDevice(selectedRes, placeholders) || '';
    }
    case DeviceDetailsPlaceholders.TOTAL_DEVICE_COST: {
      return getDeviceTotalCost(selectedRes, placeholders) || '';
    }
    default:
      return '';
  }
};

const getAdditionalOfferDetails = (
  details: IDeviceMoreInfo,
  selectedRes?: IResource
): IDeviceMoreInfo => {
  const { cmsConfig, api } = useSwitchCmsData(cmsDataID.PLACEHOLDER) as IConfigCMSParams;
  const { placeholders } = useCmsConfig(cmsConfig, api) as IPlaceholderRequest;

  const reg = /([$])\w+/g;
  let newDetails = details.moreInfoContentHtml;

  details.moreInfoContentHtml?.match(reg)?.forEach((element) => {
    const replacedData = selectedRes
      ? getDetailsPlaceholders(element, selectedRes, placeholders.placeholders)
      : '';
    newDetails = newDetails?.replace(element, replacedData);
  });

  return {
    title: details.title,
    enableMoreInfo: details.enableMoreInfo,
    moreInfoContentHtml: newDetails,
    moreInfoTitle: details.moreInfoTitle,
  };
};

export {
  getFormatData,
  getResourceData,
  findFirstResource,
  findResourceByOfferId,
  findNewResource,
  getFormatOffers,
};

