import React from 'react';
import { SplittedImageTextCardList } from '@vfit/shared/components';
import { WidgetContainer } from '../Widgets/widgets.style';
import { IBusinessWidgetSplittedList } from './widgetSplittedList.models';
import { useWidgetSplittedList } from './widgetSplittedList.hook';

const WidgetSplittedList = ({ widget, isLastWidget }: IBusinessWidgetSplittedList) => (
  <WidgetContainer isLastWidget={isLastWidget}>
    <SplittedImageTextCardList {...useWidgetSplittedList(widget)} />
  </WidgetContainer>
);

export default WidgetSplittedList;

