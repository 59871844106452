import { ICMSWidgetType } from '@vfit/business-data-access';
import { useDeviceType } from '@vfit/shared/hooks';
import { IBusinessLSSlider } from './widgetLargeSmallSlider.models';
import { getDefaultSlider, getLargeSmallSlider } from './widgetLargeSmallSlider.utils';

const useWidgetLargeSmallSlider = (widget: IBusinessLSSlider) => {
  const { isDesktop, isMobile } = useDeviceType();
  const containerStyle: React.CSSProperties = {
    paddingTop: isMobile ? 32 : 48,
  };

  const getSlider = () => {
    switch (widget.type) {
      case ICMSWidgetType.SLIDER_LARGE_SMALL:
        return getLargeSmallSlider(widget);
      default:
        return getDefaultSlider(widget);
    }
  };

  const getOtherProps = () => {
    switch (widget.type) {
      case ICMSWidgetType.SLIDER_LARGE_SMALL:
        return {
          isCovered: true,
        };
      default:
        // DEFAULT_SLIDER
        return {
          isCovered: false,
        };
    }
  };

  return {
    sliderCarousel: getSlider(),
    otherProps: getOtherProps(),
    divider: widget.divider,
    disclaimer: widget.disclaimer,
    forceTextTop: isDesktop,
    containerStyle,
    isMobile,
  };
};

export { useWidgetLargeSmallSlider };
