import { breakpoints, fonts, pxToCssFont } from '@vfit/shared/themes';
import styled from 'styled-components';

export const ExtendedVersionContainer = styled.div``;

export const TopContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  button {
    margin-bottom: 24px;
  }
`;
export const BottomContainer = styled.div`
  border-top: 1px solid #bebebe;
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  span {
    font-size: 22px;
  }
`;

export const IconWrapper = styled.div`
  max-width: 32px;
`;

export const ShortVersionContainer = styled.div`
  button {
    margin-bottom: 24px;
  }
`;

export const ContentLeft = styled.div`
  width: 330px;
`;

export const ContentRight = styled.div`
  width: 250px;
`;

export const ModalTitle = styled.div`
  ${pxToCssFont(18, 16)}
  div {
    margin: 0;
    line-height: 42px;
    margin-bottom: 24px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    ${pxToCssFont(36, 45)}
  }
`;

export const ModalDescription = styled.div`
  div {
    margin: 0;
    line-height: 24px;
  }
`;

export const DescriptionFlex = styled.div`
  border-top: 1px solid #bebebe;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  div {
    margin: 16px 0 0;
    line-height: 24px;
  }
`;

export const ImageContainer = styled.div`
  max-width: 160px;
  margin: 24px auto;
`;

export const FreeNumerContainer = styled.div`
  margin-top: 24px;
  border-radius: 32px;
  padding: 10.5px 15px;
  cursor: pointer;
  width: 100%;
  background: #fff;
  border: 1px solid #262626;
  text-align: center;

  a {
    font-family: ${fonts.regular};
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.55;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #262626;
    text-decoration: none;
  }
`;

