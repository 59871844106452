import styled from 'styled-components';
import { breakpoints } from '@vfit/shared/themes';

const ButtonSlideCTA = styled.div<{ isApp?: boolean }>`
  height: 45px;
  div {
    span {
      display: ${({ isApp }) => (isApp ? 'inline-block' : '')};
      text-transform: ${({ isApp }) => (isApp ? 'lowercase' : '')};
      font-weight: ${({ isApp }) => (isApp ? '400' : '')};
      font-size: ${({ isApp }) => (isApp ? '16px' : '')};
    }
    span::first-letter {
      text-transform: uppercase;
    }
  }

  @media (min-width: ${breakpoints.mobile}) {
    width: ${({ isApp }) => `${isApp ? '100%' : '207px'}`};
  }

  @media (min-width: ${breakpoints.desktop}) {
    width: ${({ isApp }) => `${isApp ? '100%' : '324px'}`};
  }
`;

export default ButtonSlideCTA;
