import { SVGProps, memo } from 'react';

const AlertWarning = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg width="25" height="22" viewBox="0 0 25 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>{'Alert Warning'}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.3714 16.8458L23.453 17.0403L14.8814 1.98882C14.148 0.706911 13.1247 -9.53674e-07 12.0014 -9.53674e-07C10.878 -9.53674e-07 9.85469 0.706911 9.12135 1.9922L0.631353 16.8458C-0.103647 18.1311 -0.201981 19.3454 0.36302 20.3279C0.92802 21.3105 2.04302 21.8162 3.51135 21.8162H20.4914C21.9597 21.8162 23.078 21.3088 23.6397 20.3279C24.2014 19.3471 24.1064 18.1311 23.3714 16.8458ZM12.0013 19.3268C11.4058 19.324 10.9252 18.8318 10.928 18.2275C10.9308 17.6232 11.4158 17.1356 12.0113 17.1384C12.6049 17.1412 13.0847 17.6303 13.0847 18.2326V18.2326C13.0837 18.8378 12.5995 19.3277 12.003 19.3268C12.0024 19.3268 12.0019 19.3268 12.0013 19.3268H12.0013ZM13.0763 5.60963L12.723 14.5881C12.723 14.9925 12.3999 15.3204 12.0013 15.3204C11.6028 15.3204 11.2797 14.9925 11.2797 14.5881L10.923 5.61132C10.8453 5.00935 11.2633 4.45747 11.8565 4.37866C12.4498 4.29985 12.9937 4.72395 13.0713 5.32592C13.0836 5.42065 13.0836 5.51659 13.0713 5.61132L13.0763 5.60963Z"
        fill="white"
      />
    </svg>
  );
};

export default memo<SVGProps<SVGSVGElement>>(AlertWarning);
