import { Shadow } from '@vfit/shared/atoms';
import { useDeviceType } from '@vfit/shared/hooks';

const Card = ({ marginAuto = false }) => (
  <div
    style={{
      height: '493px',
      width: '327px',
      border: '1px solid #BEBEBE',
      borderRadius: '20px',
      overflow: 'hidden',
      marginLeft: marginAuto ? 'auto' : '16px',
      marginRight: marginAuto ? 'auto' : '16px',
      marginBottom: '24px',
    }}
  >
    <Shadow height={200} />
    <div style={{ height: '290px', padding: '32px' }}>
      <div style={{ width: '200px', marginTop: '10px' }}>
        <Shadow height={30} />
      </div>
      <div style={{ width: '180px', marginTop: '25px' }}>
        <Shadow height={16} />
      </div>
      <div style={{ width: '180px', marginTop: '15px' }}>
        <Shadow height={16} />
      </div>
      <div style={{ width: '100%', margin: '70px auto 32px' }}>
        <Shadow height={44} />
      </div>
    </div>
  </div>
);

const SkeletonDesktop = () => (
  <div>
    <div style={{ width: '400px', margin: '80px auto 40px' }}>
      <Shadow height={40} />
    </div>
    <div style={{ width: '700px', margin: '0 auto', display: 'flex', gap: '32px' }}>
      <div style={{ width: '150px' }}>
        <Shadow height={30} />
      </div>
      <div style={{ width: '150px' }}>
        <Shadow height={30} />
      </div>
      <div style={{ width: '150px' }}>
        <Shadow height={30} />
      </div>
      <div style={{ width: '150px' }}>
        <Shadow height={30} />
      </div>
    </div>
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        margin: '50px 80px',
      }}
    >
      <Card />
      <Card />
      <Card />
    </div>
  </div>
);

const SkeletonTablet = () => (
  <div>
    <div style={{ width: '400px', margin: '80px auto 40px' }}>
      <Shadow height={40} />
    </div>
    <div style={{ width: '600px', margin: '0 auto', display: 'flex', gap: '32px' }}>
      <div style={{ width: '140px' }}>
        <Shadow height={30} />
      </div>
      <div style={{ width: '140px' }}>
        <Shadow height={30} />
      </div>
      <div style={{ width: '140px' }}>
        <Shadow height={30} />
      </div>
      <div style={{ width: '140px' }}>
        <Shadow height={30} />
      </div>
    </div>
    <div style={{ display: 'flex', justifyContent: 'center', margin: '50px 20px' }}>
      <Card />
      <Card />
    </div>
  </div>
);

const SkeletonMobile = () => (
  <div>
    <div style={{ width: '250px', margin: '80px auto 40px' }}>
      <Shadow height={40} />
    </div>
    <div
      style={{
        width: '330px',
        margin: '0 auto',
        display: 'flex',
        gap: '32px',
      }}
    >
      <div style={{ width: '140px' }}>
        <Shadow height={30} />
      </div>
      <div style={{ width: '140px' }}>
        <Shadow height={30} />
      </div>
    </div>
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        margin: '50px 20px',
      }}
    >
      <Card marginAuto />
      <Card marginAuto />
    </div>
  </div>
);

const SkeletonHubDigitalSolution = () => {
  const { isMobile, isTablet, isDesktop } = useDeviceType();

  return (
    <>
      {isMobile && <SkeletonMobile />}
      {isTablet && <SkeletonTablet />}
      {isDesktop && <SkeletonDesktop />}
    </>
  );
};

export default SkeletonHubDigitalSolution;

