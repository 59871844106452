import { TabsContext } from '@vfit/shared/data-access';
import { useContext } from 'react';
import { useQueryClient } from 'react-query';
import { NationContext, PopUpFilterContext } from '@vfit/business-data-access';
import {
  IConsumerOffer,
  IConsumerOfferElements,
  IOfferItem,
  IProductHub,
  IProductWrapper,
} from './ProductWrapper.model';

export const useProductWrapper = (widget: IProductWrapper) => {
  const { setIsTwoActive, setIsOneActive } = useContext(TabsContext);
  const { pageType, nation, appType } = useContext(NationContext);
  const { selectedOption } = useContext(PopUpFilterContext);
  const queryClient = useQueryClient();
  const offers = queryClient.getQueryData('getBusinessOffersFilter') as IOfferItem[];
  const filteredOffers = queryClient.getQueryData('getBusinessOffersFilterForPopUp') as IOfferItem[];
  const consumerOffers = queryClient.getQueryData('getConsumerOffersFilter') as IConsumerOffer[];

  const { tabId, elements } = widget.widget;
  let newElems: IProductHub[] = [];

  switch (appType) {
    case 'consumer':
      // eslint-disable-next-line no-case-declarations
      const filterElem = consumerOffers.find(
        (offer: IConsumerOffer) => offer?.slugCountry === nation?.slug
      );

      if (filterElem && pageType['isDetailCountry'] === 'true')
        elements.map((elem: IProductHub) =>
          filterElem?.elements.map((o: IConsumerOfferElements) => {
            if (o.offerId === elem.dxlId) {
              // eslint-disable-next-line no-param-reassign
              elem.overrideCostRenewalHtml = o.offerPriceHtml;
              // eslint-disable-next-line no-param-reassign
              elem.shortCharacteristics = o.shortCharacteristics;
              newElems.push(elem);
            }
          })
        );
      else newElems = elements;

      return {
        ...widget,
        widget: {
          // eslint-disable-next-line react/destructuring-assignment
          ...widget.widget,
          elements: newElems,
        },
      };

    default:
      if (pageType['isDetailCountry'] === 'true') {
        const offerslist =  selectedOption ? filteredOffers : offers
        newElems = elements.filter((elem: { dxlId: string }) =>
          offerslist.find((offer) => offer.dxlId === elem.dxlId && offer.country.includes(nation?.slug))
        );

        if (elements.length === 0 && tabId === '1') {
          setIsOneActive(false);
        } else if (elements.length === 0 && tabId === '2') {
          setIsTwoActive(false);
        } else {
          setIsOneActive(true);
          setIsTwoActive(true);
        }

        return {
          ...widget,
          widget: {
            // eslint-disable-next-line react/destructuring-assignment
            ...widget.widget,
            elements: newElems,
          },
        };
      }

      if (pageType['isTariffeEstero']==="true") {
        const offerslist =  selectedOption ? filteredOffers : offers
        newElems = elements.filter((elem: { dxlId: string }) =>
          offerslist.find((offer) => offer.dxlId === elem.dxlId)
        );
        return {
          ...widget,
          widget: {
            // eslint-disable-next-line react/destructuring-assignment
            ...widget.widget,
            elements: newElems,
          },
        };

      }

      return {
        ...widget,
        widget: {
          // eslint-disable-next-line react/destructuring-assignment
          ...widget.widget,
          elements,
        },
      };
      break;
  }
};

