/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import {
  CoverageErrorType,
  IProduct,
  IAddress,
  geographicAddressManagement,
  IgeographicAddressManagementResponse,
  formatDxlCosts,
  getEcommerceCatalogWithAddress,
} from '@vfit/business-data-access';
import { useQueryClient } from 'react-query';
import { useHandleQueryWithAuthSSO } from '@vfit/business-data-access';
import { UseQueryConfig } from '@vfit/shared/data-access';
import { useCoverageToolModalProvider } from './coverageToolModal.context';

type ICoverageToolFlow = {
  isSuccess: boolean;
  isLoading: boolean;
  errorType: number | undefined;
};

export interface IUseCoverageToolFlowPayload {
  product?: Array<IProduct>;
  address: IAddress;
  onConfirmAddress: boolean;
}
// geographicAddress
interface IUseGeographicAddress {
  address: IAddress;
  onConfirmAddress: boolean;
  setOnConfirmed: (v: boolean) => void;
}

type IUGeographicAddressFlow = {
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  data: IgeographicAddressManagementResponse | undefined;
  setIsError: (value: boolean) => void;
};

export const useCoverageToolFlow = ({
  onConfirmAddress,
  product,
  address,
}: IUseCoverageToolFlowPayload): ICoverageToolFlow => {
  const [errorType, setErrorType] = useState<number>();
  const queryClient = useQueryClient();
  const { setOfferType } = useCoverageToolModalProvider();

  const validAddress = queryClient.getQueryData(
    'geographicAddressManagement'
  ) as IgeographicAddressManagementResponse;

  const flowEnabler =
    !!validAddress?.validAddress?.city &&
    !!validAddress?.validAddress?.postcode &&
    !!validAddress?.validAddress?.stateOrProvince &&
    !!validAddress?.validAddress?.streetName &&
    !!validAddress?.validAddress?.streetNr &&
    onConfirmAddress;

  const ecommerceCatalogWithAddress = {
    queryKey: 'ecommerceCatalogWithAddress',
    queryFn: () => getEcommerceCatalogWithAddress({ product, address }),
    options: {
      onSuccess: (data) => {
        formatDxlCosts(data);
        setOfferType?.({
          isOfferAvailable: data?.isOfferAvailable,
          technology: data?.technology,
          isFwa: data?.isFwa,
        });
        localStorage.setItem('catalog_address', JSON.stringify(data));
        localStorage.setItem('valid_address', JSON.stringify(address));
      },
      onError: (error: Response) => {
        localStorage.removeItem('catalog_address');
        localStorage.removeItem('valid_address');
        const { status } = error;
        const statusNumber = Number(status);
        setErrorType(CoverageErrorType.KO_COVERAGE);
        if (statusNumber >= 400 && statusNumber <= 500) {
          setErrorType(CoverageErrorType.KO_COVERAGE);
        } else {
          setErrorType(CoverageErrorType.KO_TECHNICAL);
        }
      },
      enabled: flowEnabler,
    },
  } as UseQueryConfig;

  const { isSuccess, isLoading } = useHandleQueryWithAuthSSO(ecommerceCatalogWithAddress);

  return { isSuccess, isLoading, errorType };
};

export const useAddressAndVillage = ({
  address,
  onConfirmAddress,
  setOnConfirmed,
}: IUseGeographicAddress): IUGeographicAddressFlow => {
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [addressValidated, setAddressValidated] = useState<IgeographicAddressManagementResponse>();

  const geographicAddress = {
    queryKey: 'geographicAddressManagement',
    queryFn: () => {
      setIsLoading(true);
      return geographicAddressManagement(address);
    },
    options: {
      onSuccess: (data) => {
        setIsSuccess(true);
        setAddressValidated(data);
      },
      onError: () => {
        setIsError(true);
      },
      onSettled: () => {
        setOnConfirmed(false);
        setIsLoading(false);
      },
      enabled: onConfirmAddress,
    },
  } as UseQueryConfig;

  useHandleQueryWithAuthSSO(geographicAddress);

  return { isSuccess, isLoading, isError, data: addressValidated, setIsError };
};

