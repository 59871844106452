import { DXL, IDeviceInfo, getPriceDescriptionDeviceCard } from '@vfit/business-data-access';
import { IBusinessHubDevice } from './widgetHubDevice.model';

export const getValues = (characteristic: DXL.ICharacteristic[], name: string) => {
  const values = characteristic?.find((ch) => ch.name === name)
    ?.value as DXL.ICharacteristicValue[];

  return values?.[0]?.value || '';
};

export const formatData = (widget: IBusinessHubDevice, devices?: IDeviceInfo[]) => {
  const products = devices?.map((product) => ({
    dxlId: product?.deviceId,
    image: getValues(product?.characteristic || [], 'colors') || '',
    brand: product.deviceBrand || '',
    title: product.deviceName || '',
    offerLabel: widget.frontLabel || '',
    price: getPriceDescriptionDeviceCard(product.lowerCost || '0', widget.recurrence || ''),
    discount: getValues(product?.characteristic || [], 'deviceDetailList') || '',
    description: getValues(product?.characteristic || [], 'deviceShortDescription') || '',
    action: {
      ...widget.customAction,
      url: getValues(product?.characteristic || [], 'deviceUrl') || '',
    },
    haveMarketingLabel: !!getValues(product?.characteristic || [], 'deviceTag'),
    tagLine: getValues(product?.characteristic || [], 'deviceTag') || '',
    isOutOfStock:product?.availableInStock==false ? true : false
  }));

  return {
    title: widget.title || '',
    labelFilter: widget.labelFilter || '',
    filter: widget.filter,
    filterSetLabel: widget.labelSelectedFilter || '',
    products,
  };
};

