import React from 'react';
import { CustomText, ImageDivider } from '@vfit/shared/atoms';
import { Fade } from 'react-awesome-reveal';
import { SliderCarousel } from '@vfit/shared/components';
import { useWidgetLargeSmallSlider } from './widgetLargeSmallSlider.hook';
import { DisclaimerContainer, WidgetContainer, WidgetDivider } from '../Widgets/widgets.style';
import SliderWithAction from './components/SliderWithAction/sliderWithAction';
import { IBusinessWidgetLSSlider } from './widgetLargeSmallSlider.models';

const WidgetLargeSmallSlider = ({ widget, isLastWidget, indexWidget }: IBusinessWidgetLSSlider) => {
  const {
    sliderCarousel,
    divider,
    disclaimer,
    otherProps,
    forceTextTop,
    containerStyle,
    isMobile,
  } = useWidgetLargeSmallSlider(widget);

  const sliderCarouselComponent = () => (
    <Fade triggerOnce>
      <SliderCarousel
        {...sliderCarousel}
        showCase={indexWidget + 1}
        containerStyle={containerStyle}
        anchorScroll={indexWidget === 0}
        {...otherProps}
        forceTextTop={forceTextTop}
        SlideCarouselComponent={SliderWithAction}
      />
      {disclaimer && (
        <DisclaimerContainer>
          <CustomText text={disclaimer} lineHeight={22} size={16} textAlign="left" />
        </DisclaimerContainer>
      )}
    </Fade>
  );

  return (
    <WidgetContainer isLastWidget={isLastWidget}>
      {sliderCarouselComponent()}
      {divider?.image && (
        <WidgetDivider>
          <ImageDivider
            image={divider.image}
            imageMobile={divider.imageMobile}
            isMobile={isMobile}
            alt={divider.text || ''}
          />
        </WidgetDivider>
      )}
    </WidgetContainer>
  );
};

export default WidgetLargeSmallSlider;
