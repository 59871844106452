import { useContext, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import {
  CMS_CONFIG,
  useCmsConfig,
  useValidateAddressMutation,
  ICoverageToolCongifg,
  IgeographicAddressManagementRequest,
} from '@vfit/business-data-access';
import { API, PAGES } from '@vfit/shared/data-access';
import { getPlaceId, useDeviceType } from '@vfit/shared/hooks';
import { Divider, CustomText, ButtonSlideCustom } from '@vfit/shared/atoms';
import { LoggerInstance, resetData } from '@vfit/shared/data-access';
import { fonts } from '@vfit/shared/themes';
import { Form } from '@vfit/shared/components';
import { useQueryClient } from 'react-query';
import { TrackingPageContext } from '@vfit/business-data-access';
import { Title } from './coverageTool.style';
import {
  CoverageToolContainer,
  CoverageToolContent,
  CoverageToolContentButtonAuto,
} from './coverageToolContainers.style';
import {
  ICoverageToolProps,
  ICoverageToolType,
  IGeoAddressElab,
  IInputs,
} from './coverageTool.models';
import {
  checkAddressFromValue,
  checkCoverageToolType,
  composeAddress,
  getAddressFromLatLng,
  getCoverageInputValidation,
  retrieveAddress,
} from './coverageTool.utils';
import { useAddressAndVillage } from '../coverageTool.hooks';
import CoverageToolError from '../CoverageToolError/coverageToolError';

const CoverageToolAuto = (props: ICoverageToolProps) => {
  const { state, offerId, onSubmit } = props;
  const { linkError, view, currentDataLayer, setCurrentDataLayer } =
    useContext(TrackingPageContext);

  const { isDesktop } = useDeviceType();
  const mutation = useValidateAddressMutation();
  const [latitude, setLatitude] = useState<string>('');
  const [longitude, setLongitude] = useState<string>('');
  const [isFormDisabled, setIsFormDisabled] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [isLoadingGeolocation, setIsLoadingGeolocation] = useState<boolean>(false);
  const [onConfirmed, setOnConfirmed] = useState(false);
  const queryClient = useQueryClient();

  const coverageToolConfig = useCmsConfig(
    CMS_CONFIG[PAGES.BUSINESS_FIXED_LINE],
    API.CMS_BFL_GET_COVERAGE_TOOLS
  ) as ICoverageToolCongifg;

  const schema = getCoverageInputValidation(coverageToolConfig);

  const errorMessageGeolocalizzation =
    coverageToolConfig?.coveragetool?.geolocalization?.alertError || '';

  const defaultValues = {
    ...state,
  };
  let initialState = {
    placeId: false,
    streetNumber: true,
  };
  if (state?.street !== '' && state?.streetNumber !== '') {
    initialState = {
      placeId: false,
      streetNumber: false,
    };
  }

  const [hiddenFields, setHiddenFields] = useState(initialState);
  const {
    handleSubmit,
    control,
    register,
    watch,
    setValue,
    getValues,
    reset,
    trigger,
    formState: { errors },
  } = useForm<IInputs>({
    resolver: yupResolver(schema),
    defaultValues,
    mode: 'all',
  });

  const getAddress = () => {
    const inputs = getValues(); // metere in una funzione
    return {
      city: inputs.city,
      postcode: inputs.postalCode,
      streetName: inputs.street,
      streetNr: inputs.streetNumber,
      stateOrProvince: inputs.stateOrProvince,
      provideAlternatives: false,
    } as IgeographicAddressManagementRequest;
  };

  const {
    isSuccess,
    isLoading,
    isError,
    data: addressValidated,
    setIsError,
  } = useAddressAndVillage({
    address: getAddress(),
    onConfirmAddress: onConfirmed,
    setOnConfirmed,
  });

  useEffect(() => {
    if (!isSuccess) return;
    if (!addressValidated?.validAddress?.candidates) {
      confirmMutation(ICoverageToolType.MAPS);
    } else {
      confirmMutation(ICoverageToolType.LIST_ADDRESS);
    }
  }, [isSuccess]);

  useEffect(() => {
    mutation.reset();
    setIsFormDisabled(false);
    resetData(queryClient, ['geographicAddressManagement']);

    // TRACKING
    const datalayer = view(
      {
        event_name: [coverageToolConfig?.tagging?.event || ''],
        page_section: coverageToolConfig?.tagging?.pageSection,
        page_subsection: coverageToolConfig?.tagging?.pageSubsection,
        page_type: coverageToolConfig?.tagging?.pageType,
        journey_name: 'coverage tool',
        journey_type: 'journey',
        event_category: [coverageToolConfig?.tagging?.eventCategory || ''],
      },
      offerId
    );
    setCurrentDataLayer({ ...currentDataLayer, coverageToolDataLayer: datalayer });
  }, []);

  // eslint-disable-next-line arrow-body-style
  const GetAddressObjectFromPlaceId = async (placeId: string) => {
    // eslint-disable-next-line @typescript-eslint/return-await
    return await getPlaceId(placeId);
  };

  const setAddressObject = async (placeId: string | null, text?: string) => {
    //  Only if there is placeId
    if (placeId) {
      const addressObj = await GetAddressObjectFromPlaceId(placeId as string);
      //  Only if getAddressObject retireve an address object
      if (addressObj) {
        //  Shows street number text input at the first step if no retrievable from Google API placeId
        if (!addressObj?.streetNumber)
          setHiddenFields((prevState) => ({
            ...prevState,
            streetNumber: false,
          }));
        else setValue('streetNumber', addressObj.streetNumber);
        //  sets all the other fields
        setValue('placeId', placeId);
        if (addressObj.city) setValue('city', addressObj.city);
        if (addressObj.postalCode) setValue('postalCode', addressObj.postalCode);
        if (addressObj.street) setValue('street', addressObj.street);
        if (addressObj.stateOrProvince) setValue('stateOrProvince', addressObj.stateOrProvince);
        setLatitude(addressObj.latitude);
        setLongitude(addressObj.longitude);
        await trigger(['city', 'postalCode', 'street', 'stateOrProvince']);
      }
      const inputs = getValues();

      const mutateLocation = {
        ...inputs,
        latitude: parseFloat(addressObj?.latitude || latitude),
        longitude: parseFloat(addressObj?.longitude || longitude),
      };

      mutation.mutate(mutateLocation);
    } else if (!placeId && !!text) {
      //  If placeId === null, it uses the input string 'text' to get the address object
      //  instead of the placeId. It uses useAddressGeocoder()
      const addressGeocoder = await checkAddressFromValue(text);

      if (addressGeocoder) {
        //  Check if with address from addrString + streetNumber can get all the fields.
        //  if not, it sets isManual to true and show all the form fields
        setLatitude(addressGeocoder.latitude);
        setLongitude(addressGeocoder.longitude);

        //  if all fields retrieved, it sets all of them
        if (addressGeocoder.placeId) setValue('placeId', addressGeocoder.placeId);
        if (addressGeocoder.streetNumber) setValue('streetNumber', addressGeocoder.streetNumber);
        if (addressGeocoder.city) setValue('city', addressGeocoder.city);
        if (addressGeocoder.postalCode) setValue('postalCode', addressGeocoder.postalCode);
        if (addressGeocoder.street) setValue('street', addressGeocoder.street);
        if (addressGeocoder.stateOrProvince)
          setValue('stateOrProvince', addressGeocoder.stateOrProvince);

        await trigger();

        const inputs = getValues();

        const mutateLocation = {
          ...inputs,
          latitude: addressGeocoder.latitude ? parseFloat(addressGeocoder.latitude) : 0,
          longitude: addressGeocoder.longitude ? parseFloat(addressGeocoder.longitude) : 0,
        };
        mutation.mutate(mutateLocation);
      }
    }
  };
  const placeId = watch('placeId');
  const streetNumber = watch('streetNumber');

  useEffect(() => {
    //  first call of setAddressObject using placeId if no street number setted
    if (placeId && !streetNumber && hiddenFields.streetNumber === true) {
      // @ts-ignore
      setAddressObject(placeId).catch((e) => {
        LoggerInstance.debug(e);
      });
    }
    //  to handle abling/disabling button
    if (streetNumber && !errors.streetNumber && placeId && !isLoading) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [placeId, streetNumber, hiddenFields.streetNumber, setAddressObject, setValue]);

  useEffect(() => {
    if (!placeId) {
      setValue('streetNumber', '');
    }
  }, [placeId]);

  const onIconClick = () => {
    reset();
    setHiddenFields({
      placeId: false,
      streetNumber: true,
    });
  };

  const onConfirm = async (input: IInputs, e: any) => {
    e.preventDefault();
    const mutateLocation = {
      ...input,
      latitude: parseFloat(latitude),
      longitude: parseFloat(longitude),
    };

    mutation.mutate(mutateLocation);
  };

  const confirmMutation = async (coverageType: ICoverageToolType) => {
    if (placeId && streetNumber && hiddenFields.streetNumber === false) {
      const inputs = getValues();
      const formattedAddress = retrieveAddress({
        city: inputs.city,
        postalCode: inputs.postalCode,
        stateOrProvince: inputs.stateOrProvince,
        street: inputs.street,
        streetNumber,
      });
      await setAddressObject(null, formattedAddress).catch((e) => {
        LoggerInstance.debug(e);
      });
    }
    const updatedInputs = getValues();

    const mutateLocation = {
      ...updatedInputs,
      latitude: latitude ? parseFloat(latitude) : 0,
      longitude: longitude ? parseFloat(longitude) : 0,
    };
    const validAddress: IInputs = {
      city: addressValidated?.validAddress?.city || '',
      postalCode: addressValidated?.validAddress?.postcode || '',
      stateOrProvince: addressValidated?.validAddress?.stateOrProvince || '',
      street: addressValidated?.validAddress?.streetName || '',
      streetNumber: addressValidated?.validAddress?.streetNr || '',
      placeId: addressValidated?.validAddress?.addressCode || '',
      latitude: latitude ? parseFloat(latitude) : 0,
      longitude: longitude ? parseFloat(longitude) : 0,
    };

    if (onSubmit && coverageType === ICoverageToolType.MAPS) {
      return onSubmit(validAddress, coverageType);
    }
    if (onSubmit && coverageType === ICoverageToolType.MANUAL) {
      return onSubmit(mutateLocation, checkCoverageToolType(mutateLocation));
    }
    if (onSubmit) {
      onSubmit(validAddress, coverageType);
    }
    return null;
  };

  const handleFirstClick = async () => {
    if (placeId && streetNumber && hiddenFields.streetNumber === false) {
      const inputs = getValues();
      const formattedAddress = retrieveAddress({
        city: inputs.city,
        postalCode: inputs.postalCode,
        stateOrProvince: inputs.stateOrProvince,
        street: inputs.street,
        streetNumber,
      });
      await setAddressObject(null, formattedAddress).catch((e) => {
        LoggerInstance.debug(e);
      });
    }
    const updatedInputs = getValues();

    const mutateLocation = {
      ...updatedInputs,
      latitude: latitude ? parseFloat(latitude) : 0,
      longitude: longitude ? parseFloat(longitude) : 0,
    };

    if (checkCoverageToolType(mutateLocation) !== ICoverageToolType.AUTO && onSubmit) {
      onSubmit(mutateLocation, ICoverageToolType.MANUAL);
    } else {
      setOnConfirmed(true);
    }
  };

  const findPosition = async (p: GeolocationPosition) =>
    new Promise((resolve) => {
      getAddressFromLatLng(p?.coords?.latitude, p?.coords?.longitude)
        .then((value: any) => {
          let address: IGeoAddressElab = {
            street: '',
            city: '',
            province: '',
            postalCode: '',
          };
          if (value !== undefined) {
            setHiddenFields((prevState: any) => ({
              ...prevState,
              streetNumber: false,
            }));
            address = composeAddress(value);
          }
          resolve(address);
          if (address.latitude) setLatitude(address.latitude);
          if (address.longitude) setLongitude(address.longitude);
          if (address.streetNumber) setValue('streetNumber', address.streetNumber);
          if (address.postalCode) setValue('postalCode', address.postalCode);
          if (address.province) setValue('stateOrProvince', address.province.toUpperCase());
          if (address.city) setValue('city', address.city.toUpperCase());
          if (address.street) setValue('street', address.street);
          if (address.placeId) setValue('placeId', address.placeId);
        })
        .catch((err) => {
          LoggerInstance.debug(err);
          // TODO: Error geolocation
        });
    });

  const handleLoadingGeolocation = (isLoadingGeo: boolean) => {
    setIsLoadingGeolocation(isLoadingGeo);
  };

  const handleTrackError = (field: string) => {
    linkError({
      ...currentDataLayer?.coverageToolDataLayer,
      form_error: `please complete ${field}`,
      link_name: `${currentDataLayer?.coverageToolDataLayer?.page_name}:input error`,
    });
  };

  const handleRetry = () => {
    queryClient.invalidateQueries('geographicAddressManagement');
    setIsError(false);
  };

  if (isError) {
    return <CoverageToolError onRetry={handleRetry} offerId={offerId} />;
  }

  return (
    <CoverageToolContainer>
      <CoverageToolContent>
        <Title>{coverageToolConfig?.coveragetool?.addressInput?.title || ' '}</Title>
        <CustomText
          text={coverageToolConfig?.coveragetool?.addressInput?.description || ' '}
          textAlign="center"
          size={20}
          lineHeight={26}
          fontFamily={fonts.regular}
          modal
        />
        <Form
          onSubmit={handleSubmit(onConfirm)}
          autoComplete="off"
          onKeyPress={(e) => {
            if (e?.key === 'Enter') e?.preventDefault();
          }}
        >
          <Controller
            control={control}
            name="placeId"
            render={({ field: { onChange, onBlur, ref } }) => (
              <Form.AddressInput
                label={coverageToolConfig?.coveragetool?.selectInput?.address || ''}
                onChange={(event) => {
                  setHiddenFields((prevState) => ({
                    ...prevState,
                    streetNumber: true,
                  }));
                  onChange(event);
                }}
                hidden={hiddenFields.placeId}
                autoFocus={isDesktop}
                autoComplete="off"
                onIconClick={onIconClick}
                onBlur={onBlur}
                ref={ref}
                disabled={isFormDisabled || isLoadingGeolocation}
                findPosition={findPosition}
                onChangeGeolocation={handleLoadingGeolocation}
                errorMessage={errorMessageGeolocalizzation}
                placeholderAutocomplete={{
                  enabled: true,
                  label: 'Ricerca manuale',
                  action: () => confirmMutation(ICoverageToolType.MANUAL),
                }}
              />
            )}
          />
          <Divider marginBottom={1} hidden={hiddenFields.streetNumber} />
          <Form.TextInput
            placeholder=" "
            label={coverageToolConfig?.coveragetool?.selectInput?.civicNumber || ''}
            hidden={hiddenFields.streetNumber}
            error={!hiddenFields.streetNumber ? errors.streetNumber?.message : ' '}
            autoFocus
            autoComplete="off"
            disabled={isFormDisabled}
            onErrorInput={() => handleTrackError('streetNumber')}
            {...register('streetNumber')}
          />
        </Form>
      </CoverageToolContent>
      <CoverageToolContentButtonAuto>
        <ButtonSlideCustom
          label={coverageToolConfig?.coveragetool?.addressInput?.button || ''}
          disabled={isDisabled}
          onClick={handleFirstClick}
          style={coverageToolConfig?.coveragetool?.addressInput?.buttonStyle}
        />
      </CoverageToolContentButtonAuto>
    </CoverageToolContainer>
  );
};

export default CoverageToolAuto;

