import { IBusinessFaq } from './widgetFaq.models';

/**
 * Method to return the faq widget
 * @param widget
 */
const formatFaqProps = (widget: IBusinessFaq) => ({
  topText: widget?.title || '',
  faq: {
    divider: widget?.divider,
    elements: widget?.elements || [],
    showMore: {
      ...widget.customAction,
      style: Number(widget.customAction?.style),
    },
  },
});

export { formatFaqProps };

