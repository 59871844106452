import { Children, useContext } from 'react';
import { Accordion, CustomText } from '@vfit/shared/atoms';
import { ProductType, getOfferDetailsConfig, useCmsConfig } from '@vfit/business-data-access';
import { IActionCustom } from '@vfit/shared/models';
import { TrackingPageContext } from '@vfit/business-data-access';
import Tabs from '../../../Tabs/tabs';
import { OffersList, Paragraph, ParagraphBold, Spacer } from '../../productDetail.style';
import { ContentWrapper } from './content.style';
import ModalInfo from '../ModalInfo/modalInfo';
import { IOfferDetail, detailsPopup } from '../../productDetail.model';

const Content = ({
  handleClick,
  tabs,
}: {
  handleClick: (action: IActionCustom) => void;
  tabs: { productType: ProductType; dxlId: string }[];
}) => {
  const { link, currentDataLayer } = useContext(TrackingPageContext);
  const offerDetailsData = tabs
    .map((t) => {
      const { cmsConfig, api } = getOfferDetailsConfig(t?.productType);
      const offerDetails = useCmsConfig(cmsConfig, api) as IOfferDetail;
      return Object.values(offerDetails).find((el) => el?.detailspopup?.dxlId === t?.dxlId)!;
    })
    .filter(Boolean);

  const showHelpButtons = (data: detailsPopup) =>
    data?.detailspopup?.needHelp && (
      <ModalInfo
        content={{
          title: data?.detailspopup?.needHelp?.title,
          ctaName: data?.detailspopup?.needHelp?.customAction.title || '',
        }}
        customAction={() =>
          handleClick(data?.detailspopup?.needHelp?.customAction as IActionCustom)
        }
      />
    );

  const getElement = (data: detailsPopup) => (
    <>
      <OffersList>
        {Children.toArray(
          data?.detailspopup?.offerDetails?.map((offer) => (
            <li>
              {offer.title && <ParagraphBold>{offer.title}</ParagraphBold>}
              {offer.description && (
                <Paragraph>
                  <CustomText textAlign="left" text={offer.description} />
                </Paragraph>
              )}
            </li>
          ))
        )}
      </OffersList>
      {Children.toArray(
        data?.detailspopup?.conditions?.map((f) => (
          <Accordion
            title={f.title}
            content={[
              <CustomText
                modal
                sizeMobile={16}
                lineHeightMobile={22}
                size={16}
                lineHeight={22}
                textAlign="start"
                text={f.text || ''}
              />,
            ]}
            iconColor="#e60000"
          />
        ))
      )}
      <Spacer />
      {data?.detailspopup?.needHelp && showHelpButtons(data)}
    </>
  );

  const handleClickTab = (id: number) => {
    link({
      ...currentDataLayer?.pdpDataLayer,
      link_name: `${currentDataLayer?.pdpDataLayer?.page_name || ''}:${
        offerDetailsData[id]?.detailspopup?.tabName || 'Piano Telefonico'
      }`,
    });
  };

  return (
    <ContentWrapper>
      <Tabs
        tabs={offerDetailsData?.map((detail) => ({
          content: getElement(detail),
          label: detail?.detailspopup?.tabName || 'Piano Telefonico',
        }))}
        handleClickTab={handleClickTab}
      />
    </ContentWrapper>
  );
};

export default Content;

