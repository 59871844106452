import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont } from '@vfit/shared/themes';

export const ModalWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .button-slide-container {
    margin-bottom: 16px;
  }

  div :nth-child(2) {
    margin: 0;
  }
`;

export const Paragraph = styled.p`
  margin: 0;
  font-weight: 400;
  color: ${colors.$262626};
`;

export const Title = styled(Paragraph)`
  font-family: ${fonts.exbold};
  ${pxToCssFont(30, 38)}
  text-align: center;

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(42, 52)}
  }

  @media (min-width: ${breakpoints.desktop}) {
    ${pxToCssFont(36, 45)}
  }
`;

export const ModalTitle = styled(Title)`
  text-align: left;

  @media (min-width: ${breakpoints.tablet}) {
    padding-top: 8px;
    ${pxToCssFont(30, 38)}
  }

  @media (min-width: ${breakpoints.desktop}) {
    padding-top: 8px;
    ${pxToCssFont(36, 45)}
  }
`;

export const ModalDescription = styled(Paragraph)`
  font-family: ${fonts.regular};
  ${pxToCssFont(20, 26)}
  margin-top: 32px;
  text-align: left;

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(18, 24)}
  }
`;

export const ButtonDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 32px;

  .button-slide-container {
    width: 100%;

    @media (min-width: ${breakpoints.tablet}) {
      width: 258px;
    }

    @media (min-width: ${breakpoints.desktop}) {
      width: 296px;
    }
  }
`;
