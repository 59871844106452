import GoogleMapReact from 'google-map-react';
import { StyledMap, MapContainer } from './map.style';

const Map = ({ latitude, longitude }: {latitude: string, longitude: string}) => {
  const urlKeys = {
    version: 'weekly',
    key: process.env?.['NX_GOOGLE_MAPS_API_KEY'] || '',
    libraries: ['places'],
    language: 'it',
    region: 'it',
  };

  const handleShowZoom = () => {
    const printEl = document.getElementsByClassName('gm-bundled-control');
    if (printEl[0]) {
      (printEl[0] as HTMLElement).style.display = 'block';
    }
  };

  const handleHideZoom = () => {
    const printEl = document.getElementsByClassName('gm-bundled-control');
    if (printEl[0]) {
      (printEl[0] as HTMLElement).style.display = 'none';
    }
  };

  const animateMapZoomTo = (map: any, targetZoom: number, commandedZoom: number) => {
    const currentZoom = commandedZoom || map.getZoom();
    if (currentZoom !== targetZoom) {
      google.maps.event.addListenerOnce(map, 'zoom_changed', () => {
        animateMapZoomTo(map, targetZoom, currentZoom + (targetZoom > currentZoom ? 1 : -1));
      });
      setTimeout(() => {
        map.setZoom(currentZoom + (targetZoom > currentZoom ? 1 : -1));
      }, 240);
    }
  };

  const renderMarkers = (map: any, maps: any, lat: number, lng: number) => {
    const marker = new maps.Marker({
      position: { lat, lng },
      map,
    });

    setTimeout(() => {
      animateMapZoomTo(map, 15, 8);
    }, 400);

    return marker;
  };

  const mapOptions = {
    fullscreenControl: false,
    keyboardShortcuts: false,
    mapTypeControl: false,
  };

  return (
    <MapContainer>
      <StyledMap onMouseOver={handleShowZoom} onMouseLeave={handleHideZoom}>
        <GoogleMapReact
          bootstrapURLKeys={urlKeys}
          defaultCenter={{ lat: parseFloat(latitude), lng: parseFloat(longitude) }}
          defaultZoom={7}
          options={mapOptions}
          draggable={false}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => {
            renderMarkers(map, maps, parseFloat(latitude), parseFloat(longitude));
          }}
        />
      </StyledMap>
    </MapContainer>
  );
};

export default Map;
