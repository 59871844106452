import { Shadow } from '@vfit/shared/atoms'
import { useDeviceType } from '@vfit/shared/hooks';

const SkeletonHubCard = ({noMargin = false}) => {
  const { isDesktop } = useDeviceType();

  return (
    <div style={{height: isDesktop ? '792px' : '680px', width: isDesktop ? '405px' : '329px', border: '1px solid #BEBEBE', borderRadius: '20px', overflow: 'hidden', marginRight: noMargin ? '0': '32px'}}>
      <Shadow height={240}/>
      <div style={{marginLeft: isDesktop ? '36px' : '24px'}}>
        <div style={{width: isDesktop ? '226px' : '170px', marginTop: '32px'}}>
          <Shadow height={38}/>
          <div style={{marginTop: '6px'}}><Shadow height={18}/></div>
        </div>
        <div style={{width: isDesktop ? '333px' : '283px', marginTop: '32px'}}>
          <Shadow height={30}/>
          <div style={{marginTop: '8px'}}><Shadow height={30}/></div>
          <div style={{marginTop: '8px'}}><Shadow height={30}/></div>
          <div style={{marginTop: '8px'}}><Shadow height={30}/></div>
          {isDesktop && (
            <div style={{width: '303px', marginLeft: '30px', marginTop: '8px'}}>
              <Shadow height={22}/>
              <div style={{marginTop: '8px'}}><Shadow height={22}/></div>
            </div>
          )}
          <div style={{marginTop: '8px'}}><Shadow height={30}/></div>
        </div>
        <div style={{width: isDesktop ? '333px' : '283px', marginTop: '32px'}}>
          {isDesktop && <Shadow height={48}/>}
          <div style={{marginTop: '14px'}}><Shadow height={48}/></div>
        </div>
      </div>
    </div>
  )
};

export default SkeletonHubCard