import { useContext, useEffect, useState } from 'react';
import { PageLoadingContext, TrackingPageContext } from '@vfit/business-data-access';
import {
  IConfigCMSParams,
  IDeviceInfo,
  cmsDataID,
  useCmsConfig,
  useSwitchCmsData,
} from '@vfit/business-data-access';
import TopDevice from '../TopDevice/topDevice';
import { WidgetContainer } from '../Widgets/widgets.style';
import {
  IBusinessWidgetDeviceHeroBanner,
  ICapacityandColor,
  IFilter,
  IFilterType,
} from './widgetDeviceHeroBanner.models';
import { useWidgetDeviceHeroBanner } from './widgetDeviceHeroBanner.hook';
import {
  findFirstResource,
  findNewResource,
  findResourceByOfferId,
  getFormatData,
  getFormatOffers,
  getResourceData,
} from './widgetDeviceHeroBanner.utils';
import SkeletonPdpDevice from '../SkeletonPdpDevice';

const WidgetDeviceHeroBanner = ({ widget, isLastWidget }: IBusinessWidgetDeviceHeroBanner) => {
  const { isLoading } = useContext(PageLoadingContext);
  const { selectedResource, setSelectedResource } = useContext(TrackingPageContext);

  const { cmsConfig, api } = useSwitchCmsData(cmsDataID.CAPACITY_AND_COLOR) as IConfigCMSParams;
  const capacityAndColor = useCmsConfig(cmsConfig, api) as ICapacityandColor;

  const { device, offerCombination } = useWidgetDeviceHeroBanner(
    widget,
    widget.product.productIdList
  );

  const [filter, setFilter] = useState<IFilter>({
    color: '',
    capacity: '',
    offerId: '',
  });

  useEffect(() => {
    if (!isLoading) {
      const { resource, offerId } = findFirstResource(offerCombination, capacityAndColor);
      setSelectedResource(resource);
      setFilter({
        color: resource?.resourceColour?.id || '',
        capacity: resource?.resourceCapacity?.id || '',
        offerId,
      });
    }
  }, [isLoading]);

  const handleFilter = (filterItem: IFilterType, item: string) => {
    const combo = { ...filter, [filterItem]: item };

    const offerResource = offerCombination[combo?.offerId];
    const comboResource = offerResource?.find(
      (r) => r?.resourceColour?.id === combo?.color && r?.resourceCapacity?.id === combo?.capacity
    );
    const resourceById =
      filterItem !== 'offerId' && findResourceByOfferId(offerResource, combo, filterItem);

    if (comboResource) {
      setSelectedResource(comboResource);
      setFilter(combo);
    } else if (resourceById) {
      setSelectedResource(resourceById);
      setFilter({
        color: resourceById?.resourceColour?.id || '',
        capacity: resourceById?.resourceCapacity?.id || '',
        offerId: combo?.offerId,
      });
    } else {
      const { resource, offerId } = findNewResource(offerCombination, combo, filterItem);
      if (resource) {
        setSelectedResource(resource);
        setFilter({
          color: resource?.resourceColour?.id || '',
          capacity: resource?.resourceCapacity?.id || '',
          offerId,
        });
      }
    }
  };

  const deviceData = getFormatData(
    device?.deviceInfo?.[0] as IDeviceInfo,
    capacityAndColor,
    widget,
    selectedResource
  );
  const dataOffers = getFormatOffers(offerCombination, selectedResource);
  const resourceData = getResourceData(
    device?.deviceInfo?.[0] as IDeviceInfo,
    capacityAndColor,
    widget,
    selectedResource
  );

  if (isLoading) return <SkeletonPdpDevice />;
  return (
    selectedResource && (
      <WidgetContainer isLastWidget={isLastWidget}>
        <TopDevice
          deviceData={deviceData}
          handleFilter={handleFilter}
          offerResources={offerCombination[filter?.offerId]}
          offerData={dataOffers}
          resourceData={resourceData}
        />
      </WidgetContainer>
    )
  );
};

export default WidgetDeviceHeroBanner;

