import { IActionSlide, IActionType, IErrorFormApi, ISubmitOutput } from '@vfit/shared/models';
import { useRouter } from 'next/router';
import { useContext, useState } from 'react';
import { useQueryClient } from 'react-query';
import { IGetAllCmsForm, manageErrorLeadPlatform, manageSuccessLeadPlatform, openLeadModal } from '@vfit/shared/data-access';
import { AuthenticationContext } from '@vfit/business-data-access';
import { getFormatprops } from './widgetCardInfoProduct.utils';
import { IBusinessCardInfoProduct, ICardInfoProductProps } from './widgetCardsInfoProduct.models';

export const useWidgetCardInfo = (widget: IBusinessCardInfoProduct): ICardInfoProductProps => {
  const formatPropscards = widget.elements ? getFormatprops(widget.elements) : [];
  const queryClient = useQueryClient();
  const { authType } = useContext(AuthenticationContext);

  const { push } = useRouter();
  const [modal, setModal] = useState({
    show: false,
    text: '',
    title: '',
  });

  const onActionClick = (action?: IActionSlide, key?: string) => {
    const type = action?.type ? +action.type : 0;
    const card = widget.elements?.find((el, index) => index === Number(key));
    switch (type) {
      case IActionType.CUSTOM_URL:
        if (action?.url) window.open(action.url, action?.isBlank === 'true' ? '_blank' : '_self');
        break;
      case IActionType.LOCAL_URL:
        if (action?.url) push(action?.url);
        break;
      case IActionType.POPUP_DETAIL:
        if (card?.popupDetail) {
          setModal({
            show: true,
            text: card?.popupDetail.descriptionHtml,
            title: card?.popupDetail.title,
          });
        }
        break;
        case IActionType.LEAD_MODAL:
        if (queryClient) {
          const allLeads = queryClient?.getQueryData('getAllLeads') as IGetAllCmsForm;
          // form name
          const ref = action?.url || '';
          const forms = allLeads?.[ref].forms;
          if (forms) {
            openLeadModal({
              form: forms,
              onSuccess: (submitOutput: ISubmitOutput) =>
                manageSuccessLeadPlatform(submitOutput, push, queryClient),
              onError: (submitOutput: ISubmitOutput[], errorCmsApi?: IErrorFormApi) =>
                manageErrorLeadPlatform(submitOutput, errorCmsApi, push, queryClient),
              trackingOption: {
                visitor_type: authType
              },
            });
          }
        }
        break;
      default:
        break;
    }
  };
  return {
    topText: widget.titleHtml,
    description: widget.descriptionHtml,
    divider: widget.divider,
    disclaimer: widget.disclaimerHtml,
    newCards: formatPropscards,
    onClickCardAction: onActionClick,
    modal,
    setModal,
  };
};

