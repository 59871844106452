import { ISplittedTableTextElement } from './widgetSplittedTableText.models';

export const getTableRowsList = (elements: ISplittedTableTextElement[]) =>
  elements.map((item, index) => {
    const isFirstElement = index === 0;
    const isLastElement = index === elements.length - 1;

    return {
      label: item?.label || '',
      value: item?.value || '',
      isFirstRow: isFirstElement,
      isLastRow: isLastElement,
    };
  });

