import { Shadow } from '@vfit/shared/atoms';
import { useDeviceType } from '@vfit/shared/hooks';
import { useEffect } from 'react';
import SkeletonHubCard from './SkeletonHubCard';

const SkeletonDesktop = () => (
  <div style={{ height: '100vh' }}>
    <div style={{ width: '1000px', margin: '80px auto 32px auto' }}>
      <Shadow height={45} />
    </div>
    <div style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: '80px' }}>
      <SkeletonHubCard />
      <SkeletonHubCard />
      <SkeletonHubCard noMargin />
    </div>
  </div>
);

const SkeletonTablet = () => (
  <div style={{ height: '100vh' }}>
    <div style={{ width: '1000px', margin: '80px 23px 32px' }}>
      <Shadow height={45} />
    </div>
    <div style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: '80px' }}>
      <SkeletonHubCard />
      <SkeletonHubCard noMargin />
    </div>
  </div>
);

const SkeletonMobile = () => (
  <div style={{ height: '100vh' }}>
    <div style={{ margin: '56px 23px 32px' }}>
      <Shadow height={45} />
    </div>
    <div style={{ display: 'flex', justifyContent: 'center', marginLeft: '32px' }}>
      <SkeletonHubCard />
    </div>
  </div>
);

const SkeletonHub = () => {
  const { isMobile, isTablet, isDesktop } = useDeviceType();

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  if(isMobile) return <SkeletonMobile />;
  if(isTablet) return <SkeletonTablet />;
  if(isDesktop) return <SkeletonDesktop />;
  return <SkeletonMobile />;
};

export default SkeletonHub;
