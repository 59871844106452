import { ICMSWidgetType } from '@vfit/business-data-access';
import React, { Children } from 'react';
import { WidgetComponents } from './widgets.config';
import { IWidgets } from './widgets.models';

const Widgets = ({aemElements}: IWidgets) => {
  // check if component is one to show in page or not as "seo, tagging, etc..."
  const widgets = Object.values(aemElements).filter(e => ICMSWidgetType[e?.type] !== undefined)

  return (
    <>
      {Children.toArray(
        widgets.map((widget, indexWidget) => {
          const isLastWidget = indexWidget === widgets.length - 1;
          // check if component exist
          if(typeof WidgetComponents[widget.type] !== "undefined") {
            const Component = WidgetComponents[widget.type];
            return (
              <div data-widget-id={indexWidget} className='noPadding'>
                <Component widget={widget} isLastWidget={isLastWidget} indexWidget={indexWidget} />
              </div>
            );
          }

          // fallback if the component doesn't exist
          return (
            <p>
              The component <strong>{widget.type}</strong> has not been created yet.
            </p>
          )
        })
      )}
    </>
  );
};

export default Widgets;
