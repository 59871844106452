import * as yup from 'yup';

export const getCodeValidation = (error: string) =>
// do a regex to matches only letters, numbers


  yup
    .object({
      code: yup
        .string()
        .matches(
          /^[a-zA-Z0-9!#$@%&'*+/=?^_.`{|}~-]+$/g,
          { message: error}
        )
    }).required();