import { useContext, useEffect, useState } from 'react';
import { Alert, ButtonSlideCustom, CustomText, Checkbox } from '@vfit/shared/atoms';
import { useDeviceType } from '@vfit/shared/hooks';
import { fonts } from '@vfit/shared/themes';
import { AlertWarning } from '@vfit/shared-icons';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useGetListLandingPage } from '@vfit/business-data-access';
import { TrackingPageContext } from '@vfit/business-data-access';
import { Form } from '@vfit/shared/components';
import {
  CheckBoxContainer,
  ModalButtonContainer,
  ModalDescription,
  ModalTitle,
  VersionContainer,
} from './priveModal.style';
import { IInputPrive, IPriveModal } from './priveModal.models';
import { getCodeValidation } from './priveModal.utils';

const PriveModal = ({ prive, version, handleEnableNextStep, onTrackFunction }: IPriveModal) => {
  const { link, linkError, currentDataLayer } = useContext(TrackingPageContext);
  const { title, descriptionHtml, wrongCode, checkboxContent, customAction } = prive;

  const { isMobile } = useDeviceType();
  const [enabledCode, setEnabledCode] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [checkConfirm, setCheckConfirm] = useState(!checkboxContent);

  useEffect(() => {
    // TRACKING
    onTrackFunction();
  }, []);

  const handleTrackError = (label: string) => {
    linkError({
      ...currentDataLayer?.pdpDataLayer,
      form_error: wrongCode,
      link_name: `${currentDataLayer?.pdpDataLayer?.page_name || ''}:${label}`,
    });
  };

  const schema = getCodeValidation(prive.errorCode);
  const {
    register,
    getValues,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<IInputPrive>({
    resolver: yupResolver(schema),
    defaultValues: { code: '' },
    mode: 'all',
  });

  const { error } = useGetListLandingPage({
    version,
    code: getValues('code'),
    enabled: enabledCode,
    handleNextStep: handleEnableNextStep,
  });

  useEffect(() => {
    if (error?.status === 404) {
      handleTrackError('code error');
      setValue('code', '');
      setEnabledCode(false);
    }
  }, [error]);

  const handleCode = () => {
    if (!errors.code?.message) {
      link({
        ...currentDataLayer?.pdpDataLayer,
        link_name: `${currentDataLayer?.pdpDataLayer?.page_name || ''}:vai alla offerta`,
      });
      setEnabledCode(true);
    }
  };

  const codeState = watch('code');

  useEffect(() => {
    if (codeState.length > 0 && checkConfirm && !errors.code?.message) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [codeState, checkConfirm, errors.code?.message]);

  return (
    <VersionContainer>
      <ModalTitle>
        <CustomText
          fontFamily={fonts.exbold}
          textAlign={isMobile ? 'center' : 'left'}
          text={title || ''}
          modal
        />
      </ModalTitle>
      <ModalDescription>
        <CustomText
          fontFamily={fonts.regular}
          textAlign={isMobile ? 'center' : 'left'}
          size={20}
          text={descriptionHtml || ''}
          modal
        />
      </ModalDescription>

      {error?.status === 404 && (
        <Alert type="error" icon={<AlertWarning />} text={wrongCode || ''} />
      )}
      <Form.CodeInput
        {...register('code')}
        error={errors.code?.message}
        onErrorInput={() => handleTrackError('input error')}
      />
      {checkboxContent && (
        <CheckBoxContainer>
          <Checkbox checked={checkConfirm} onChange={() => setCheckConfirm(!checkConfirm)}>
            <span>{checkboxContent}</span>
          </Checkbox>
        </CheckBoxContainer>
      )}
      <ModalButtonContainer>
        <ButtonSlideCustom
          onClick={handleSubmit(handleCode)}
          label={customAction?.title || ''}
          style={customAction?.style}
          disabled={isDisabled}
        />
      </ModalButtonContainer>
    </VersionContainer>
  );
};

export default PriveModal;

