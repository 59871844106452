import { ButtonSlideCustom, ClickBox, CustomText } from '@vfit/shared/atoms';
import { Check } from '@vfit/shared-icons';
import { TrackingPageContext, getEventLabel } from '@vfit/business-data-access';
import {
  usePostCreateShoppingCartDevice,
  useVolaUtils,
  sumPriceDeviceAndOffer,
  ProductType,
  CTC_POSITION,
  getCharacteristicValues,
} from '@vfit/business-data-access';
import { GalleryImage, Pills, StickyOffer, VfModal } from '@vfit/shared/components';
import { colors, fonts, stringToAsciiCode } from '@vfit/shared/themes';
import { useContext, useEffect, useRef, useState } from 'react';
import { IActionCustom, IActionStyle, IActionType } from '@vfit/shared/models';
import { useDeviceType } from '@vfit/shared/hooks';
import { getActionUrlByActionType } from '@vfit/shared/data-access';
import {
  Box,
  ColorItem,
  ContainerDeviceImage,
  ContainerDeviceInfo,
  ContainerTags,
  ContainerTopDevice,
  DeviceCardSpecContainer,
  DeviceCardSpecItem,
  DeviceCardSpecText,
  DeviceCustomize,
  DeviceCustomizeContainer,
  DeviceSpecContainer,
  ListItem,
  ModalContent,
  ModalTitle,
  OfferBoxDesc,
  OfferBoxLeft,
  OfferBoxRight,
  OffertBox,
  SlideDeviceContent,
  SlideDeviceNavigation,
  ContainerButtons,
  WrapperPills,
} from './topDevice.style';
import {
  ICapacity,
  IColor,
  IDeviceMoreInfo,
  IDeviceProps,
} from '../WidgetDeviceHeroBanner/widgetDeviceHeroBanner.models';
import { ProductCardSpecItemAction } from '../WidgetSliderProductsHub/components/ProductCard/productCard.style';
import ProductDetail from '../ProductDetail/productDetail';
import WhatsappModal from '../WhatsappModal/whatsappModal';
import { useHandleClick } from '../WidgetDeviceHeroBanner/utils/useHandleClick.hook';
import DeviceOffer from './components/deviceOffer';
import { NavBackLink } from '../NavBackLink/navBackLink';

const TopDevice = ({
  deviceData,
  offerData,
  resourceData,
  offerResources,
  handleFilter,
}: IDeviceProps) => {
  const {
    deviceId,
    deviceType,
    backLabel,
    topPriceLabel,
    colorLabel,
    capacityLabel,
    offerMatchLabel,
    tags,
    additionalOfferDetails,
    actions,
    customAction,
    tagline,
    title,
    allCombinations,
  } = deviceData;

  const { resource, associatedOfferId, deviceImages, price } = resourceData;

  const { getVolaUrl, createUrlDesc } = useVolaUtils();
  const refActionsButtons = useRef<HTMLDivElement>(null);
  const { isShowWhatsapp, setIsShowWhatsapp, handleClick, showCheckout } = useHandleClick();
  const { link, currentDataLayer } = useContext(TrackingPageContext);
  const { isMobile } = useDeviceType();
  const [directionSlideStickyOffer, setDirectionSlideStickyOffer] = useState<
    'upward' | 'downward' | undefined
  >();

  const [modal, setModal] = useState({
    show: false,
    text: '',
    title: '',
  });

  const { data } = usePostCreateShoppingCartDevice(deviceId!, resource, showCheckout);

  useEffect(() => {
    const observer = new IntersectionObserver((entry) => {
      entry[0].isIntersecting
        ? setDirectionSlideStickyOffer('downward')
        : setDirectionSlideStickyOffer('upward');
    });
    observer.observe(refActionsButtons.current!);
  }, []);

  useEffect(() => {
    const checkoutAction = actions?.find((a) => a?.type == IActionType.CHECKOUT_MODAL_MOBILE);
    if (data && checkoutAction) {
      window.open(
        `${checkoutAction?.url}${data?.id}`,
        checkoutAction?.isBlank === 'true' ? '_blank' : '_self'
      );
    }
  }, [data]);

  const renderInfo = (info: IDeviceMoreInfo, icon = false) => {
    const { title: infoTitle, enableMoreInfo, moreInfoContentHtml, moreInfoTitle } = info;

    return (
      <DeviceCardSpecContainer>
        <DeviceCardSpecItem>
          {icon && (
            <div>
              <Check />
            </div>
          )}
          <DeviceCardSpecText>
            {infoTitle && (
              <CustomText text={infoTitle} size={14} lineHeight={18} textAlign="start" />
            )}
          </DeviceCardSpecText>
        </DeviceCardSpecItem>
        {enableMoreInfo === 'true' && (
          <div
            role="presentation"
            onClick={() => {
              setModal({
                show: true,
                text: moreInfoContentHtml || '',
                title: moreInfoTitle || '',
              });
              link(
                {
                  ...currentDataLayer?.pdpDataLayer,
                  link_name: `${currentDataLayer?.pdpDataLayer?.page_name}:Dettagli costi`,
                },
                associatedOfferId
              );
            }}
          >
            <ProductCardSpecItemAction>+</ProductCardSpecItemAction>
          </div>
        )}
      </DeviceCardSpecContainer>
    );
  };

  const renderGallery = () => (
    <ContainerDeviceImage>
      <GalleryImage images={deviceImages?.map((el) => el?.value as string)} externalUrl />
    </ContainerDeviceImage>
  );

  const getSticky = () => {
    const cta = actions?.find((item) => item?.style == IActionStyle.PRIMARY);

    return (
      cta && (
        <StickyOffer
          slideBanner={directionSlideStickyOffer}
          id="sticky-offer-device"
          topLabel={topPriceLabel}
          title={price || ''}
          description={customAction?.title || ''}
          ctaActivation={{
            action: () => {
              const desc = createUrlDesc({
                position: CTC_POSITION.STICKY_OFFER,
                productName: title,
                productPrice: sumPriceDeviceAndOffer(resource).toString(),
              });
              onTrack(cta);
              !getActionUrlByActionType(cta) && onHandleClick(cta!, desc);
            },
            label: cta?.title || '',
            actionNavigationUrl: getActionUrlByActionType(cta),
            isBlank: cta?.isBlank,
            type: cta?.type,
          }}
          content={getPdpDetails()}
        />
      )
    );
  };

  const getPdpDetails = () => (
    <ProductDetail
      product={{
        id: deviceId!,
        title: title!,
        price,
        description: '',
      }}
      productOffer={{
        offerId: associatedOfferId!,
        title: title!,
        taggingProductPrice: sumPriceDeviceAndOffer(resource).toString(),
        timeDiscount: resource?.product?.[0]?.timeDiscount,
        timeDiscountLabels: {
          timeDiscountDetails: getCharacteristicValues(resource?.product?.[0]?.characteristic || [], 'timeDiscountDetails'),
          timeDiscountTagForDevicePages: getCharacteristicValues(resource?.product?.[0]?.characteristic || [], 'timeDiscountTagForDevicePages'),
        }
      }}
      customComponent={<DeviceOffer deviceData={deviceData} resourceData={resourceData} isSticky />}
      tabs={[
        {
          productType: deviceType!,
          dxlId: deviceId!,
        },
        {
          productType: resource?.product?.[0]?.productType as ProductType,
          dxlId: resource?.product?.[0]?.productSpecificationId || '',
        },
      ]}
    />
  );

  const checkColorAvaibility = (item: IColor) => {
    const exist = offerResources?.find(
      (r) =>
        r?.resourceColour?.id === item?.gradientId &&
        r?.resourceCapacity?.id === resource?.resourceCapacity?.id &&
        r?.product?.find((e) => e?.productSpecificationId === associatedOfferId)
    );
    return !!exist;
  };

  const checkCapacityAvaibility = (item: ICapacity) => {
    const exist = offerResources?.find(
      (r) =>
        r?.resourceCapacity?.id === item?.capacityId &&
        r?.resourceColour?.id === resource?.resourceColour?.id &&
        r?.product?.find((e) => e?.productSpecificationId === associatedOfferId)
    );
    return !!exist;
  };

  const onTrack = (btn: IActionCustom) => {
    if (btn?.type != IActionType.WHATSAPP_URL) {
      link(
        {
          ...currentDataLayer?.pdpDataLayer,
          link_name: `${currentDataLayer?.pdpDataLayer?.page_name}${getEventLabel(btn?.type)}`,
        },
        associatedOfferId
      );
    }
  };

  const onHandleClick = (btn: IActionCustom, desc?: string) => {
    const taggingData = {
      offerId: deviceId!,
      taggingProductPrice: sumPriceDeviceAndOffer(resource).toString(),
    };

    btn && handleClick(btn, taggingData, desc);
  };
  return (
    <>
      <SlideDeviceContent>
        <SlideDeviceNavigation>
          {backLabel && (
            <NavBackLink navBackLinkUrl={backLabel?.url} navBackLinkText={backLabel?.text} />
          )}
        </SlideDeviceNavigation>
        <ContainerTopDevice>
          {!isMobile && renderGallery()}
          <ContainerDeviceInfo>
            <ContainerTags>
              {tagline && <Pills pills={[tagline]} variant={colors.$00697c} />}
              {tags?.map((tag, index) => (
                <Pills
                  key={stringToAsciiCode(index.toString())}
                  pills={[tag.keyword]}
                  variant={colors.$0d0d0d}
                />
              ))}
            </ContainerTags>

            <DeviceOffer deviceData={deviceData} resourceData={resourceData} />

            <DeviceSpecContainer>
              <div className="additionalOfferDetails">
                {additionalOfferDetails ? renderInfo(additionalOfferDetails) : '\u00A0'}
              </div>
            </DeviceSpecContainer>
            {isMobile && renderGallery()}
            <DeviceCustomizeContainer isFirstElement>
              <DeviceCustomize>
                <p>{colorLabel}</p>
                <ListItem>
                  {allCombinations?.colors?.map((item, index) => (
                    <div key={stringToAsciiCode(index.toString())}>
                      <ClickBox
                        selected={resource?.resourceColour?.id === item?.gradientId}
                        onClick={() => handleFilter('color', item?.gradientId)}
                        disabled={!checkColorAvaibility(item)}
                      >
                        <Box selected={resource?.resourceColour?.id === item?.gradientId}>
                          <ColorItem
                            itemColor={item?.startingColor}
                            colorName={item?.gradientName}
                          />
                          <p>{item?.gradientName}</p>
                        </Box>
                      </ClickBox>
                    </div>
                  ))}
                </ListItem>
              </DeviceCustomize>
            </DeviceCustomizeContainer>

            <DeviceCustomizeContainer>
              <DeviceCustomize>
                <p>{capacityLabel}</p>
                <ListItem>
                  {allCombinations?.capacity?.map((item, index) => (
                    <div key={stringToAsciiCode(index.toString())}>
                      <ClickBox
                        selected={resource?.resourceCapacity?.id === item?.capacityId}
                        onClick={() => handleFilter('capacity', item.capacityId)}
                        disabled={!checkCapacityAvaibility(item)}
                      >
                        <Box selected={resource?.resourceCapacity?.id === item?.capacityId}>
                          <p>{item?.capacityLabel}</p>
                        </Box>
                      </ClickBox>
                    </div>
                  ))}
                </ListItem>
              </DeviceCustomize>
            </DeviceCustomizeContainer>

            <DeviceCustomizeContainer>
              <DeviceCustomize>
                <p>{offerMatchLabel}</p>
                {offerData?.map((item, index) => (
                  <div key={stringToAsciiCode(index.toString())}>
                    <ClickBox
                      selected={associatedOfferId === item?.id}
                      onClick={() => handleFilter('offerId', item.id!)}
                    >
                      <OffertBox>
                        <OfferBoxRight selected={associatedOfferId === item?.id}>
                          {item?.deviceTimeDiscount && (
                            <WrapperPills>
                              <Pills pills={[item?.deviceTimeDiscount]} variant={colors.$00697c} />
                            </WrapperPills>
                          )}
                          <p className="titleBoxOffer">{item.title}</p>
                          <OfferBoxDesc>
                            <CustomText
                              text={item.description}
                              size={16}
                              lineHeight={22}
                              textAlign="start"
                            />
                          </OfferBoxDesc>
                        </OfferBoxRight>
                        <OfferBoxLeft selected={associatedOfferId === item.id}>
                          <CustomText text={item.price} />
                          <CustomText text={item.unaTantum} />
                        </OfferBoxLeft>
                      </OffertBox>
                    </ClickBox>
                  </div>
                ))}
              </DeviceCustomize>
            </DeviceCustomizeContainer>
            <ContainerButtons ref={refActionsButtons}>
              {actions?.map((action, i) => (
                <ButtonSlideCustom
                  key={stringToAsciiCode(i.toString())}
                  label={action?.title}
                  onClick={() => {
                    const desc = createUrlDesc({
                      position: CTC_POSITION.TOP_PRODUCT,
                      productName: title,
                      productPrice: sumPriceDeviceAndOffer(resource).toString(),
                    });
                    onTrack(action);
                    onHandleClick(action, desc);
                  }}
                  style={action?.style}
                />
              ))}
            </ContainerButtons>
          </ContainerDeviceInfo>
        </ContainerTopDevice>
        {getSticky()}
      </SlideDeviceContent>
      <VfModal
        height={563}
        isOpen={modal.show}
        handleClose={() => setModal({ show: false, text: '', title: '' })}
      >
        <>
          {modal.title && (
            <ModalTitle>
              <CustomText fontFamily={fonts.exbold} textAlign="left" text={modal.title} />
            </ModalTitle>
          )}
          <ModalContent>
            <CustomText size={18} lineHeight={24} textAlign="left" text={modal.text} />
          </ModalContent>
        </>
      </VfModal>
      <VfModal height={540} isOpen={isShowWhatsapp} handleClose={() => setIsShowWhatsapp(false)}>
        <WhatsappModal
          product={{
            offerId: deviceId!,
            title: title!,
            taggingProductPrice: sumPriceDeviceAndOffer(resource).toString(),
          }}
          urlVola={getVolaUrl(deviceData)}
        />
      </VfModal>
    </>
  );
};

export default TopDevice;

