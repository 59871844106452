import { useContext } from 'react';
import { NationContext, changeTextByFlag } from '@vfit/business-data-access';
import { TabsContext } from '@vfit/shared/data-access';
import { IBusinessWidgetText } from '../WidgetText/widgetText.model';
import { TextWrapperContainer } from './textWrapper.style';

const TextWrapper = (TextWidget) => (widget: IBusinessWidgetText) => {
  const { pageType, nation } = useContext(NationContext);
  const { isActive } = useContext(TabsContext);
  // eslint-disable-next-line no-unsafe-optional-chaining, react/destructuring-assignment
  const { description, text } = widget?.widget;
  let transformWidget = widget;

  if (pageType['isDetailCountry'] === 'true') {
    transformWidget = {
      ...widget,
      widget: {
        // eslint-disable-next-line react/destructuring-assignment
        ...widget.widget,
        description: changeTextByFlag(isActive ? description : text, nation?.name, '$NAZIONE$'),
      },
    };
  }

  return (
    // eslint-disable-next-line react-hooks/rules-of-hooks, react/jsx-no-useless-fragment
    <TextWrapperContainer>
      <TextWidget {...transformWidget} />
    </TextWrapperContainer>
  );
};
export default TextWrapper;
