import { useDeviceType } from '@vfit/shared/hooks';
import { IFaqWidget } from 'libs/shared/components/src/lib/Faq/faq.models';
import { getActionUrlByActionType } from '@vfit/shared/data-access';
import { IBusinessFaq } from './widgetFaq.models';
import { formatFaqProps } from './widgetFaq.utils';

export const useWidgetFaq = (widget: IBusinessFaq): IFaqWidget => {
  const { faq, topText } = formatFaqProps(widget);
  const { isMobile } = useDeviceType();
  const containerStyle: React.CSSProperties = {
    paddingTop: isMobile ? 32 : 48,
  };

  return {
    faq,
    topText,
    containerStyle,
    divider: widget.divider,
    disclaimer: widget.disclaimer,
    ...(widget.customAction && {
      actionNavigationUrl: getActionUrlByActionType(widget.customAction),
    }),
  };
};
