import React, { useContext, useEffect, useState } from 'react';
import { PageLoadingContext, RetargetContext, TrackingPageContext } from '@vfit/business-data-access';
import { VfModal } from '@vfit/shared/components';
import { useQueryClient } from 'react-query';
import { usePostCreateShoppingCartMobile } from '@vfit/business-data-access';
import { IActionType } from '@vfit/shared/models';
import { WidgetContainer } from '../Widgets/widgets.style';
import { IBusinessWidgetProductHeroBanner } from './widgetProductHeroBanner.models';
import { useWidgetProductHeroBanner } from './widgetProductHeroBanner.hook';
import TopProduct from '../TopProduct/topProduct';
import SkeletonPdp from '../SkeletonPdp';
import PriveModal from '../PriveModal/priveModal';

const WidgetProductHeroBanner = ({ widget, isLastWidget }: IBusinessWidgetProductHeroBanner) => {
  const { isLoading } = useContext(PageLoadingContext);
  const { setOfferTimer } = useContext(RetargetContext);
  const { view, currentDataLayer, setCurrentDataLayer } = useContext(TrackingPageContext);
  const queryClient = useQueryClient();
  const isPrive = widget.product?.landingprive?.isPrive === 'true';
  const [modal, setModal] = useState(!!isPrive);
  const [enabled, setEnabled] = useState(!isPrive);
  const [createCart, setCreateCart] = useState('');

  useEffect(() => {
    if (!isLoading && widget?.product?.version && !isPrive)
      setOfferTimer(
        widget?.product?.version,
        widget?.product?.dxlId || '',
        widget?.product?.retarget
      );
    if (isPrive)
      queryClient.removeQueries([widget?.product?.dxlId || '', 'ecommerceCatalogCoreOffer']);
  }, [isLoading]);

  const handleEnableNextStep = () => {
    setEnabled(true);
    setModal(false);
  };

  const info = useWidgetProductHeroBanner(widget, enabled);

  const onTrackFunction = () => {
    const datalayer = view({
      event_name: ['view'],
      event_label: 'Popup prive',
      page_section: 'eshop',
      page_subsection: 'popup prive',
      page_type: widget.product?.productType,
      journey_type: 'task',
      event_category: ['sales'],
    });
    setCurrentDataLayer({
      ...currentDataLayer,
      pdpDataLayer: { ...currentDataLayer?.pdpDataLayer, ...datalayer },
    });
  };

  const { data } = usePostCreateShoppingCartMobile(info.product?.offerId!, createCart);

  useEffect(() => {
    const checkoutAction = info.product?.actions?.find(
      (a) => a?.type == IActionType.CHECKOUT_MODAL_MOBILE
    );
    if (data && checkoutAction) {
      window.open(
        `${checkoutAction?.url}${data?.id}`,
        checkoutAction?.isBlank === 'true' ? '_blank' : '_self'
      );
    }
  }, [data]);

  if (isLoading && enabled) return <SkeletonPdp />;
  return (
    <>
      <WidgetContainer isLastWidget={isLastWidget}>
        <TopProduct {...{ ...info, createShoppingCart: () => setCreateCart(info.product?.offerId!) }} />
      </WidgetContainer>
      {isPrive && widget.product.version && (
        <VfModal isOpen={modal} isDisabledClose height={560}>
          <PriveModal
            onTrackFunction={onTrackFunction}
            prive={widget.product.landingprive!}
            version={widget.product.version}
            handleEnableNextStep={() => handleEnableNextStep()}
          />
        </VfModal>
      )}
    </>
  );
};

export default WidgetProductHeroBanner;

