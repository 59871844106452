import React, { Children, useContext, useEffect, useRef, useState } from 'react';
import { Check } from '@vfit/shared-icons';
import { ButtonSlideCustom, CustomText, ImageAtoms } from '@vfit/shared/atoms';
import { colors, fonts } from '@vfit/shared/themes';
import { Pills, VfModal } from '@vfit/shared/components';
import { IActionCustom } from '@vfit/shared/models';
import { RetargetContext, ITimerStatus } from '@vfit/business-data-access';
import { CTC_POSITION, useVolaUtils } from '@vfit/business-data-access';
import WhatsappModal from '../WhatsappModal/whatsappModal';
import CoverageToolModal from '../CoverageToolModal/coverageToolModal';
import { useCardFunctionality } from './productCard.hook';
import { IProductCardProps, IProductMoreInfo } from './productCard.models';
import {
  ModalContent,
  ModalTitle,
  ProductCardBottom,
  ProductCardBottomContainer,
  ProductCardBottomContainerText,
  ProductCardContainer,
  ProductCardOfferLabel,
  ProductCardSpecContainer,
  ProductCardSpecItem,
  ProductCardSpecItemAction,
  ProductCardSpecText,
  ProductCardSpecTitle,
  ProductCardTop,
  ProductCardTopContent,
  DivImgContainer,
  H3Header,
  ProductCardCategory,
} from './productCard.style';
import ProductDetail from '../ProductDetail/productDetail';
// import { useDeviceType } from '@vfit/shared/hooks';

const ProductCard = ({
  product,
  haveMarketingLabel,
  large,
  noMargin,
  createShoppingCart,
  handleTracking,
  handleRetarget,
}: IProductCardProps) => {
  const specRef = useRef<HTMLDivElement | null>(null);
  const {
    offerName,
    frontLabel,
    bottomLabel,
    additionalOfferDetails,
    offerSpecs,
    image,
    price,
    actions,
    pills,
    tagLine,
    offerId,
    priceDescription,
    unaTantumPriceDescription,
    shortDescription,
    retarget,
    timeDiscount,
    timeDiscountLabels,
    version,
    category,
    taggingProductPrice,
  } = product;

  const {
    handleClick,
    isShowCoverageTool,
    setIsShowCoverageTool,
    isShowWhatsapp,
    setIsShowWhatsapp,
    showCheckout,
    modalDetails,
    setModalDetails,
  } = useCardFunctionality();
  const { getVolaUrl, createUrlDesc } = useVolaUtils();
  const { checkRetargetActive } = useContext(RetargetContext);
  // const { isMobile } = useDeviceType();

  useEffect(() => {
    if (showCheckout === product.offerId) {
      createShoppingCart?.(showCheckout);
    }
  }, [showCheckout]);

  const [modal, setModal] = useState({
    show: false,
    text: '',
    title: '',
  });

  const renderPill = () => {
    if(timeDiscount && timeDiscountLabels?.timeDiscountTag) {
      return <Pills pills={[timeDiscountLabels?.timeDiscountTag]} variant={colors.$00697c} opacity={1} />
    } if(checkRetargetActive({ productId: offerId!, version }) === ITimerStatus.ACTIVE) {
      return retarget?.label ? (
        <Pills pills={[retarget.label]} variant={colors.$00697c} opacity={1} />
      ) : (
        <h6>{'\u00A0'}</h6>
      );
    } return <h6>{frontLabel || '\u00A0'}</h6>;
  }

  const renderPrice = () => (
    <>
      {renderPill()}
      {price && (
        <div className="productTitle">
          <CustomText text={`<div class="price">${price}</div>`} textAlign="left" />
        </div>
      )}
      <h4>{bottomLabel || '\u00A0'}</h4>
      {priceDescription && (
        <div className="productDescription">
          <CustomText
            text={priceDescription}
            textAlign="left"
            fontFamily={fonts.regular}
            size={18}
            lineHeight={24}
          />
        </div>
      )}
      {unaTantumPriceDescription && (
        <div className="productDescription">
          <CustomText
            text={unaTantumPriceDescription}
            textAlign="left"
            fontFamily={fonts.regular}
            size={18}
            lineHeight={24}
          />
        </div>
      )}
      {shortDescription && (
        <div className="productDescription">
          <CustomText
            text={shortDescription}
            textAlign="left"
            fontFamily={fonts.regular}
            size={18}
            lineHeight={24}
          />
        </div>
      )}
      <div className="additionalOfferDetails">
        {additionalOfferDetails ? renderInfo(additionalOfferDetails) : '\u00A0'}
      </div>
    </>
  );

  const renderInfo = (info: IProductMoreInfo, icon = false) => {
    const { title, subtitle, enableMoreInfo, moreInfoContentHtml, moreInfoTitle } = info;

    return (
      <ProductCardSpecContainer>
        <ProductCardSpecItem>
          {icon && (
            <div>
              <Check />
            </div>
          )}
          <ProductCardSpecText>
            {title && <ProductCardSpecTitle>{title}</ProductCardSpecTitle>}
            {subtitle && <CustomText text={subtitle} textAlign="left" />}
          </ProductCardSpecText>
        </ProductCardSpecItem>
        {enableMoreInfo === 'true' && (
          <div
            role="presentation"
            onClick={() =>
              setModal({
                show: true,
                text: moreInfoContentHtml || '',
                title: moreInfoTitle || '',
              })
            }
          >
            <ProductCardSpecItemAction>+</ProductCardSpecItemAction>
          </div>
        )}
      </ProductCardSpecContainer>
    );
  };

  const onHandleClick = (btn: IActionCustom, desc?: string) => {
    // aggiunta funzione callback per gestione tracking
    handleTracking?.(btn);
    // aggiunta funzione callback per gestione retarget
    handleRetarget?.(btn);
    // if (btn?.type == IActionType.CHECKOUT_MODAL_MOBILE)
    //   setOfferTimer(version!, product?.offerId || '', retarget);
    handleClick(btn, product, desc);
  };

  const renderButtons = () => (
    <div
      style={{
        marginBottom: 32,
      }}
    >
      {Children.toArray(
        actions?.map((btn) => (
          <div
            style={{
              marginBottom: 20,
            }}
          >
            <ButtonSlideCustom
              onClick={() => {
                const desc = createUrlDesc({
                  position: CTC_POSITION.PRODUCT_CARD,
                  productName: offerName,
                  productPrice: taggingProductPrice,
                });
                onHandleClick(btn, desc);
              }}
              label={btn.title}
              style={btn?.style && +btn.style}
              name={`action_offerslider_${btn.type}`}
            />
          </div>
        ))
      )}
    </div>
  );

  const renderSpecs = () => (
    <div
      ref={specRef}
      style={{
        marginBottom: 32,
      }}
    >
      {Children.toArray(
        offerSpecs &&
          offerSpecs.length > 0 &&
          offerSpecs.map((spec) => <div>{renderInfo(spec, true)}</div>)
      )}
    </div>
  );

  const getPdpDetails = () => (
    <ProductDetail
      product={{
        id: product.offerId || '',
        title: product?.offerName || '',
        price: product?.price,
        description: product?.priceDescription || '',
      }}
      productOffer={product}
      showPopupTitle
    />
  );

  return (
    <>
      {haveMarketingLabel && (
        <ProductCardOfferLabel haveLabel={!!tagLine}>
          {tagLine && <span>{tagLine}</span>}
        </ProductCardOfferLabel>
      )}
      <ProductCardContainer noMargin={noMargin} haveOfferLabel={haveMarketingLabel}>
        <ProductCardTop large={large} haveImage={!!image} className="product-card-top">
          <Pills
            pills={pills?.map((p) => p.keyword) || []}
            opacity={image ? 1 : undefined}
            borderColor={!image ? '#000000' : ''}
          />
          <ProductCardTopContent haveImage={!!image}>
            {category && <ProductCardCategory>{category}</ProductCardCategory>}
            <H3Header haveImage={!!image}>{offerName}</H3Header>
          </ProductCardTopContent>
          {image && (
            <DivImgContainer absolute>
              <ImageAtoms nameCard="topImage" image={image} alt={`${category || ''} ${offerName}`}/>
            </DivImgContainer>
          )}
        </ProductCardTop>
        <ProductCardBottom>
          <ProductCardBottomContainer>
            <ProductCardBottomContainerText>{renderPrice()}</ProductCardBottomContainerText>
            <div>{renderButtons()}</div>
            <div>{renderSpecs()}</div>
          </ProductCardBottomContainer>
        </ProductCardBottom>
        <VfModal
          height={563}
          isOpen={modal.show}
          handleClose={() => setModal({ show: false, text: '', title: '' })}
        >
          <>
            {modal.title && (
              <ModalTitle>
                <CustomText fontFamily={fonts.exbold} textAlign="left" text={modal.title} />
              </ModalTitle>
            )}
            <ModalContent>
              <CustomText size={18} lineHeight={24} textAlign="left" text={modal.text} />
            </ModalContent>
          </>
        </VfModal>
        <VfModal height={540} isOpen={isShowWhatsapp} handleClose={() => setIsShowWhatsapp(false)}>
          <WhatsappModal product={product} urlVola={getVolaUrl(product)} />
        </VfModal>
        <VfModal
          height={563}
          isOpen={modalDetails.show}
          handleClose={() => setModalDetails({ show: false })}
        >
          {getPdpDetails()}
        </VfModal>
        <CoverageToolModal
          backgroundImage={product.image}
          isOpen={isShowCoverageTool}
          product={product}
          handleClose={() => setIsShowCoverageTool(false)}
        />
      </ProductCardContainer>
    </>
  );
};

export default ProductCard;

