import { useContext } from 'react';
import { CardInfoProduct, VfModal } from '@vfit/shared/components';
import {
  IEcommerceCatalogResponse,
  IProductInfo,
  useGetSelectedAddons,
} from '@vfit/business-data-access';
import { useQueryClient } from 'react-query';
import { PageLoadingContext } from '@vfit/business-data-access';
import { fonts } from '@vfit/shared/themes';
import { CustomText } from '@vfit/shared/atoms';
import { IBusinessWidgetAddons } from './widgetAddons.models';
import { useWidgetAddons } from './widgetAddons.hook';
import { WidgetContainer } from '../Widgets/widgets.style';
import { ModalContent, ModalTitle } from './widgetAddons.style';
import SkeletonAddon from '../SkeletonAddon';

const WidgetAddons = ({ widget, isLastWidget = true }: IBusinessWidgetAddons) => {
  const queryClient = useQueryClient();
  const { isLoading } = useContext(PageLoadingContext);

  const selectedProductId = queryClient.getQueryData('selectedProduct') as string;

  const catalogWithAddress = queryClient.getQueryData(
    'ecommerceCatalogWithAddress'
  ) as IEcommerceCatalogResponse;
  const catalog = queryClient.getQueryData([
    selectedProductId,
    'ecommerceCatalogCoreOffer',
  ]) as IEcommerceCatalogResponse;

  // addons
  useGetSelectedAddons(
    (catalogWithAddress?.productInfo?.[0] || catalog?.productInfo?.[0]) as IProductInfo,
    {
      addon: widget,
    }
  );
  const cardInfo = useWidgetAddons(widget);

  if (isLoading) return <SkeletonAddon />;
  return (cardInfo?.newCards?.length > 0 && (
      <>
        <WidgetContainer isLastWidget={isLastWidget}>
          <CardInfoProduct {...cardInfo} />
        </WidgetContainer>
        <VfModal
          height={563}
          isOpen={cardInfo.modal.show}
          handleClose={() => cardInfo.setModal({ show: false, text: '', title: '' })}
        >
          <>
            {cardInfo.modal.title && (
              <ModalTitle>
                <CustomText
                  fontFamily={fonts.exbold}
                  textAlign="left"
                  text={cardInfo.modal.title}
                />
              </ModalTitle>
            )}
            <ModalContent>
              <CustomText size={18} lineHeight={24} textAlign="left" text={cardInfo.modal.text} />
            </ModalContent>
          </>
        </VfModal>
      </>
    )
  );
};

export default WidgetAddons;

