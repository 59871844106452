import {
  CMS_CONFIG,
  IPlaceholderRequest,
  useCmsConfig,
  useGetEcommerceCatalogDSById
} from '@vfit/business-data-access';
import { API } from '@vfit/shared/data-access';
import { IBusinessProductHeroBannerDS } from './widgetProductHeroBannerDS.models';
import { getDSHeroBanner } from './widgetProductHeroBannerDS.utils';

export const useWidgetProductHeroBannerDS = (widget: IBusinessProductHeroBannerDS) => {
  const { placeholders } = useCmsConfig(
    CMS_CONFIG['BUSINESS_DSIOT_PDP'],
    API.CMS_DSIOT_GET_COMMON_PLACEHOLDER
  ) as IPlaceholderRequest;

  const { data } = useGetEcommerceCatalogDSById([widget?.product]);

  const topProduct = getDSHeroBanner(
    placeholders?.placeholders,
    widget?.product,
    data?.[0]?.businessSolutionInfo?.[0]
  );

  return {
    product: topProduct,
    disclaimer: widget.descriptionHtml,
    description: widget.disclaimerHtml,
    divider: widget.divider,
    disableNavigation: !widget?.product?.backLabel,
    offerDetails: widget?.customAction
  };
};
