import { useAddressGeocoder, loaderObj } from '@vfit/shared/hooks';
import { Loader } from '@googlemaps/js-api-loader';
import { LoggerInstance } from '@vfit/shared/data-access';
import * as yup from 'yup';
import { ICoverageToolCongifg } from '@vfit/business-data-access';
import { IAddressObject, ICoverageToolType, IGeoAddressElab, IInputs } from './coverageTool.models';

const GetAddressObjectFromString = async (text: string) =>
  Promise.resolve(await useAddressGeocoder(text));

const retrieveAddress = (add: IAddressObject) => {
  let addressString = '';
  Object.values(add).map((el: string) => {
    if (el) addressString = addressString.concat(el, ', ');
    return null;
  });
  return addressString;
};

const checkCoverageToolType = (mutateLocation: IInputs): ICoverageToolType => {
  let typeCoverageTool = ICoverageToolType.AUTO;

  LoggerInstance.debug('Mutate location', mutateLocation);
  Object.keys(mutateLocation).forEach((key) => {
    if (!mutateLocation[key]) typeCoverageTool = ICoverageToolType.MANUAL;
  });

  return typeCoverageTool;
};

const checkAddressFromValue = async (text: string) =>
  Promise.resolve(await GetAddressObjectFromString(text));

async function getAddressFromLatLng(lat: number, lng: number) {
  const mapsLoader = new Loader(loaderObj);
  const google = await mapsLoader.load();
  if (google !== undefined) {
    const geocoder = new google.maps.Geocoder();
    const latlng = new google.maps.LatLng(lat, lng);
    return new Promise((resolve, reject) => {
      geocoder.geocode(
        {
          location: latlng,
        },
        (results, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            if (results !== null) {
              const filteredResults = results.filter(
                (result) => result.address_components.length === 8
              );
              if (filteredResults.length !== 0) {
                resolve(filteredResults[0]);
              } else resolve(results[0]);
            } else {
              LoggerInstance.debug('No results found');
            }
          } else {
            LoggerInstance.debug('Geocoder failed due to: ', status);
          }
          // eslint-disable-next-line prefer-promise-reject-errors
          reject('Geolocalization error');
        }
      );
    });
  }
  return null;
}

const getCoverageInputValidation = (coverageToolConfig: ICoverageToolCongifg) =>
  yup
    .object({
      placeId: yup.string().required(''), //  This is handled in AddressInput
      city: yup
        .string()
        .min(1, coverageToolConfig?.coveragetool?.errorMessage?.error0 || ' ')
        .matches(
          /^[a-zA-Z+"àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝ"+\s]*$/g,
          coverageToolConfig?.coveragetool?.errorMessage?.error1 || ' '
        ),
      //  .required(coverageToolConfig.coveragetool.errorMessage.error0),
      street: yup
        .string()
        .min(1, coverageToolConfig?.coveragetool?.errorMessage?.error0 || ' ')
        .matches(
          /^[a-zA-Z+"-àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝ"+\s]*$/g,
          coverageToolConfig?.coveragetool?.errorMessage?.error1 || ' '
        ),
      // .required(coverageToolConfig.coveragetool.errorMessage.error0),
      streetNumber: yup
        .string()
        .test(
          'rangeTest',
          coverageToolConfig?.coveragetool?.errorMessage?.error1 || ' ',
          (value) => {
            if (value?.match(/^[1-9]([0-9]{0,5})[a-zA-Z]?$/)) {
              return true;
            }
            return false;
          }
        )
        .required(coverageToolConfig.coveragetool.errorMessage.error0),
    })
    .required();

const composeAddress = (value: any) => {
  const { address_components } = value;
  const address: IGeoAddressElab = {
    street: '',
    streetNumber: '',
    city: '',
    province: '',
    postalCode: '',
    latitude: '',
    longitude: '',
  };

  address_components.forEach((v: any) => {
    if (v.types.includes('route')) address.street = v.long_name;
    else if (v.types.includes('administrative_area_level_3')) address.city = v.long_name;
    else if (v.types.includes('administrative_area_level_2')) address.province = v.short_name;
    else if (v.types.includes('postal_code')) address.postalCode = v.long_name;
    else if (v.types.includes('street_number')) address.streetNumber = v.long_name;
  });

  // @ts-ignore
  address.placeId = value.place_id;
  // @ts-ignore
  address.latitude = value?.geometry?.location.lat();
  // @ts-ignore
  address.longitude = value?.geometry?.location.lng();

  return address;
};

export {
  checkCoverageToolType,
  retrieveAddress,
  checkAddressFromValue,
  getAddressFromLatLng,
  getCoverageInputValidation,
  composeAddress,
};

