import React, { forwardRef } from 'react';
import { ICheckbox } from './checkbox.models';
import * as S from './checkbox.style';

export const Checkbox = forwardRef<HTMLInputElement, ICheckbox>(
  ({ children, styled, disabled, style, ...rest }, ref) => (
    <S.Label disabled={disabled} style={style} styled={styled}>
      <S.Input {...rest} disabled={disabled} ref={ref} type="checkbox" />
      {children}
    </S.Label>
  )
);

