import { DXL, IBusinessSolutionInfo } from '@vfit/business-data-access';
import { IBusinessHubDigitalSolutions, IDigitalSolution } from './widgetHubDigitalSolutions.model';

export const getValues = (characteristic: DXL.ICharacteristic[], name: string) => {
  const values = characteristic?.find((ch) => ch.name === name)
    ?.value as DXL.ICharacteristicValue[];

  return values?.[0]?.value || '';
};

export const formatDigitalSolutions = (
  widget: IBusinessHubDigitalSolutions,
  digitalSolutions?: IBusinessSolutionInfo[]
): IDigitalSolution[] =>
  digitalSolutions?.map((item) => ({
    id: item?.businessSolutionId || '',
    image: getValues(item?.editionInfo?.[0].characteristic || [], 'backgroundImage').trim() || '',
    title: item.businessSolutionName || '',
    description:
      getValues(item?.editionInfo?.[0].characteristic || [], 'businessSolutionClaim') || '',
    tag: getValues(item?.editionInfo?.[0].characteristic || [], 'tag') || '',
    action: {
      ...widget.customAction,
      style: Number(widget.customAction?.style),
      url: getValues(item?.editionInfo?.[0].characteristic || [], 'businessSolutionUrl') || '',
      localUrl: getValues(item?.editionInfo?.[0].characteristic || [], 'businessSolutionUrl') || '', // used by passionCard component
    },
  })) || [];

export const getFilteredProductData = (
  tab: string,
  digitalSolutions: IDigitalSolution[],
  widget: IBusinessHubDigitalSolutions
) => {
  const filteredData = digitalSolutions?.filter(
    (ds) => ds.tag === tab || tab === widget.tags.allTagsLabel
  );
  return {
    product_id: [...filteredData.map((item) => item['id'])],
    product_name: [...filteredData.map((item) => item['title'])],
    product_category: [...filteredData.map(() => 'DS')],
    // product_price: [],
    product_target_segment: [...filteredData.map(() => 'business')],
    product_quantity: filteredData.length.toString(),
  };
};

