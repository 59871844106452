import { createGlobalStyle } from 'styled-components';

export const OverlayGlobalStyle = createGlobalStyle`
  body {
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: fixed;
  }

  header {
    display: none !important;
  }

  #sticky-offer-product {
    display: none;
  }

  #sticky-offer-summary {
    visibility: hidden;
  }
`;

