import { useContext } from 'react';
import { TabsContext } from '@vfit/shared/data-access';
import { IProductWrapper } from './ProductWrapper.model';
import { useProductWrapper } from './ProductWrapper.hook';

const ProductsWrapper = (SliderProductsHub) => (widget: IProductWrapper) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { selectedTab } = useContext(TabsContext);

  const transformWidget = useProductWrapper(widget);

  if (
    (selectedTab != transformWidget.widget?.tabId && transformWidget.widget?.tabId) ||
    transformWidget['widget'].elements.length == 0
  ) {
    return null;
  }

  return (
    // eslint-disable-next-line react-hooks/rules-of-hooks, react/jsx-no-useless-fragment
    <SliderProductsHub {...transformWidget} />
  );
};
export default ProductsWrapper;
