import { PageLoadingContext, TrackingPageContext } from '@vfit/business-data-access';
import { useContext, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { IEcommerceCatalogResponse } from '@vfit/business-data-access';
import { CardInfoDevice, ListCardDevice } from '@vfit/shared/components';
import { IActionCustom, IActionType } from '@vfit/shared/models';
import { useRouter } from 'next/router';
import { ButtonSlideCustom } from '@vfit/shared/atoms';
import { stringToAsciiCode } from '@vfit/shared/themes';
import { getActionUrlByActionType } from '@vfit/shared/data-access';
import FilterBrandModal from '../FilterModal/filterBrandModal';
import { WidgetContainer } from '../Widgets/widgets.style';
import useWidgetHubDevice from './useWidgetHubDevice.hook';
import { IBusinessWidgetHubDevice, IFilterSet } from './widgetHubDevice.model';
import SkeletonHubDevice from '../SkeletonHubDevice';
import { formatData } from './widgetHubDevice.utils';
import { DeviceNotAvailable } from './widgetHubDevice.style';

const WidgetHubDevice = ({ widget, isLastWidget }: IBusinessWidgetHubDevice) => {
  const { isLoading } = useContext(PageLoadingContext);
  const { link, currentDataLayer } = useContext(TrackingPageContext);
  const { products } = useWidgetHubDevice(widget);
  const { deviceNotAvailable, buttonLabelDeviceOutofStock } = widget;
  const filter = products?.filter;
  const [modal, setModal] = useState(false);
  const [productDevices, setProductDevices] = useState(products?.products);
  const queryClient = useQueryClient();
  const [filterSet, setFilterSet] = useState<IFilterSet>({ isFiltered: false, filteredBy: '' });
  const catalog = queryClient.getQueryData('ecommerceCatalogDevices') as IEcommerceCatalogResponse;
  const { push } = useRouter();

  useEffect(() => {
    if (!isLoading) setProductDevices(products?.products);
  }, [isLoading]);

  const handleFilterBrand = (item: string) => {
    if (!products.products) return;
    setProductDevices(products?.products?.filter((product) => product.brand === item));
    setModal(false);
    setFilterSet({ isFiltered: true, filteredBy: item });
  };

  const handlesortAscBrand = () => {
    if (!catalog) return;
    const sortAsc = [...(catalog?.deviceInfo ?? [])].sort((a, b) =>
      Number(a.lowerCost) > Number(b.lowerCost) ? 1 : -1
    );
    const formatSortAsc = formatData(widget, sortAsc);
    setProductDevices(formatSortAsc.products);
    setModal(false);
    setFilterSet({ isFiltered: true, filteredBy: 'Prezzo crescente' });
  };

  const handlesortDescBrand = () => {
    if (!catalog) return;
    const sortDesc = [...(catalog?.deviceInfo ?? [])].sort((a, b) =>
      Number(a.lowerCost) < Number(b.lowerCost) ? 1 : -1
    );
    const formatSortDesc = formatData(widget, sortDesc);
    setProductDevices(formatSortDesc.products);
    setModal(false);
    setFilterSet({ isFiltered: true, filteredBy: 'Prezzo decrescente' });
  };

  const handleRemoveFilter = () => {
    if (!catalog) return;
    const originalList = catalog?.deviceInfo;
    const formatOriginalList = formatData(widget, originalList);
    setFilterSet({ isFiltered: false, filteredBy: '' });
    setProductDevices(formatOriginalList.products);
  };

  const getNumberOfDevice = (item: string) => {
    if (!products.products) return 0;
    return products.products?.filter((product) => product.brand === item).length;
  };

  const onTrack = (device: any) => {
    link(
      {
        ...currentDataLayer?.hubDataLayer,
        link_name: `${
          currentDataLayer?.hubDataLayer?.page_name
        }:${device?.action?.title?.toLocaleLowerCase()}`,
      },
      device?.dxlId
    );
  };

  const goBack = (param: IActionCustom) => {
    const type = param?.type ? +param.type : 0;

    switch (type) {
      case IActionType.LOCAL_URL:
        if (param?.url) push(param?.url);
        break;
      case IActionType.CUSTOM_URL:
        if (param?.url) window.open(param?.url, param?.isBlank === 'true' ? '_blank' : '_self');
        break;
      default:
        break;
    }
  };

  if (isLoading) return <SkeletonHubDevice />;

  return (
    <WidgetContainer isLastWidget={isLastWidget}>
      <ListCardDevice
        title={products?.title}
        labelFilter={products?.labelFilter}
        filterSetLabel={products?.filterSetLabel}
        openModal={setModal}
        isOpen={modal}
        isFiltered={filterSet}
        handleRemoveFilter={handleRemoveFilter}
        modalComponent={
          <FilterBrandModal
            title={filter?.title || ''}
            ascFilter={filter?.ascFilter}
            descFilter={filter?.descFilter}
            brandNameList={filter?.brandNameList}
            handleFilterBrand={handleFilterBrand}
            handlesortAscBrand={handlesortAscBrand}
            handlesortDescBrand={handlesortDescBrand}
            getNumberOfDevices={getNumberOfDevice}
          />
        }
      >
        {productDevices && productDevices?.length > 0 ? (
          productDevices?.map((item, index) => (
            <CardInfoDevice
              key={stringToAsciiCode(index.toString())}
              {...item}
              customTracking={() => onTrack(item)}
              externalUrl
              actionNavigationUrl={getActionUrlByActionType(item.action)}
              outOfStockLabel={buttonLabelDeviceOutofStock}
              disableDeviceInfo={item.isOutOfStock}
            />
          ))
        ) : (
          <DeviceNotAvailable>
            <p>{deviceNotAvailable.title}</p>
            <ButtonSlideCustom
              label={deviceNotAvailable.customAction.title}
              style={deviceNotAvailable.customAction.style}
              onClick={() => goBack(deviceNotAvailable.customAction)}
            />
          </DeviceNotAvailable>
        )}
      </ListCardDevice>
    </WidgetContainer>
  );
};

export default WidgetHubDevice;

