import { IPlaceholder, useGetProductEcommerceCatalog } from '@vfit/business-data-access';
import { useContext } from 'react';
import { HubPostCoverageContext } from '@vfit/business-data-access';
import { IBusinessSliderProductsHub } from './widgetSliderProductsHub.models';
import { formatProducts } from './widgetSliderProductsHub.utils';

const useWidgetSliderProductHub = (
  widget: IBusinessSliderProductsHub,
  placeholders: IPlaceholder[]
) => {


  const { elements } = widget;

  const { isHubPostCoverage, hubPostCoverageElements } = useContext(HubPostCoverageContext);

  const currentElements =
    isHubPostCoverage && hubPostCoverageElements.length > 0 ? hubPostCoverageElements : elements;

  const { catalog } = useGetProductEcommerceCatalog(currentElements);

  return {
    title: widget.titleHtml || '',
    products: formatProducts(currentElements, placeholders, catalog),
  };
};

export { useWidgetSliderProductHub };

