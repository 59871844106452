import {
  IPlaceholder,
  PlaceholdersID,
  getLabel,
  getDSIOTPrice,
  IBusinessSolutionInfo,
  DXL,
  getDSIOTActivationPrice,
  getDSIOTResponsivePrice,
} from '@vfit/business-data-access';
import { getFormattedPriceStringDS } from '@vfit/business-data-access';
import { IHeroBanner } from '../TopProduct/topProduct.model';
import { getValues } from '../WidgetHubDigitalSolutions/widgetHubDigitalSolutions.utils';
import { IProduct } from '../WidgetProductHeroBanner/widgetProductHeroBanner.models';

const getDSHeroBanner = (
  placeholders: IPlaceholder[],
  product: IProduct,
  dxlProduct?: IBusinessSolutionInfo
): IHeroBanner => {
  if (product?.isEditorialCard === 'false' && !dxlProduct)
    return {
      image: product.imageBackgroundUrl || '',
    };

  const getPriceInfo = () => {
    const isDSSingle = dxlProduct?.editionInfo && dxlProduct?.editionInfo?.length === 1;
    return isDSSingle
      ? {
          priceDescription:
            product.overrideCostActivationHtml ||
            (dxlProduct &&
              getDSIOTActivationPrice(
                dxlProduct?.editionInfo?.[0]?.costs as DXL.ICost,
                placeholders
              )),
          price:
            product.overrideCostRenewalHtml ||
            (dxlProduct &&
              getDSIOTPrice(dxlProduct?.editionInfo?.[0]?.costs as DXL.ICost, placeholders)),
          priceResponsive:
            product.overrideCostRenewalResponsive ||
            (dxlProduct &&
              getDSIOTResponsivePrice(
                dxlProduct?.editionInfo?.[0]?.costs as DXL.ICost,
                placeholders
              )),
          frontLabel: product.frontLabel || '',
          offerLabel: product.offerLabel || '',
        }
      : {};
  };

  return {
    offerId: product.dxlId,
    isEditorialCard: product.isEditorialCard,
    taggingProductPrice:
      product.taggingProductPrice ||
      getFormattedPriceStringDS(dxlProduct?.editionInfo?.[0]?.costs) ||
      '',
    title:
      product.overrideOfferName ||
      (dxlProduct && getLabel(dxlProduct, placeholders, PlaceholdersID.BS_NAME)),
    productType: product.productType,
    shortDescription: product?.shortDescription,
    image: product.imageBackgroundUrl || '',
    backLabel: product.backLabel || undefined,
    actions: product.actions,
    subtitle:
      product?.overrideSubtitle ||
      getValues(dxlProduct?.editionInfo?.[0]?.characteristic || [], 'businessSolutionClaim'),
    iconUrl:
      product?.overrideIconUrl ||
      getValues(dxlProduct?.editionInfo?.[0]?.characteristic || [], 'businessSolutionImage'),
    externalUrl: !product?.overrideIconUrl,
    hideDetails: true,
    ...getPriceInfo(),
  };
};

export { getDSHeroBanner };

