import { breakpoints, colors, fonts, pxToCssFont, pxToCssFontSize } from '@vfit/shared/themes';
import styled from 'styled-components';

export const SlideDeviceContent = styled.div`
  margin: 24px;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 64px;
  border-bottom: 1px solid ${colors.$bebebe};

  #sticky-offer-device {
    #sticky-offer-next {
      button {
        padding: 10.5px 10px;
      }
    }
  }

  @media (min-width: ${breakpoints.tablet}) {
    margin-right: 64px;
    margin-left: 64px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin: 32px 80px 80px;
    padding-bottom: 80px;
  }
`;

export const SlideDeviceNavigation = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  @media (min-width: ${breakpoints.desktop}) {
    margin-bottom: 80px;
  }
`;

export const ContainerTopDevice = styled.div`
  @media (min-width: ${breakpoints.tablet}) {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
`;

// device info
export const ContainerDeviceInfo = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${breakpoints.tablet}) {
    width: 100%;
    margin-left: 32px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    width: 60%;
    margin-left: 32px;
  }
`;

export const ContainerTags = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const DeviceInfo = styled.div`
  margin-top: 16px;
  div {
    margin: 0;
    span {
      margin: 0;
    }
  }
`;

export const DeviceBrand = styled.p`
  font-family: ${fonts.light};
  font-weight: 400;
  ${pxToCssFontSize(20)}
  line-height: 28px;
  color: ${colors.$0d0d0d};
  margin: 0;

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFontSize(22)}
    line-height: 32px;
  }
`;

export const DeviceTitle = styled.p`
  font-family: ${fonts.exbold};
  font-weight: 400;
  ${pxToCssFontSize(28)}
  line-height: 36px;
  color: ${colors.$0d0d0d};
  margin: 0;

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFontSize(36)}
    line-height: 48px;
  }
`;
export const DeviceSubTitle = styled.p`
  font-family: ${fonts.light};
  font-weight: 400;
  ${pxToCssFontSize(28)}
  line-height: 36px;
  color: ${colors.$0d0d0d};
  margin: 0;

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFontSize(36)}
    line-height: 48px;
  }
`;

export const DeviceFrontLabel = styled.p`
  font-family: ${fonts.regular};
  font-weight: 400;
  ${pxToCssFontSize(16)}
  line-height: 22px;
  color: ${colors.$262626};
  margin: 0;
`;
export const DeviceShortDescription = styled.p`
  font-family: ${fonts.regular};
  font-weight: 700;
  ${pxToCssFontSize(16)}
  line-height: 22px;
  color: ${colors.$7e7e7e};
  margin: 0;
`;

export const DevicePrice = styled.div`
  ${pxToCssFont(12, 16)}
  display: flex;
  justify-content: 'flex-start';
  align-items: center;

  div {
    white-space: nowrap;
  }

  .discount div div {
    padding: 6px 8px;
  }
`;

export const DeviceDetailOffer = styled.div`
  padding-top: 16px;
  display: flex;
  flex-direction: column;
`;

export const DeviceSpecContainer = styled.div`
  padding-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .additionalOfferDetails {
    width: 100%;
  }

  @media (min-width: ${breakpoints.desktop}) {
    .additionalOfferDetails {
      div {
        display: flex;
        justify-content: flex-start;
      }
    }
  }
`;

export const DeviceSpecTitle = styled.p`
  font-family: ${fonts.regular};
  font-weight: 400;
  ${pxToCssFontSize(14)}
  color: ${colors.$262626};
  margin: 0;
`;

export const DeviceSpecButton = styled.button`
  display: flex;
  margin-left: 16px;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  background: ${colors.$e60000};
  border-radius: 100px;
  color: white;
  width: 26px;
  height: 26px;
  text-align: center;
  align-items: center;
  justify-content: center;
  ${pxToCssFontSize(26)}
  font-family: ${fonts.light};

  &:hover {
    background: ${colors.$bd0000};
  }

  &:active {
    background: ${colors.$a10000};
  }
`;

export const DeviceCustomizeContainer = styled.div<{ isFirstElement?: boolean }>`
  display: flex;
  margin-top: 32px;

  @media (min-width: ${breakpoints.desktop}) {
    margin-top: ${({ isFirstElement }) => (isFirstElement ? '32px' : '40px')};
  }
`;
// device info

export const ContainerDeviceImage = styled.div`
  display: flex;

  @media (min-width: ${breakpoints.tablet}) {
    width: 100%;
    margin-right: 32px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    width: 40%;
    margin-right: 32px;
  }
`;

export const ContainerDeviceCustomize = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media (min-width: ${breakpoints.desktop}) {
    grid-column-start: 2;
    gap: 40px;
  }
`;

export const DeviceCustomize = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  p {
    font-family: ${fonts.regular};
    font-weight: 400;
    ${pxToCssFontSize(18)}
    line-height: 24px;
    font-weight: 700;
    color: ${colors.$333333};
    margin: 0;
  }
`;
export const ListItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 16px;
  column-gap: 24px;
`;
export const Box = styled.div<{ selected: boolean }>`
  display: flex;
  min-height: 54px;
  min-width: 100px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;

  p {
    margin: 0;
    font-family: ${fonts.regular};
    font-weight: 400;
    font-weight: ${({ selected }) => (selected ? 700 : 400)};
    ${pxToCssFontSize(14)}
    line-height: 18px;
    text-align: center;
  }

  &:hover {
    p {
      font-weight: 700;
    }
  }
`;

export const OffertBox = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
  justify-content: space-between;
  padding: 16px 20px;
  flex-direction: column;
  @media (min-width: ${breakpoints.desktop}) {
    flex-direction: row;
  }
`;

export const OfferBoxRight = styled.div<{ selected: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: start;

  .titleBoxOffer {
    margin: 0;
    font-family: ${({ selected }) => (selected ? fonts.exbold : fonts.regular)};
    font-weight: ${({ selected }) => (selected ? 700 : 400)};
    ${pxToCssFontSize(22)}
    line-height: 32px;
    color: ${colors.$0d0d0d};
  }
  p {
    margin: 0;
    font-family: ${({ selected }) => (selected ? fonts.exbold : fonts.regular)};
    font-weight: ${({ selected }) => (selected ? 700 : 400)};
    ${pxToCssFontSize(16)}
    line-height: 22px;
    color: ${colors.$0d0d0d};
  }
`;

export const WrapperPills = styled.div`
  > div {
    margin-top: 0;
  }
`

export const OfferBoxDesc = styled.div`
  div {
    margin: 0;
    p {
      margin: 0;
      font-family: ${fonts.regular};
      color: ${colors.$0d0d0d};
      @media (min-width: ${breakpoints.tablet}) {
        font-weight: 400;
      }
    }
  }
`;

export const OfferBoxLeft = styled.div<{ selected: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: start;

  div {
    margin: 0;
    font-family: ${fonts.regular};
    font-weight: ${({ selected }) => (selected ? 700 : 400)};
    ${pxToCssFontSize(16)}
    line-height: 22px;
    color: ${colors.$0d0d0d};
    text-align: start;
  }
  @media (min-width: ${breakpoints.desktop}) {
    align-items: flex-end;
    div {
      text-align: end;
    }
  }
`;

export const ColorItem = styled.div<{ itemColor: string; colorName: string }>`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: ${({ colorName }) =>
    colorName?.toLocaleLowerCase().includes('white') ? `1px solid ${colors.$bebebe}` : 'none'};
  background-color: ${({ itemColor }) => itemColor};
`;

export const DeviceCardSpecContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
`;

export const DeviceCardSpecItem = styled.div`
  display: flex;
  justify-content: 'flex-start';
  align-items: baseline;
  margin: 0;

  svg {
    margin-right: 16px;
    width: 16px;
  }
`;

export const DeviceCardSpecText = styled.div`
  div {
    font-family: ${fonts.regular};
    font-weight: 400;
    ${pxToCssFontSize(14)}
    color: ${colors.$262626};
    margin: 0;
  }
`;

export const DeviceCardSpecTitle = styled.p`
  font-family: ${fonts.regular};
  font-weight: 700;
  ${pxToCssFontSize(20)}
  color: ${colors.$262626};
  margin: 0;
`;

export const DeviceCardSpecItemAction = styled.button`
  display: flex;
  margin-left: 16px;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  background: ${colors.$e60000};
  border-radius: 100px;
  color: white;
  width: 26px;
  height: 26px;
  text-align: center;
  align-items: center;
  justify-content: center;
  ${pxToCssFontSize(26)}
  font-family: ${fonts.light};

  &:hover {
    background: ${colors.$bd0000};
  }

  &:active {
    background: ${colors.$a10000};
  }
`;

export const ModalTitle = styled.div`
  margin-bottom: 32px;

  div {
    margin: 0;
    ${pxToCssFont(18, 16)}
  }

  @media (min-width: ${breakpoints.desktop}) {
    div {
      ${pxToCssFont(36, 45)}
    }
  }
`;

export const ModalContent = styled.div`
  div {
    margin: 0;
  }
`;

export const ContainerButtons = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;

  div {
    width: 100%;
  }

  @media (min-width: ${breakpoints.desktop}) {
    flex-direction: row;
    justify-content: flex-start;
    gap: 32px;

    div {
      width: 256px;
    }
  }
`;

export const DeviceOfferWrapper = styled.div``;

