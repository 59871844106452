import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont } from '@vfit/shared/themes';

export const TabsContainer = styled.div<{ align?: string }>`
  list-style: none;
  overflow-x: auto;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  margin-bottom: 39px;

  @media (min-width: ${breakpoints.desktop}) {
    margin-bottom: 48px;
    justify-content: ${({ align }) => align || 'flex-start'};
  }

  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
`;

export const Tab = styled.div<{ isActive: boolean }>`
  margin: 0;
  border: 1px solid ${colors.$262626};
  border-radius: 20px;
  font-family: ${fonts.regular};
  font-weight: 400;
  ${pxToCssFont(16, 22)}
  padding: 3px 12px;
  flex-shrink: 0;
  color: ${({ isActive }) => (isActive ? colors.$ffffff : colors.$0d0d0d)};
  background-color: ${({ isActive }) => (isActive ? colors.$262626 : colors.$ffffff)};

  &:hover {
    cursor: pointer;
  }

  @media (min-width: ${breakpoints.desktop}) {
    padding: 4px 16px;
  }
`;

