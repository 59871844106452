import { Shadow } from '@vfit/shared/atoms';
import { useEffect } from 'react';

const SkeletonPdp = () => {

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <div style={{height: '100vh', marginLeft: '80px'}}>
      <div style={{margin: '50px 0 32px', width: '200px'}}>
        <Shadow height={25} />
      </div>
      <div style={{margin: '70px 0 32px', width: '400px'}}>
        <Shadow height={50} />
      </div>
      <div style={{margin: '40px 0', width: '400px'}}>
        <Shadow height={150} />
      </div>
      <div style={{marginBottom: '220px', width: '600px'}}>
        <Shadow height={50} />
      </div>
      <div style={{width: '100%'}}>
        <Shadow height={100} />
      </div>
    </div>
  )
};

export default SkeletonPdp;