import { colors, fonts } from '@vfit/shared/themes';
import styled from 'styled-components';

const WidgetContainer = styled.div<{ isLastWidget: boolean }>`
  padding-bottom: ${({ isLastWidget }) => (isLastWidget ? '109px' : 0)};
`;

const WidgetDivider = styled.div`
  margin-top: 64px;
`;

const DisclaimerContainer = styled.div`
  font-family: ${fonts.regular};
  font-weight: 700;
  color: ${colors.$7e7e7e};

  img {
    width: 18px;
  }

  a {
    color: ${colors.$262626} !important;

    :visited {
      color: ${colors.$262626} !important;
    }
  }
`;

export { WidgetContainer, WidgetDivider, DisclaimerContainer };

