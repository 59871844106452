import { CustomText } from '@vfit/shared/atoms';
import { useDeviceType } from '@vfit/shared/hooks';
import React, { Children, useContext, useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCards, FreeMode, Keyboard, Mousewheel, Pagination } from 'swiper';
import { TrackingPageContext } from '@vfit/business-data-access';
import { IProductsList } from './productsList.models';
import { MainContainer, ProductListContainer, Title } from './productsList.style';
import { getAllHeight, getSlideSize, updateAllMobileHeight } from './productsList.utils';
import ProductCard from '../ProductCard/productCard';

const ProductsList = ({ title, products }: IProductsList) => {
  const productListsElements = useRef<HTMLDivElement>(null);
  const { isMobile, isTablet, isDesktop } = useDeviceType();
  const [allMobileHeight, setAllMobileHeight] = useState<number[]>([]);
  const haveMarketingLabel = !!products?.find((p) => !!p.frontLabel);
  const { link, currentDataLayer } = useContext(TrackingPageContext);

  const setMobileHeight = () => {
    if (productListsElements?.current) {
      const allHeight = getAllHeight(productListsElements.current);
      setAllMobileHeight(allHeight);
      if (isMobile) {
        updateAllMobileHeight(productListsElements.current, allHeight, 0);
      }
    }
  };

  useEffect(() => {
    if (isMobile) {
      setTimeout(() => {
        setMobileHeight();
      }, 100);
    }
  }, [isMobile]);

  const swiperProduct = (type: 'slide' | 'cards') => (
    <Swiper
      slidesPerView="auto"
      effect={type}
      cardsEffect={{
        rotate: true,
        slideShadows: false,
      }}
      mousewheel={{
        forceToAxis: true,
      }}
      spaceBetween={33}
      freeMode={false}
      centeredSlides={isMobile || products.length === 1}
      keyboard
      pagination={{
        clickable: true,
      }}
      onSlideChange={(swiper) => {
        if (isMobile && productListsElements?.current) {
          link(
            {
              ...currentDataLayer?.hubDataLayer,
              link_name: `${currentDataLayer?.hubDataLayer?.page_name}:carousel swipe:${swiper.activeIndex + 1}`,
            },
            products[swiper.activeIndex]?.offerId
          );
          updateAllMobileHeight(productListsElements.current, allMobileHeight, swiper.activeIndex);
        }
      }}
      modules={[Pagination, FreeMode, Mousewheel, Keyboard, EffectCards]}
    >
      {Children.toArray(
        products?.map((product, index) => {
          let marginLeft = 0;
          if (index === 0) {
            if (isDesktop) marginLeft = 80;
            else if (isTablet) marginLeft = 32;
          }
          if (index === 0 && isMobile) marginLeft = 0;
          const customSize = getSlideSize(index, isMobile, isTablet);
          return (
            <SwiperSlide
              style={{
                marginLeft,
                ...customSize,
              }}
            >
              <div>
                <ProductCard
                  cardIndex={index}
                  product={product}
                  large={isDesktop}
                  noMargin={isMobile}
                  haveMarketingLabel={haveMarketingLabel}
                />
              </div>
            </SwiperSlide>
          );
        })
      )}
    </Swiper>
  );

  return (
    <>
      {title && (
        <Title>
          <CustomText
            text={title}
            size={30}
            lineHeight={38}
            sizeMobile={30}
            lineHeightMobile={38}
          />
        </Title>
      )}
      <ProductListContainer ref={productListsElements}>
        <MainContainer>
          {isMobile && swiperProduct('cards')}
          {!isMobile && swiperProduct('slide')}
        </MainContainer>
      </ProductListContainer>
    </>
  );
};

export default ProductsList;

