import styled from 'styled-components';
import { breakpoints } from '@vfit/shared/themes';

export const MainContainer = styled.div`
  width: 100%;
`;

export const ProductListContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  .swiper {
    margin-left: inherit;
  }

  .swiper-wrapper {
    height: inherit;
  }

  @media (min-width: ${breakpoints.tablet}) {
    margin-bottom: 45px;
  }

  @media (min-width: ${breakpoints.bigDesktop}) {
    margin-bottom: 120px;
  }
`;

export const Title = styled.div`
  div {
    // TOFIX: Padding is not correct, check header for dynamic height
    padding: 56px 0 32px;
    margin-bottom: 0;
    margin-top: 0;

    p {
      margin-block: 0;
    }

    @media (min-width: ${breakpoints.tablet}) {
      padding: 80px 0 48px;
    }

    @media (min-width: ${breakpoints.desktop}) {
      padding: 80px 0 48px;
    }
  }
`;

