import { useRouter } from 'next/router';
import { Arrow } from '@vfit/shared-icons';
import { INavBackLink } from './navBackLink.models';
import { NavBackLinkA } from './navBackLink.style';

export const NavBackLink = ({
  navBackLinkUrl,
  navBackLinkText,
  navBackLinkColor,
}: INavBackLink) => {
  const { asPath } = useRouter();

  const getNavigationBackUrl = () => navBackLinkUrl || asPath.slice(0, asPath.lastIndexOf('/'));

  return (
    <NavBackLinkA
      href={getNavigationBackUrl()}
      target={navBackLinkUrl ? '_blank' : '_self'}
      color={navBackLinkColor}
      data-name="action_navBackLink"
    >
      <div>
        <Arrow rotate={130} />
      </div>
      <span>{navBackLinkText}</span>
    </NavBackLinkA>
  );
};

