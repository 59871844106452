import { getActionUrlByActionType } from '@vfit/shared/data-access';
import { IProductCardInfo } from './widgetCardsInfoProduct.models';

export const getFormatprops = (elements: IProductCardInfo[]) =>
  elements.map((el, index) => ({
    image: el?.image,
    frontLabel: el?.frontLabel,
    description: el?.descriptionHtml,
    title: el?.title,
    action: el?.action,
    key: index.toString(),
    ...(el?.action && { actionNavigationUrl: getActionUrlByActionType(el?.action) }),
  }));

