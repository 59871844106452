import styled from 'styled-components';
import { colors } from '@vfit/shared/themes';
import { ICSSDividerProps } from './divider.models';

export const Divider = styled.div<ICSSDividerProps>`
  ${(props) => props.marginBottom && `margin: 0 0 ${props.marginBottom}rem 0;`}

  ${({ space, vertical }) => space && !vertical && `margin: ${space}rem 0;`}
  ${({ space, vertical }) => space && vertical && `margin: 0 ${space}rem;`}
  ${(props) => (props.isHidden ? 'display: none;' : '')}
  background-color: ${(props) => (props.color ? colors[props.color] : '')};
  height: ${(props) => (props.size && !props.vertical ? `${props.size}px` : '')};
  width: ${(props) => (props.size && props.vertical ? `${props.size}px` : '')};
`;

export default Divider;

