import { Faq } from '@vfit/shared/components';
import { WidgetContainer } from '../Widgets/widgets.style';
import { IBusinessWidgetFaq } from './widgetFaq.models';
import { useWidgetFaq } from './widgetFaq.hook';
import { useWidgetsAction } from '../Widgets/widgets.hook';

const WidgetFaq = ({ widget, isLastWidget }: IBusinessWidgetFaq) => {
  const { faq, containerStyle, topText, divider, disclaimer, actionNavigationUrl } =
    useWidgetFaq(widget);
  const { handleClick } = useWidgetsAction();

  return (
    <WidgetContainer isLastWidget={isLastWidget}>
      <Faq
        faq={faq}
        containerStyle={containerStyle}
        topText={topText}
        divider={divider}
        disclaimer={disclaimer}
        handleButtonClick={() => handleClick(faq?.showMore)}
        actionNavigationUrl={actionNavigationUrl}
      />
    </WidgetContainer>
  );
};

export default WidgetFaq;

