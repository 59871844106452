import { useContext } from 'react';
import { PageLoadingContext, TrackingPageContext } from '@vfit/business-data-access';
import { ButtonSlideCustom, CustomText } from '@vfit/shared/atoms';
import { PassionCard } from '@vfit/shared/components';
import { stringToAsciiCode } from '@vfit/shared/themes';
import { IActionCustom, IActionType } from '@vfit/shared/models';
import { useRouter } from 'next/router';
import { getActionUrlByActionType } from '@vfit/shared/data-access';
import { WidgetContainer } from '../Widgets/widgets.style';
import {
  IBusinessWidgetHubDigitalSolutions,
  IDigitalSolution,
} from './widgetHubDigitalSolutions.model';
import { useWidgetHubDigitalSolutions } from './useWidgetHubDigitalSolutions.hook';
import SkeletonHubDigitalSolution from '../SkeletonHubDigitalSolution';
import {
  CardsListContainer,
  CardContainer,
  DigitalSolutionsContainer,
  TopText,
  ProductNotAvailable,
} from './widgetHubDigitalSolutions.style';
import Tabs from '../Tabs/tabs';
import { getFilteredProductData } from './widgetHubDigitalSolutions.utils';

const WidgetHubDigitalSolutions = ({
  widget,
  isLastWidget,
}: IBusinessWidgetHubDigitalSolutions) => {
  const { isLoading } = useContext(PageLoadingContext);
  const { link, currentDataLayer } = useContext(TrackingPageContext);
  const { title, tabs, digitalSolutions, productNotAvailable } =
    useWidgetHubDigitalSolutions(widget);
  const { push } = useRouter();

  const handleClickTab = (id: number) => {
    link({
      ...currentDataLayer?.hubDataLayer,
      link_name: `${currentDataLayer?.hubDataLayer?.page_name}:${tabs[id]}`,
      ...getFilteredProductData(tabs[id], digitalSolutions, widget),
    });
  };

  const handleClickCard = (digitalSolution: IDigitalSolution) => {
    const selectedTab = digitalSolution.tag || widget.tags.allTagsLabel;
    link(
      {
        ...currentDataLayer?.hubDataLayer,
        link_name: `${currentDataLayer?.hubDataLayer?.page_name}:${selectedTab}:${digitalSolution?.action?.title}`,
      },
      digitalSolution?.id
    );
  };

  const getDigitalSolutionsCards = (tab: string) => {
    const filteredData = digitalSolutions?.filter(
      (ds) => ds.tag === tab || tab === widget.tags.allTagsLabel
    );
    return (
      <CardsListContainer>
        {filteredData &&
          filteredData.map((item, index) => (
            <CardContainer>
              <PassionCard
                key={stringToAsciiCode(index.toString())}
                image={item.image}
                title={item.title}
                description={item.description}
                action={item.action}
                customTracking={() => handleClickCard(item)}
                external
                actionNavigationUrl={getActionUrlByActionType(item.action)}
              />
            </CardContainer>
          ))}
      </CardsListContainer>
    );
  };

  const goBack = (param: IActionCustom) => {
    const type = param?.type ? +param.type : 0;

    switch (type) {
      case IActionType.LOCAL_URL:
        if (param?.url) push(param?.url);
        break;
      case IActionType.CUSTOM_URL:
        if (param?.url) window.open(param?.url, param?.isBlank === 'true' ? '_blank' : '_self');
        break;
      default:
        break;
    }
  };

  if (isLoading) return <SkeletonHubDigitalSolution />;

  return (
    <WidgetContainer isLastWidget={isLastWidget}>
      <DigitalSolutionsContainer>
        {title && (
          <TopText>
            <CustomText
              text={title}
              size={36}
              sizeMobile={28}
              lineHeight={48}
              lineHeightMobile={36}
            />
          </TopText>
        )}
        {tabs && tabs?.length > 0 ? (
          <Tabs
            tabs={tabs.map((tab) => ({
              label: tab,
              content: getDigitalSolutionsCards(tab),
            }))}
            align="center"
            handleClickTab={handleClickTab}
          />
        ) : (
          <ProductNotAvailable>
            <p>{productNotAvailable.title}</p>
            <ButtonSlideCustom
              label={productNotAvailable.customAction.title}
              style={productNotAvailable.customAction.style}
              onClick={() => goBack(productNotAvailable.customAction)}
            />
          </ProductNotAvailable>
        )}
      </DigitalSolutionsContainer>
    </WidgetContainer>
  );
};

export default WidgetHubDigitalSolutions;

