import { breakpoints, fonts } from '@vfit/shared/themes';
import styled from 'styled-components';

export const DeviceNotAvailable = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;

  p {
    text-align: center;
    font-family: ${fonts.light};
    font-size: 40px;
    font-weight: 400;
    line-height: 48px;
    margin: 0 0 32px 0;
  }

  @media (min-width: ${breakpoints.desktop}) {
    p {
      margin: 0 0 48px 0;
    }
  }

  div {
    display: flex;
    width: 216px;
    justify-items: center;
  }
`;
