import { ICommonData } from '@vfit/shared/models';
import { useEffect, useState } from 'react';
import { IgeographicAddressManagementResponse } from '@vfit/business-data-access';
import { useQueryClient } from 'react-query';
import { useCoverageToolModalProvider } from '../../coverageToolModal.context';
import CoverageToolListAddress from '../../CoverageTool/coverageToolListAddress';
import { ICoverageToolType, IInputs } from '../../CoverageTool/coverageTool.models';
import { IProductCard } from '../../coverageToolModal.models';

const CoverageToolListAddressCard = ({ handleGoNextSlide, handleGoPrevSlide }: ICommonData) => {
  const { setAddress } = useCoverageToolModalProvider();
  const [enableNext, setEnableNext] = useState(false);
  const queryClient = useQueryClient();
  const product: IProductCard | undefined = queryClient.getQueryData('coverageToolProduct');

  const onSubmit = (data: IInputs, typeCoverage: ICoverageToolType) => {
    setAddress({
      ...data,
      typeCoverage,
    });
    if (handleGoNextSlide) {
      setTimeout(() => {
        setEnableNext(true);
      }, 500);
    }
  };

  useEffect(() => {
    const data = queryClient.getQueryData(
      'geographicAddressManagement'
    ) as IgeographicAddressManagementResponse;
    if (!data?.validAddress?.candidates && handleGoPrevSlide) handleGoPrevSlide();
  }, []);

  useEffect(() => {
    if (enableNext && handleGoNextSlide) {
      handleGoNextSlide();
    }
  }, [enableNext]);

  const onBack = () => {
    if (handleGoPrevSlide) handleGoPrevSlide();
  };

  return (
    <CoverageToolListAddress offerId={product?.offerId || ''} onSubmit={onSubmit} onBack={onBack} />
  );
};

export default CoverageToolListAddressCard;

