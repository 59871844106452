import { useContext, useEffect, useState } from 'react';
import { ButtonSlideCustom, CustomText, ImageAtoms } from '@vfit/shared/atoms';
import { fonts } from '@vfit/shared/themes';
import {
  IConfigCMSParams,
  ISupportModules,
  cmsDataID,
  useCmsConfig,
  useSwitchCmsData,
} from '@vfit/business-data-access';
import { useDeviceType } from '@vfit/shared/hooks';
import { IActionCustom, IActionType } from '@vfit/shared/models';
import { TrackingPageContext, getEventLabel } from '@vfit/business-data-access';
import { IDatalayer } from '@vfit/shared/data-access';
import { useWhatsappModalAction } from './whatsappModal.hook';
import {
  ModalTitle,
  ModalDescription,
  ExtendedVersionContainer,
  ShortVersionContainer,
  FreeNumerContainer,
  ContentLeft,
  ContentRight,
  ImageContainer,
  TopContainer,
  BottomContainer,
  IconWrapper,
  DescriptionFlex,
} from './whatsappModal.style';
import { IWhatsappModal, IWhatsappVersion } from './components/whatsappModal.models';

const ExtendedVersion = ({ data, handleButtonClick }: IWhatsappVersion) => (
  <ExtendedVersionContainer>
    <TopContainer>
      <ContentLeft>
        <ModalTitle>
          <CustomText fontFamily={fonts.exbold} textAlign="left" text={data?.title || ''} modal />
        </ModalTitle>
        {data?.actions?.map((action: IActionCustom) => (
          <ButtonSlideCustom
            key={Math.random()}
            onClick={() => handleButtonClick?.(action)}
            label={action?.title}
            style={action?.style}
            name={`action_whatsapp_${action?.type}`}
          />
        ))}
        <ModalDescription>
          <CustomText
            fontFamily={fonts.regular}
            textAlign="left"
            size={18}
            text={data?.disclaimer || ''}
            modal
          />
        </ModalDescription>
      </ContentLeft>
      <ContentRight>
        <ModalDescription>
          <CustomText
            fontFamily={fonts.regular}
            textAlign="center"
            size={18}
            text={data?.description || ''}
            modal
          />
        </ModalDescription>
        {data?.image && (
          <ImageContainer>
            <ImageAtoms nameCard="whatsappQRCode" alt="QRCode Whatsapp" image={data?.image} />
          </ImageContainer>
        )}
      </ContentRight>
    </TopContainer>
    {data?.footerDescription && (
      <BottomContainer>
        {data?.footerIcon && (
          <IconWrapper>
            <ImageAtoms nameCard="wrapperIconWA" image={data?.footerIcon} alt="icona operatore" />
          </IconWrapper>
        )}
        <CustomText
          margin={[24, 0, 24, 8]}
          fontFamily={fonts.regular}
          size={18}
          text={data?.footerDescription || ''}
          modal
        />
      </BottomContainer>
    )}
  </ExtendedVersionContainer>
);

const ShortVersion = ({ data, handleButtonClick }: IWhatsappVersion) => (
  <ShortVersionContainer>
    <ModalTitle>
      <CustomText fontFamily={fonts.exbold} textAlign="left" text={data?.title || ''} modal />
    </ModalTitle>
    {data?.actions?.map((action: IActionCustom) => (
      <ButtonSlideCustom
        key={Math.random()}
        onClick={() => handleButtonClick?.(action)}
        label={action?.title}
        style={action?.style}
        name={`action_whatsapp_${action?.type}`}
      />
    ))}
    <ModalDescription>
      <CustomText
        fontFamily={fonts.regular}
        textAlign="left"
        size={18}
        text={data?.disclaimerMobile || ''}
        modal
      />
    </ModalDescription>
    <FreeNumerContainer>
      <a href={`tel:${data?.freeNumberUrl}`}>{data?.freeNumberLabel}</a>
    </FreeNumerContainer>
    <DescriptionFlex>
      {data?.footerIcon && (
        <IconWrapper>
          <ImageAtoms nameCard="wrapperIconWA" image={data?.footerIcon} alt="icona operatore"/>
        </IconWrapper>
      )}
      <CustomText
        fontFamily={fonts.regular}
        textAlign="left"
        size={18}
        text={data?.footerDescriptionMobile || ''}
        modal
      />
    </DescriptionFlex>
    <FreeNumerContainer>
      <a href={`tel:${data?.footerNumberUrl}`}>{data?.footerNumberLabel}</a>
    </FreeNumerContainer>
  </ShortVersionContainer>
);

const WhatsappModal = ({ product, urlVola }: IWhatsappModal) => {
  const { isMobile } = useDeviceType();
  const { handleClick } = useWhatsappModalAction();
  const { link, view } = useContext(TrackingPageContext);
  const [whatsappTrackInfo, setWhatsappTrackInfo] = useState<IDatalayer>({
    event_name: [],
  });

  const { cmsConfig, api } = useSwitchCmsData(cmsDataID.WHATSAPP) as IConfigCMSParams;

  const { whatsapp, tagging } = useCmsConfig(cmsConfig, api) as ISupportModules;
  useEffect(() => {
    const datalayer = view(
      {
        event_name: [tagging?.event || ''],
        event_label: 'snodo_whatsapp-ctc',
        page_section: tagging?.pageSection,
        page_subsection: tagging?.pageSubsection,
        page_type: tagging?.pageType,
        journey_name: 'snodo_whatsapp-ctc',
        journey_type: 'journey',
        event_category: [tagging?.eventCategory || ''],
      },
      product?.offerId
    );

    setWhatsappTrackInfo(datalayer);
  }, []);

  const onHandleClick = (btn?: IActionCustom) => {
    const localBtn = btn!;
    if (btn?.type == IActionType?.CALL_ME_NOW) localBtn.url = urlVola;

    const label = btn?.type == IActionType.CUSTOM_URL ? ':whatsapp' : getEventLabel(btn?.type);
    link(
      {
        ...whatsappTrackInfo,
        link_name: `${whatsappTrackInfo?.page_name}${label}`,
      },
      product?.offerId
    );

    handleClick(localBtn, product);
  };

  return (
    <>
      {isMobile && <ShortVersion data={whatsapp} handleButtonClick={onHandleClick} />}
      {!isMobile && <ExtendedVersion data={whatsapp} handleButtonClick={onHandleClick} />}
    </>
  );
};

export default WhatsappModal;

