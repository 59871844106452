import { IClickBoxProps } from "./clickBox.model"
import { ClickBoxContainer, FullCoverContainer } from "./clickBox.style"

const ClickBox = ({children, onClick, selected, disabled = false, color='#00697c'} : IClickBoxProps) => {
  return (
    <ClickBoxContainer 
      selected={selected}
      onClick={() => onClick()}
      color={color}
    >
      {disabled && <FullCoverContainer />}
      {children}
    </ClickBoxContainer>
  )
}

export default ClickBox
