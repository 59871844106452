import { IPlaceholder, useGetEcommerceCatalogDSById } from '@vfit/business-data-access';
import { formatProducts } from './widgetSliderProductDSMultiple.utils';
import { IBusinessSliderProductDSMultiple } from './widgetSliderProductDSMultiple.models';

const useWidgetSliderProductDSMultiple = (
  widget: IBusinessSliderProductDSMultiple,
  placeholders: IPlaceholder[]
) => {
  const { elements } = widget;

  // se tutte le card sono editoriali non viene chiamata la getEcommerceCatalog
  const isEditorialCard = Object.values(elements)
    .every((item) => item.isEditorialCard === 'true')
    .toString();

  const { data } = useGetEcommerceCatalogDSById([{ dxlId: widget.dxlId, isEditorialCard }]);

  return {
    title: widget.titleHtml || '',
    products: formatProducts(elements, placeholders, data?.[0]?.businessSolutionInfo?.[0]),
  };
};

export { useWidgetSliderProductDSMultiple };

