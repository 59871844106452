import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont, pxToRem } from '@vfit/shared/themes';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: ${pxToRem(560)};

  @media (min-width: ${breakpoints.tablet}) {
    min-height: 737px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    min-height: 737px;
  }
`;

export const Title = styled.div`
  ${pxToCssFont(42, 52)}
  margin-bottom: 20px;
  color: ${colors.$e60000};
  font-family: ${fonts.exbold};
  text-align: center;

  @media (min-width: ${breakpoints.tablet}) {
    margin-bottom: 24px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-bottom: 32px;
  }
`;

export const Description = styled.div`
  div {
    width: 366px;
    margin: 0 auto;
    font-family: ${fonts.regular};
    ${pxToCssFont(20, 30)}
    text-align: center;

    @media (min-width: ${breakpoints.tablet}) {
      text-align: center;
    }
  }
`;

// export const ButtonSlideContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   gap: 16px;
//   justify-content: center;

//   @media (min-width: ${breakpoints.tablet}) {
//     margin-top: 40px;
//     flex-direction: row;
//     gap: 24px;
//   }

//   @media (min-width: ${breakpoints.desktop}) {
//     margin-top: 48px;
//     gap: 32px;
//   }

//   div {
//     width: 100%;
//     justify-content: center;
//     display: flex;

//     button {
//       max-width: 100%;
//     }
//   }
// `;

export const ButtonSlideContainer = styled.div`
  position: absolute;
  bottom: 32px;
  right: 20px;
  left: 20px;

  @media (min-width: ${breakpoints.tablet}) {
    bottom: 64px;
    left: 40px;
    right: 40px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    bottom: 60px;
    left: 54px;
    right: 54px;
  }
`;


export const Subtitle = styled.div`
  margin: 0;
  font-family: ${fonts.exbold};
  ${pxToCssFont(24, 30)}
  text-align: center;
  color: ${colors.$262626};
  margin-bottom: 8px;
  padding: 0 20px;
`;

