import {
  getLabel,
  IPlaceholder,
  IBusinessSolutionInfo,
  PlaceholdersID,
  DXL,
  getDSIOTPrice,
  getDSIOTActivationPrice,
} from '@vfit/business-data-access';
import { getFormattedPriceStringDS } from '@vfit/business-data-access';
import { IProductDSMultiple } from './widgetSliderProductDSMultiple.models';
import { IProductCard } from '../ProductCard/productCard.models';

const formatProducts = (
  elements: IProductDSMultiple[],
  placeholder: IPlaceholder[],
  businessSolutionInfo: IBusinessSolutionInfo
): IProductCard[] => {
  const products = elements
    .map((el) => {
      const editionInfo = businessSolutionInfo?.editionInfo?.find(
        (item) => item.productSpecificationId === el?.dxlId?.toString()
      );

      if (el?.isEditorialCard === 'false' && !editionInfo) return undefined;

      return {
        tagLine: el?.tagLine,
        offerName:
          el?.overrideOfferName ||
          (editionInfo && getLabel(editionInfo, placeholder, PlaceholdersID.BS_EDITION_NAME)),
        frontLabel: el?.frontLabel,
        bottomLabel: el?.offerLabel,
        additionalOfferDetails: el?.additionalOfferDetails,
        offerSpecs: el?.shortCharacteristics,
        offerId: el?.dxlId,
        isEditorialCard: el?.isEditorialCard,
        taggingProductPrice:
          el?.taggingProductPrice || getFormattedPriceStringDS(editionInfo?.costs) || '',
        priceDescription:
          el?.overrideCostActivationHtml ||
          (editionInfo && getDSIOTActivationPrice(editionInfo.costs as DXL.ICost, placeholder)),
        shortDescription: el?.shortDescription,
        price:
          el?.overrideCostRenewalHtml ||
          (editionInfo && getDSIOTPrice(editionInfo.costs as DXL.ICost, placeholder)),
        actions: el?.actions,
        pills: el?.tags,
        category:
          el?.overrideCategory ||
          (businessSolutionInfo &&
            getLabel(businessSolutionInfo, placeholder, PlaceholdersID.BS_NAME)),
      };
    })
    .filter(Boolean) as IProductCard[];

  return products;
};

export { formatProducts };

