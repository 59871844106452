import { useContext, useEffect } from 'react';
import { CustomText } from '@vfit/shared/atoms';
import { fonts, stringToAsciiCode } from '@vfit/shared/themes';
import { TrackingPageContext } from '@vfit/business-data-access';
import { IFilter } from './filterBrandModal.model';
import { FilterRow, Title } from './filterBrandModal.style';

const FilterBrandModal = (filter: IFilter) => {
  const { link, currentDataLayer } = useContext(TrackingPageContext);

  const {
    title,
    ascFilter,
    handlesortAscBrand,
    descFilter,
    handlesortDescBrand,
    brandNameList,
    handleFilterBrand,
    getNumberOfDevices,
  } = filter;

  useEffect(() => {
    link({
      ...currentDataLayer?.hubDataLayer,
      link_name: `${currentDataLayer?.hubDataLayer?.page_name}:filter`,
    });
  }, []);

  const apply = (type: string, label?: string) => {
    link({
      ...currentDataLayer?.hubDataLayer,
      link_name: `${currentDataLayer?.hubDataLayer?.page_name}:filter:apply`,
    });

    switch (type) {
      case 'ASC':
        return handlesortAscBrand?.();
      case 'DESC':
        return handlesortDescBrand?.();
      default:
        return handleFilterBrand?.(label!);
    }
  };

  return (
    <>
      <Title>
        <CustomText
          text={title || ''}
          textAlign="left"
          size={36}
          sizeMobile={30}
          lineHeight={45}
          lineHeightMobile={38}
          fontFamily={fonts.exbold}
        />
      </Title>

      {ascFilter?.hideFilter === 'false' && handlesortAscBrand && (
        <FilterRow onClick={() => apply('ASC')}>
          <span>{ascFilter.label}</span>
        </FilterRow>
      )}
      {descFilter?.hideFilter === 'false' && handlesortDescBrand && (
        <FilterRow onClick={() => apply('DESC')}>
          <span>{descFilter.label}</span>
        </FilterRow>
      )}
      {handleFilterBrand &&
        getNumberOfDevices &&
        brandNameList?.map((item, index) => (
          <FilterRow
            key={stringToAsciiCode(index.toString())}
            onClick={() => apply('OTHER', item?.label)}
          >
            <span>{item.label}</span>
            <span className="numberBrand">{`(${getNumberOfDevices(item.label)})`}</span>
          </FilterRow>
        ))}
    </>
  );
};

export default FilterBrandModal;

