import { useWidgetsAction } from '../Widgets/widgets.hook';
import { IBusinessSplittedList } from './widgetSplittedList.models';
import { getCardsList } from './widgetSplittedList.utils';

export const useWidgetSplittedList = (widget: IBusinessSplittedList) => {
  const cards = widget?.elements ? getCardsList(widget?.elements) : [];

  const {handleClick} = useWidgetsAction();

  return {
    elements: cards,
    topText: widget.title,
    description: widget.description,
    disclaimer: widget.disclaimer,
    handleButtonClick: handleClick
  };
}