import { useCallback, useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Divider, CustomText, ButtonSlideCustom } from '@vfit/shared/atoms';
import {
  CMS_CONFIG,
  useCmsConfig,
  useValidateAddressMutation,
  ICoverageToolCongifg,
  IgeographicAddressManagementRequest,
} from '@vfit/business-data-access';
import { fonts } from '@vfit/shared/themes';
import {
  getItalianMunicipalities,
  getItalianProvinces,
  ICountry,
  IProvince,
  API,
  PAGES,
} from '@vfit/shared/data-access';
import { resetData } from '@vfit/shared/data-access';
import { Form } from '@vfit/shared/components';
import { useQueryClient } from 'react-query';
import { TrackingPageContext } from '@vfit/business-data-access';
import { ErrorMsg, Title } from './coverageTool.style';
import { ICoverageToolProps, ICoverageToolType, IInputs } from './coverageTool.models';
import {
  CoverageToolContainer,
  CoverageToolContent,
  CoverageToolContentButton,
} from './coverageToolContainers.style';
import { checkAddressFromValue, retrieveAddress } from './coverageTool.utils';
import { useAddressAndVillage } from '../coverageTool.hooks';
import { IProductCard } from '../coverageToolModal.models';
import CoverageToolError from '../CoverageToolError/coverageToolError';

const CoverageToolManual = ({ state, onSubmit }: ICoverageToolProps) => {
  const mutation = useValidateAddressMutation();
  const [isFormDisabled, setIsFormDisabled] = useState<boolean>(false);
  const italianMunicipalities: ICountry[] = getItalianMunicipalities();
  const italianProvinces: IProvince[] = getItalianProvinces();
  const [isProvinceSelected, setIsProvinceSelected] = useState<boolean>(true);
  const [cityProvince, setCityProvince] = useState<string>(state?.stateOrProvince || '');
  const [stateProvinceError, setStateProvinceError] = useState<boolean>(false);
  const [cityError, setCityError] = useState<boolean>(false);
  const [onConfirmed, setOnConfirmed] = useState(false);
  const queryClient = useQueryClient();

  const { view, linkError, currentDataLayer, setCurrentDataLayer } =
    useContext(TrackingPageContext);

  const product: IProductCard | undefined = queryClient.getQueryData('coverageToolProduct');

  const calculatedItalianMunicipalities = useCallback(
    (province: string) =>
      italianMunicipalities
        .filter((city) => (province !== '' ? city.province === province : true))
        .map((municipality) => `${municipality.municipality} (${municipality.province})`),
    [italianMunicipalities]
  );

  const calculatedItalianProvinces = useCallback(
    () => italianProvinces.map((province) => `${province.long} (${province.short})`),

    [italianMunicipalities]
  );

  const coverageToolConfig = useCmsConfig(
    CMS_CONFIG[PAGES.BUSINESS_FIXED_LINE],
    API.CMS_BFL_GET_COVERAGE_TOOLS
  ) as ICoverageToolCongifg;

  const schema = yup
    .object({
      city: yup
        .string()
        .min(1, coverageToolConfig?.coveragetool?.errorMessage?.error0 || '')
        .matches(
          /^[a-zA-Z+"àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝ"+\s]*$/g,
          coverageToolConfig?.coveragetool?.errorMessage?.error1 || ''
        )
        .required(coverageToolConfig?.coveragetool?.errorMessage?.error0 || ''),
      postalCode: yup
        .string()
        .min(1, coverageToolConfig?.coveragetool?.errorMessage?.error0 || '')
        .matches(/^\d{5}$/, coverageToolConfig?.coveragetool?.errorMessage?.error1 || '')
        .required(coverageToolConfig?.coveragetool?.errorMessage?.error0 || ''),
      stateOrProvince: yup
        .string()
        .min(1, coverageToolConfig?.coveragetool?.errorMessage?.error0 || '')
        .matches(
          /^[a-zA-Z+"àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝ"+\s]*$/g,
          coverageToolConfig?.coveragetool?.errorMessage?.error1 || ''
        )
        .required(coverageToolConfig?.coveragetool?.errorMessage?.error0 || ''),
      street: yup
        .string()
        .min(1, coverageToolConfig?.coveragetool?.errorMessage?.error0 || '')
        .test(
          'rangeTest',
          coverageToolConfig?.coveragetool?.errorMessage?.error0 || ' ',
          (value) => !(value === undefined || value.trim().length === 0)
        )
        .matches(
          /^[a-zA-Z+"-àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝ"+\s]*$/g,
          coverageToolConfig?.coveragetool?.errorMessage?.error1 || ''
        )
        .required(coverageToolConfig?.coveragetool?.errorMessage?.error0 || ''),

      streetNumber: yup
        .string()
        .test(
          'rangeTest',
          coverageToolConfig?.coveragetool?.errorMessage?.error1 || ' ',
          (value) => {
            if (value?.match(/^[1-9]([0-9]{0,5})[a-zA-Z]?$/)) {
              return true;
            }
            return false;
          }
        )
        .required(coverageToolConfig.coveragetool.errorMessage.error0),
    })
    .required();

  const {
    handleSubmit,
    register,
    getValues,
    setValue,
    trigger,
    formState: { isValid, errors, touchedFields },
  } = useForm<IInputs>({
    resolver: yupResolver(schema),
    defaultValues: state,
    mode: 'all',
  });

  const getAddress = () => {
    const inputs = getValues(); // metere in una funzione
    return {
      city: inputs.city,
      postcode: inputs.postalCode,
      streetName: inputs.street,
      streetNr: inputs.streetNumber,
      stateOrProvince: inputs.stateOrProvince,
      provideAlternatives: false,
    } as IgeographicAddressManagementRequest;
  };
  const {
    isSuccess,
    isError,
    data: addressValidated,
    setIsError,
  } = useAddressAndVillage({
    address: getAddress(),
    onConfirmAddress: onConfirmed,
    setOnConfirmed,
  });
  useEffect(() => {
    mutation.reset();
    resetData(queryClient, ['geographicAddressManagement']);
    setIsFormDisabled(false);
    if (!state?.stateOrProvince && !state?.city) {
      setIsProvinceSelected(false);
    }
    if (state?.city) {
      setCityProvince(
        italianMunicipalities.find((city) => city.municipality === state.city?.toUpperCase())
          ?.province || ''
      );
    }

    const datalayer = view(
      {
        event_name: [coverageToolConfig?.tagging?.event || ''],
        page_section: coverageToolConfig?.tagging?.pageSection,
        page_subsection: coverageToolConfig?.tagging?.pageSubsection,
        page_type: coverageToolConfig?.tagging?.pageType,
        journey_name: 'coverage tool',
        journey_type: 'journey',
        event_category: [coverageToolConfig?.tagging?.eventCategory || ''],
        event_label: 'tool_manual-address',
      },
      product?.offerId
    );
    setCurrentDataLayer({ ...currentDataLayer, coverageToolDataLayer: datalayer });
  }, []);

  useEffect(() => {
    if (!isSuccess) return;
    const inputs = getValues();
    if (!addressValidated?.validAddress?.candidates) {
      confirmMutation(inputs, ICoverageToolType.MAPS);
    } else {
      confirmMutation(inputs, ICoverageToolType.LIST_ADDRESS);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (state?.placeId) trigger();
  }, [isValid]);

  const confirmMutation = async (inputs: IInputs, coverageType: ICoverageToolType) => {
    const { city, postalCode, stateOrProvince, street, streetNumber } = inputs;
    const address = retrieveAddress({
      city,
      postalCode,
      stateOrProvince,
      street,
      streetNumber,
    });
    const addressGeocoder = await checkAddressFromValue(address);

    const validAddress: IInputs = {
      city: addressValidated?.validAddress?.city || '',
      postalCode: addressValidated?.validAddress?.postcode || '',
      stateOrProvince: addressValidated?.validAddress?.stateOrProvince || '',
      street: addressValidated?.validAddress?.streetName || '',
      streetNumber: addressValidated?.validAddress?.streetNr || '',
      placeId: addressValidated?.validAddress?.addressCode || '',
      latitude: addressGeocoder?.latitude ? parseFloat(addressGeocoder.latitude) : 0,
      longitude: addressGeocoder?.longitude ? parseFloat(addressGeocoder.longitude) : 0,
    };

    if (onSubmit) onSubmit(validAddress, coverageType);
  };

  const handleFirstClick = async () => setOnConfirmed(true);

  const onSelectProvince = (value: string) => {
    setValue('stateOrProvince', (value.match(/\((.*?)\)/) || '')[1]);
    if (cityProvince !== getValues().stateOrProvince) {
      setValue('city', '');
      setCityProvince('');
    }
    trigger('stateOrProvince');
    setIsProvinceSelected(value !== '');
  };

  const handleTrackError = (fieldName: string) => {
    linkError({
      ...currentDataLayer?.coverageToolDataLayer,
      form_error: `please complete ${fieldName}`,
      link_name: `${currentDataLayer?.coverageToolDataLayer?.page_name}:input error`,
    });
  };

  const onSelectCity = (value: string) => {
    setValue('city', (value.match(/^[^(]+/) || ' ')[0].trim());
    if (value !== '') {
      setCityProvince((value.match(/\((.*?)\)/) || '')[1]);
    }
    trigger('city');
  };

  const handleRetry = () => {
    queryClient.invalidateQueries('geographicAddressManagement');
    setIsError(false);
  };

  if (isError) {
    return <CoverageToolError onRetry={handleRetry} offerId={product?.offerId} />;
  }

  return (
    <CoverageToolContainer>
      <CoverageToolContent>
        <Title>{coverageToolConfig.coveragetool.addressInput.title}</Title>
        <CustomText
          text={coverageToolConfig.coveragetool.manualInput.description}
          textAlign="center"
          size={20}
          lineHeight={26}
          fontFamily={fonts.regular}
          modal
        />
        <Form onSubmit={handleSubmit(handleFirstClick)} autoComplete="off">
          <Divider marginBottom={1} />
          <Form.TextInput
            label={coverageToolConfig.coveragetool.selectInput.address}
            error={state?.placeId || touchedFields.street ? errors.street?.message : ''}
            placeholder=" "
            isErrorMessageDisabled
            autoFocus
            disabled={isFormDisabled}
            onError={() => handleTrackError('street')}
            {...register('street')}
          />

          <Divider marginBottom={1} />
          <Form.TextInput
            label={coverageToolConfig.coveragetool.selectInput.civicNumber}
            error={state?.placeId || touchedFields.streetNumber ? errors.streetNumber?.message : ''}
            isErrorMessageDisabled
            placeholder=" "
            disabled={isFormDisabled}
            onError={() => handleTrackError('streetNumber')}
            {...register('streetNumber')}
          />
          <Divider marginBottom={1} />
          <Form.SelectInput
            label={coverageToolConfig.coveragetool.selectInput.province}
            onSelectValue={(selectedValue) => {
              onSelectProvince(selectedValue);
              setStateProvinceError(false);
            }}
            errorMessage={errors.stateOrProvince?.message}
            isErrorIconDisabled
            isErrorMessageDisabled
            placeholder=" "
            {...register('stateOrProvince')}
            onError={() => handleTrackError('stateOrProvince')}
            items={calculatedItalianProvinces()}
            getIsExistedError={(value: boolean) => {
              setStateProvinceError(value);
              setCityError(value);
              if (value) {
                setValue('city', '');
                setCityProvince('');
              }
            }}
          />
          <Divider marginBottom={1} />
          <Form.SelectInput
            label={coverageToolConfig.coveragetool.selectInput.municipality}
            onSelectValue={(selectedValue) => {
              onSelectCity(selectedValue);
              setCityError(false);
            }}
            isDisabled={!isProvinceSelected || stateProvinceError}
            isErrorMessageDisabled
            isErrorIconDisabled
            errorMessage={errors.city?.message}
            onError={() => handleTrackError('city')}
            placeholder=" "
            {...register('city')}
            items={calculatedItalianMunicipalities(getValues().stateOrProvince || '')}
            getIsExistedError={(value: boolean) => {
              setCityError(value);
            }}
          />

          <Divider marginBottom={1} />
          <Form.TextInput
            required
            label={coverageToolConfig.coveragetool.selectInput.cap}
            error={state?.placeId || touchedFields.postalCode ? errors.postalCode?.message : ''}
            isErrorMessageDisabled
            placeholder=" "
            autoFocus={!!state?.placeId}
            disabled={isFormDisabled}
            onError={() => handleTrackError('postalCode')}
            {...register('postalCode')}
          />
          <Divider marginBottom={1.5} />

          {Object.keys(errors).length > 0 ? (
            <ErrorMsg isManual={!isValid}>
              {coverageToolConfig.coveragetool.errorMessage.error2}
            </ErrorMsg>
          ) : (
            <div />
          )}
        </Form>
      </CoverageToolContent>
      <CoverageToolContentButton>
        <ButtonSlideCustom
          label={coverageToolConfig.coveragetool.manualInput.button}
          disabled={
            !isValid || !!Object.keys(errors ?? {}).length || stateProvinceError || cityError
          }
          onClick={handleFirstClick}
          style={coverageToolConfig.coveragetool.manualInput.buttonStyle}
        />
      </CoverageToolContentButton>
    </CoverageToolContainer>
  );
};

export default CoverageToolManual;

