import { Shadow } from '@vfit/shared/atoms';
import { useDeviceType } from '@vfit/shared/hooks';
import SkeletonDefault from './SkeletonDefault';

const Card = ({ noMargin = false }) => (
  <div
    style={{
      height: '493px',
      width: '327px',
      border: '1px solid #BEBEBE',
      borderRadius: '20px',
      overflow: 'hidden',
      marginRight: noMargin ? '0' : '32px',
      marginBottom: '24px',
    }}
  >
    <Shadow height={200} />
    <div style={{ height: '145px', marginTop: '32px', marginLeft: '24px' }}>
      <div style={{ width: '80px' }}>
        <Shadow height={22} />
      </div>
      <div style={{ width: '170px', marginTop: '6px' }}>
        <Shadow height={30} />
      </div>
      <div style={{ width: '100px', marginTop: '8px' }}>
        <Shadow height={20} />
      </div>
      <div style={{ width: '170px', marginTop: '6px' }}>
        <Shadow height={30} />
      </div>
      <div style={{ width: '150px', marginTop: '6px' }}>
        <Shadow height={16} />
      </div>
      <div style={{ width: '248px', marginTop: '24px', marginBottom: '32px' }}>
        <Shadow height={44} />
      </div>
    </div>
  </div>
);

const SkeletonDesktop = () => (
  <div>
    <div style={{ width: '200px', margin: '80px' }}>
      <Shadow height={45} />
    </div>
    <div style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: '80px' }}>
      <Card />
      <Card />
      <Card />
    </div>
  </div>
);

const SkeletonTablet = () => (
  <div>
    <div style={{ width: '100px', margin: '80px 23px 32px' }}>
      <Shadow height={45} />
    </div>
    <div style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: '23px' }}>
      <Card />
      <Card />
    </div>
  </div>
);

const SkeletonMobile = () => (
  <div>
    <div style={{ margin: '56px 23px 32px' }}>
      <Shadow height={45} />
    </div>
    <div style={{ marginLeft: '23px' }}>
      <Card noMargin />
      <Card noMargin />
    </div>
  </div>
);



const SkeletonHubDevice = () => {
  const { isMobile, isTablet, isDesktop } = useDeviceType();

  if(isMobile) return <SkeletonMobile />;
  if(isTablet) return <SkeletonTablet />;
  if(isDesktop) return <SkeletonDesktop />;
  return <SkeletonDefault />;
};

export default SkeletonHubDevice;
