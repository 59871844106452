import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont, pxToRem } from '@vfit/shared/themes';

export const Container = styled.div`
  position: relative;
`;

export const Input = styled.input<{ hasDetail?: boolean }>`
  appearance: none;
  width: ${pxToRem(20)};
  height: ${pxToRem(20)};
  border: 1px solid ${colors.$bebebe};
  border-radius: 50%;
  outline: none;
  padding: ${pxToRem(3)};
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  position: absolute;
  margin: auto ${pxToRem(18)};
  ${(props) => (props.hasDetail ? `top: ${pxToRem(18)};` : `top: calc(50% - ${pxToRem(10)});`)}

  &::before {
    content: '';
    display: block;
    width: ${pxToRem(12)};
    height: ${pxToRem(12)};
    border-color: ${colors.$00697c};
    border-radius: 50%;
    transition: background-color 0.3s ease;
  }

  &:checked::before {
    background-color: ${colors.$00697c};
    border-color: ${colors.$00697c};
  }

  &:checked {
    border-color: ${colors.$00697c};
  }

  &:checked + label {
    border-color: ${colors.$00697c};
    border-width: ${pxToRem(2)};
    padding: ${pxToRem(15, 15, 15, 55)};
  }
`;

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid ${colors.$bebebe};
  border-radius: ${pxToRem(16)};
  padding: ${pxToRem(16, 16, 16, 56)};
`;

export const Detail = styled.span`
  color: ${colors.$bebebe};
  ${pxToCssFont(16, 22)}

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(18, 24)}
  }
`;

export const Title = styled.h3<{ hasDetail?: boolean }>`
  font-family: ${fonts.exbold};
  ${pxToCssFont(20, 26)}
  margin:0;

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(20, 30)}
  }

  ${(props) =>
    props.hasDetail
      ? `{
    font-family: ${fonts.regular};
    font-weight: 700;
    margin-bottom: ${pxToRem(5)};
    ${pxToCssFont(16, 22)}
    @media (min-width: ${breakpoints.tablet}) {
      ${pxToCssFont(18, 24)}
      margin-bottom: ${pxToRem(9)};
    }
  }`
      : ''}
`;

export const Description = styled.span<{ hasIcon?: boolean }>`
  ${pxToCssFont(16, 22)}
  margin-top: ${pxToRem(3)};

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(18, 24)}
    ${(props) => (props.hasIcon ? `margin-top: 0;` : '')}
  }
`;

export const Icon = styled.span<{ color?: string }>`
  ${pxToCssFont(16, 22)}
  font-weight: 700;
  margin-top: ${pxToRem(4)};
  ${(props) => `color: ${props.color};`}
  display: flex;
  align-items: center;

  @media (min-width: ${breakpoints.tablet}) {
    margin-top: ${pxToRem(9)};
  }

  svg {
    vertical-align: middle;
    margin-right: ${pxToRem(8)};
  }
`;

