import React from 'react';
import { CardInfoProduct, VfModal } from '@vfit/shared/components';
import { CustomText } from '@vfit/shared/atoms';
import { fonts } from '@vfit/shared/themes';
import { WidgetContainer } from '../Widgets/widgets.style';
import { useWidgetCardInfo } from './widgetCardInfoProduct.hook';
import { IBusinessWidgetcardInfoProduct } from './widgetCardsInfoProduct.models';
import { ModalContent, ModalTitle } from './widgetCardInfoProduct.style';

const WidgetCardsInfoProduct = ({
  widget,
  isLastWidget = true,
}: IBusinessWidgetcardInfoProduct) => {
  const cardInfo = useWidgetCardInfo(widget);

  return (
    cardInfo?.newCards?.length > 0 && (
      <>
        <WidgetContainer isLastWidget={isLastWidget}>
          <CardInfoProduct {...cardInfo} />
        </WidgetContainer>
        <VfModal
          height={563}
          isOpen={cardInfo.modal.show}
          handleClose={() => cardInfo.setModal({ show: false, text: '', title: '' })}
        >
          <>
            {cardInfo.modal.title && (
              <ModalTitle>
                <CustomText
                  fontFamily={fonts.exbold}
                  textAlign="left"
                  text={cardInfo.modal.title}
                />
              </ModalTitle>
            )}
            <ModalContent>
              <CustomText size={18} lineHeight={24} textAlign="left" text={cardInfo.modal.text} />
            </ModalContent>
          </>
        </VfModal>
      </>
    )
  );
};

export default WidgetCardsInfoProduct;

