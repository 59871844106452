import { getActionUrlByActionType } from '@vfit/shared/data-access';
import { IAddonInfo } from './widgetAddons.models';

export const getFormatprops = (elements: IAddonInfo[]) =>
  elements.map((el) => ({
    image: el?.image,
    frontLabel: el?.frontLabel,
    description: el?.descriptionHtml,
    title: el?.title,
    action: el?.action,
    key: el?.addonId,
    ...(el?.action && { actionNavigationUrl: getActionUrlByActionType(el?.action) }),
  }));

