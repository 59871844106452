import { Fade } from 'react-awesome-reveal';
import { Button, ButtonSlideCustom, CustomText } from '@vfit/shared/atoms';
import { useViewportHeight } from '@vfit/shared/hooks';
import { useContext } from 'react';
import { TrackingPageContext } from '@vfit/business-data-access';
import { IDatalayer } from '@vfit/shared/data-access';
import { Text } from '@vfit/shared/components';
import { fonts } from '@vfit/shared/themes';
import { getActionUrlByActionType, getVariantByActionStyle } from '@vfit/shared/data-access';
import { WidgetContainer } from '../Widgets/widgets.style';
import { IBusinessWidgetText } from './widgetText.model';
import { ButtonContainer } from './widgetText.style';
import { useWidgetsAction } from '../Widgets/widgets.hook';
import TextWrapper from '../TextWrapper/textWrapper';

const WidgetText = ({ widget, isLastWidget }: IBusinessWidgetText) => {
  const { description, scrollTo, customAction, disclaimer } = widget;
  const targetId = scrollTo?.widgetTarget ? +scrollTo.widgetTarget - 1 : 0;
  const { handleClick } = useWidgetsAction();
  const { headerHeight } = useViewportHeight();
  const { link, currentDataLayer } = useContext(TrackingPageContext);

  const handleScroll = () => {
    let activeLayer: IDatalayer = {
      event_name: [],
    };
    Object.keys(currentDataLayer).forEach((key) => {
      if (currentDataLayer[key].event_name.length > 0) activeLayer = currentDataLayer[key];
    });
    link({
      ...activeLayer,
      link_name: `${activeLayer.page_name}:${scrollTo?.buttonLabel?.toLocaleLowerCase()}`,
    });
    const targetElement = document.querySelector(`div[data-widget-id="${targetId}"]`);
    const viewportYDelta = targetElement?.getBoundingClientRect().top || 0;
    const targetElementY = window.scrollY + viewportYDelta - headerHeight;
    window.scrollTo({ top: targetElementY, behavior: 'smooth' });
  };

  return (
    <WidgetContainer isLastWidget={isLastWidget}>
      <Fade direction="up" triggerOnce>
        {description && (
          <CustomText
            textAlign="center"
            size={52}
            lineHeight={70}
            sizeMobile={35}
            lineHeightMobile={45}
            text={description}
          />
        )}
        {(scrollTo?.buttonLabel || customAction?.title) && (
          <ButtonContainer>
            {scrollTo?.buttonLabel && (
              <ButtonSlideCustom
                label={scrollTo.buttonLabel}
                onClick={() => handleScroll()}
                style={scrollTo.style}
              />
            )}
            {customAction?.title && (
              <Button
                onClick={() => !getActionUrlByActionType(customAction) && handleClick(customAction)}
                href={getActionUrlByActionType(customAction)}
                target={
                  getActionUrlByActionType(customAction) && customAction?.isBlank === 'true'
                    ? '_blank'
                    : undefined
                }
                variant={getVariantByActionStyle(Number(customAction?.style))}
                name={`action_widget_text_${customAction?.type}`}
              >
                {customAction?.title}
              </Button>
            )}
          </ButtonContainer>
        )}
        {disclaimer && (
          <Text
            textConfig={{ text: disclaimer, fontFamily: fonts.regular, size: 16, lineHeight: 22 }}
          />
        )}
      </Fade>
    </WidgetContainer>
  );
};

export default TextWrapper(WidgetText);

