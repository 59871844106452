import { breakpoints, fonts } from '@vfit/shared/themes';
import styled from 'styled-components';

export const DigitalSolutionsContainer = styled.div`
  margin: 0 32px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media (min-width: ${breakpoints.desktop}) {
    margin: 0 80px;
  }
`;

export const TopText = styled.div`
  padding-bottom: 32px;
  div {
    margin: 0;
    p {
      margin: 0;
    }
  }

  @media (min-width: ${breakpoints.desktop}) {
    padding-bottom: 48px;
  }
`;

export const CardsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  gap: 33px;

  @media (min-width: ${breakpoints.tablet}) {
    width: 100%;
    grid-template-columns: repeat(auto-fill, 328px);
    display: grid;
    grid-column-gap: 33px;
    grid-row-gap: 33px;
    grid-auto-rows: 1fr;
    align-items: flex-start;
  }

  @media (min-width: ${breakpoints.desktop}) {
    grid-template-columns: repeat(auto-fill, 404px);
  }
`;

export const CardContainer = styled.div`
  width: 100%;
  > div {
    margin: 0;
  }
  img {
    object-fit: cover !important;
  }
`;

export const ProductNotAvailable = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;

  p {
    text-align: center;
    font-family: ${fonts.light};
    font-size: 40px;
    font-weight: 400;
    line-height: 48px;
    margin: 0 0 32px 0;
  }

  @media (min-width: ${breakpoints.desktop}) {
    p {
      margin: 0 0 48px 0;
    }
  }

  div {
    display: flex;
    width: 216px;
    justify-items: center;
  }
`;
