import { Children, useContext, useEffect, useState } from 'react';
import { Accordion, CustomText } from '@vfit/shared/atoms';
import { colors } from '@vfit/shared/themes';
import { ITimerStatus, RetargetContext, TrackingPageContext } from '@vfit/business-data-access';
import { Pills, VfModal } from '@vfit/shared/components';
import {
  CTC_POSITION,
  IConfigCMSParams,
  cmsDataID,
  useCmsConfig,
  useSwitchCmsData,
  useVolaUtils,
} from '@vfit/business-data-access';
import { IActionSlide, IActionType } from '@vfit/shared/models';
import { IOfferDetail, IProductDetailProps } from './productDetail.model';
import ModalInfo from './components/ModalInfo/modalInfo';
import WhatsappModal from '../WhatsappModal/whatsappModal';
import {
  Address,
  Title,
  Price,
  Description,
  Paragraph,
  ParagraphBold,
  OffersList,
  Spacer,
  SectionTitle,
} from './productDetail.style';
import Content from './components/Content/content';

const ProductDetail: React.FC<IProductDetailProps> = ({
  product,
  address,
  showHelps = true,
  productOffer,
  showPopupTitle = false,
  customComponent,
  tabs,
}) => {
  const { id, title, price, description } = product;

  const { view, descriptionVolaUrl } = useContext(TrackingPageContext);
  const { checkRetargetActive } = useContext(RetargetContext);
  const { cmsConfig, api } = useSwitchCmsData(cmsDataID.OFFER_DETAILS) as IConfigCMSParams;
  const offerDetails = useCmsConfig(cmsConfig, api) as IOfferDetail;

  const offerDetail = Object.values(offerDetails).find(
    (el) => el?.detailspopup?.dxlId === product?.id
  );
  const detailsTagging = offerDetail?.tagging;

  const [isShowWhatsapp, setIsShowWhatsapp] = useState<boolean>(false);

  const { formatUrlVola, openPopupVola, createUrlDesc, formatDynamicVolaUrl } = useVolaUtils();

  useEffect(() => {
    view(
      {
        event_name: ['product_view'],
        page_section: detailsTagging?.pageSection,
        page_subsection: detailsTagging?.pageSubsection,
        page_type: detailsTagging?.pageType,
        journey_type: 'task',
        event_category: [detailsTagging?.eventCategory || ''],
        event_label: 'Offer detail',
      },
      product?.id
    );
  }, []);

  const handleClick = (param?: IActionSlide) => {
    const desc = createUrlDesc({
      position: CTC_POSITION.PRODUCT_DETAIL,
      productName: productOffer.taggingProductName || title,
      productPrice: productOffer.taggingProductPrice,
    });

    const type = param?.type ? +param.type : 0;
    switch (type) {
      case IActionType.CUSTOM_URL:
        if (param?.url) {
          window.open(param?.url, param?.isBlank === 'true' ? '_blank' : '_self');
        }
        break;
      case IActionType.WHATSAPP_URL:
        setIsShowWhatsapp(true);
        break;
      case IActionType.CALL_ME_NOW:
        if (param?.url) {
          if (descriptionVolaUrl && desc)
            openPopupVola(formatDynamicVolaUrl(param?.url, desc), 'vola-ctc');
          else openPopupVola(formatUrlVola(param?.url, productOffer!), 'vola-ctc');
        }
        break;
      default:
        break;
    }
  };

  const showHelpButtons = () =>
    offerDetail?.detailspopup?.needHelp && (
      <ModalInfo
        content={{
          title: offerDetail?.detailspopup?.needHelp?.title,
          ctaName: offerDetail?.detailspopup?.needHelp?.customAction.title || '',
        }}
        customAction={() => handleClick(offerDetail?.detailspopup?.needHelp?.customAction)}
      />
    );

  const renderPill = () => {
    const isTimeDiscount = productOffer?.timeDiscount && (productOffer?.timeDiscountLabels?.timeDiscountTag || productOffer?.timeDiscountLabels?.timeDiscountTagForDevicePages);
    const isRetarget = checkRetargetActive({ productId: id, version: productOffer?.version }) === ITimerStatus.ACTIVE
    if (isTimeDiscount || isRetarget) {
      return (
        <Pills
          pills={isTimeDiscount ? [productOffer?.timeDiscountLabels?.timeDiscountTag || productOffer?.timeDiscountLabels?.timeDiscountTagForDevicePages || ''] : [productOffer?.retarget?.label || '']}
          variant={colors.$00697c}
          opacity={1}
        />
      );
    }
    return <span>{'\u00A0'}</span>;
  }

  return (
    <>
      {address && (
        <Address>
          <CustomText textAlign="left" text={address} />
        </Address>
      )}
      {showPopupTitle && <SectionTitle>{offerDetail?.detailspopup?.title}</SectionTitle>}
      {renderPill()}

      {customComponent || (
        <>
          <Title>{title}</Title>
          <Price>
            <CustomText textAlign="left" text={price!} />
          </Price>
        </>
      )}

      {description && (
        <Description>
          <CustomText textAlign="left" text={description} />
        </Description>
      )}
      {productOffer?.timeDiscount && (
        <Description>
          <CustomText textAlign='left' text={productOffer?.timeDiscountLabels?.timeDiscountDetails || ''} />
        </Description>
      )}
      {tabs && <Content tabs={tabs} handleClick={handleClick} />}
      {!tabs && (
        <>
          <OffersList>
            {Children.toArray(
              offerDetail?.detailspopup?.offerDetails?.map((offer) => (
                <li>
                  {offer.title && <ParagraphBold>{offer.title}</ParagraphBold>}
                  {offer.description && (
                    <Paragraph>
                      <CustomText textAlign="left" text={offer.description} />
                    </Paragraph>
                  )}
                </li>
              ))
            )}
          </OffersList>
          {Children.toArray(
            offerDetail?.detailspopup?.conditions?.map((f) => (
              <Accordion
                title={f.title}
                content={[
                  <CustomText
                    modal
                    sizeMobile={16}
                    lineHeightMobile={22}
                    size={16}
                    lineHeight={22}
                    textAlign="start"
                    text={f.text || ''}
                  />,
                ]}
                iconColor="#e60000"
              />
            ))
          )}
          <Spacer />
          {showHelps && showHelpButtons()}
        </>
      )}
      <VfModal height={540} isOpen={isShowWhatsapp} handleClose={() => setIsShowWhatsapp(false)}>
        <WhatsappModal
          product={productOffer}
          urlVola={offerDetail?.detailspopup?.needHelp?.customAction?.url}
        />
      </VfModal>
    </>
  );
};

export default ProductDetail;

