import { useGetProductEcommerceCatalog } from '@vfit/business-data-access';
import { IBusinessProductHeroBanner } from './widgetProductHeroBanner.models';
import { getHeroBanner } from './widgetProductHeroBanner.utils';

export const useWidgetProductHeroBanner = (
  widget: IBusinessProductHeroBanner,
  enabled: boolean
) => {
  const { catalog } = useGetProductEcommerceCatalog([widget?.product], enabled);

  const topProduct = getHeroBanner(widget?.product, catalog?.[0]);

  return {
    product: topProduct,
    disclaimer: widget.descriptionHtml,
    description: widget.disclaimerHtml,
    divider: widget.divider,
    disableNavigation: !widget?.product?.backLabel,
    offerDetails: widget?.customAction
  };
};
