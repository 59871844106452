import { colors, fonts, pxToCssFontSize } from '@vfit/shared/themes';
import styled from 'styled-components';

export const NavBackLinkA = styled.a<{ color?: string }>`
  width: inherit;
  display: flex;
  cursor: pointer;
  margin: 0;
  appearance: none;
  background: none;
  border: none;
  font-family: inherit;
  font-size: inherit;
  padding: 0;
  text-decoration: none;

  div {
    transform: rotate(180deg);
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 9px;
      height: 16px;

      path {
        stroke: ${(props) => props.color || colors.$262626};
      }
    }
  }

  span {
    font-family: ${fonts.regular};
    font-weight: 400;
    ${pxToCssFontSize(16)}
    line-height: 22px;
    margin-left: 15px;
    color: ${(props) => props.color || colors.$262626};
  }
`;

