import {
  IResource,
  useGetEcommerceCatalogDeviceInfo,
  useGetProductEcommerceCatalogAssociated,
} from '@vfit/business-data-access';
import { useContext, useEffect, useState } from 'react';
import {
  ErrorAction,
  ErrorSeverity,
  PageLoadingContext,
  errorManager,
} from '@vfit/business-data-access';
import { IBusinessDeviceHeroBanner, IProductIdList } from './widgetDeviceHeroBanner.models';

export const useWidgetDeviceHeroBanner = (
  widget: IBusinessDeviceHeroBanner,
  product: IProductIdList[]
) => {
  const { setIsLoading } = useContext(PageLoadingContext);

  const [offerCombination, setOfferCombination] = useState<Record<string, IResource[]>>({});

  const { isLoadingDeviceInfo, data } = useGetEcommerceCatalogDeviceInfo({
    deviceId: widget.product.dxlId!,
  });

  const { isLoadingEcomm, catalog } = useGetProductEcommerceCatalogAssociated(product);

  useEffect(() => {
    if (!isLoadingEcomm && !isLoadingDeviceInfo) {
      const obj = offerCombination;
      catalog?.forEach((el) => {
        const arrOfres =
          data?.deviceInfo?.[0]?.resource?.filter((r) =>
            r?.product?.find((e) => e?.productSpecificationId === el?.productOfferingId)
          ) || [];
        if (el.productSpecificationId && arrOfres?.length > 0) {
          const newArr: IResource[] = [];
          arrOfres?.map((a) =>
            newArr.push({
              ...a,
              product: [el],
            })
          );
          obj[el.productSpecificationId] = newArr;
        }
      });
      if (Object.keys(obj).length === 0) {
        errorManager.handleError(ErrorSeverity.HIGH, {
          errorAction: ErrorAction.PAGE,
          errorCode: 404,
        });
      } else {
        setOfferCombination(obj);
        setIsLoading(false);
      }
    }
  }, [isLoadingEcomm, isLoadingDeviceInfo]);

  return {
    device: data,
    offerCombination,
  };
};

